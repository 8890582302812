.ob_unqulified{
@import "./modules/custom.scss";
// @import "./modules/signature.scss";
@import "./modules/style.scss";
@import "./modules/openbank.scss";
// @import "./modules/id_upload.scss";

// @import "../../node_modules/choices.js/src/styles/choices.scss";

// @import "node_modules/bootstrap/scss/bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.2/font/bootstrap-icons.min.css");

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;1,200&display=swap');

}