	/*
	*******************************************
	Template Name: Allegiant
	Author: Arun Ravi
	Date:18-07-2022
	Template By: Vandalay Designs
	Copyright 2021-2022 Vandalay Business Solution

	* This file contains the styling for the Allegiant, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/



.questionnaire-2023{

	$background_color_1:#ffffff;
	$background_color_2:#2f3996;

	$color_1:#ffffff;
	$color_2:#2f3996;

	*{
		font-weight: 600;
	}

	html {
		scroll-behavior: smooth;
  	}

	a{
		cursor: pointer;
	}

	.loading_section{
		padding: 0px;
		margin: 0px; 
		&::before{
			z-index: -1;
			opacity: 0.3;
			content: "";
			position: fixed;
			height: 100%;
			width: 100%;
			background: #ffffff !important;
            background: -moz-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
            background: -webkit-gradient(left top, left bottom, color-stop(0%, #67c4b1), color-stop(24%, #fff), color-stop(49%, #fff), color-stop(69%, #fff), color-stop(100%, #5cc0ad)) !important;
            background: -webkit-linear-gradient(top, #69a2d7 0%, #fff 24%, #fff 49%, #fff 69%, #69a2d7 100%) !important;
            background: -o-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
            background: -ms-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
            background: linear-gradient(to bottom, #69a2d7 0%, #fff 24%, #fff 49%, #fff 69%, #69a2d7 100%) !important;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#67c4b1', endColorstr='#5cc0ad', GradientType=0 );
		}

		.questionspart{
			&::after{
			z-index: -1;
			content: "";
			position: fixed;
			height: 173px;
			width: 100%;
			background-size: 17%;
			top:2%;
			background-image: url(../../img/logo.png) !important;
			background-position:center !important;
			background-repeat: no-repeat!important; 
			opacity: 0.28;
			}
			.space{
				margin-top: 20%;
				img{
					width: 100px;
					margin-top: 20px;
				}
				.htext{
					font-weight: normal;
					text-align: center;
					margin-top: 10px;
					color: #2f3996;
                    font-size: 18px;
				}
			}
			
		}
	}

	.formsection{
		height: auto;
		background-position: center;
		padding: 0px 0px;
		margin-bottom: 20px;

		&::before{
			z-index: -1;
			opacity: 0.3;
			content: "";
			position: fixed;
			height: 100%;
			width: 100%;
			background: #ffffff !important;
            background: -moz-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
            background: -webkit-gradient(left top, left bottom, color-stop(0%, #67c4b1), color-stop(24%, #fff), color-stop(49%, #fff), color-stop(69%, #fff), color-stop(100%, #5cc0ad)) !important;
            background: -webkit-linear-gradient(top, #69a2d7 0%, #fff 24%, #fff 49%, #fff 69%, #69a2d7 100%) !important;
            background: -o-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
            background: -ms-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
            background: linear-gradient(to bottom, #69a2d7 0%, #fff 24%, #fff 49%, #fff 69%, #69a2d7 100%) !important;
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#67c4b1', endColorstr='#5cc0ad', GradientType=0 );
		}

		&::after{
			z-index: -1;
			content: "";
			position: fixed;
			height: 173px;
			width: 100%;
			background-size: 17%;
			top:2%;
			background-image: url(../../img/logo.png) !important;
			background-position: center !important;
			background-repeat: no-repeat!important;
			opacity: 0.28;
		}

		.gb {
			position: absolute;
			background: #69a2d7 !important;
			height: 50px !important;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: $color_1 !important;
			padding: 6px 10px;
			border-radius: 10px 5px 5px 10px;
			font-size: 16px;
			text-align: center;
			font-weight: 600;
			p{ 
				margin-bottom: 0;
			}
		}

		.car-reg{
			height: 60px !important;
			border: 2px solid $color_2 !important;
			font-size: 18px !important;
			font-weight: 600;
			text-align: center;
			border-radius: 6px;
			width: 100% !important;
		} 

	    .amount-text {
			padding-left: 45px;
			width: 100%;
		}

		.radio-box {
			margin:30px 0 ;
			display: inline-block;
			width: 100%;
				h3{
					line-height: 28px;
					margin: 0 !important;
					padding: 0px 0 20px;
					font-size: 20px !important;
					text-align: center;
					color: #69a2d7; 
					font-weight: 600;
					span{
						color: $color_3;
					}
				}

				label.radioa {
				background-color: #FFF  !important;
				border:2px solid #69a2d7;			
				-moz-box-sizing: border-box;
				box-sizing: border-box;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				height:48px;
				position: relative;
				cursor: pointer;
				outline: none;
				margin: 0;
				padding: 0px 0px 0px 50px;
				line-height: 44px;
				margin-bottom: 10px;
				width: 100%;
				float: left;
				text-align: left;
				clear: none;
				color: $color_2;
				display: block;
				font-size: 16px;
					span{
						position: absolute;
						left: 2px;
						color: $color_1;
						font-weight: 400;
						height: 40px;
						line-height: 40px;
						padding: 0px 14px;
						margin: 2px 0px;
						background-color: #69a2d7;
						border-radius: 5px;      
					}
				}
	
				label.radio {
					background-color: rgba(0, 156, 127, 0.18) !important;  
					border:2px solid $color_2;
					-webkit-box-sizing: border-box;
					-moz-box-sizing: border-box;
					box-sizing: border-box;
					border-radius: 4px;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					height:48px;
					position: relative;
					cursor: pointer;
					outline: none;
					margin: 0;
					padding: 0px 0px 0px 50px;
					line-height: 44px;
					margin-bottom: 10px;
					width: 100%;
					float: left;
					text-align: left;
					clear: none;
					color: $color_2;
					font-weight: normal;
					display: block;
					font-size: 17px;
				}

		}

		.radio {
			&:checked+label{
				background-color:rgba(47,57,150,0.68)!important;
				border: 2px solid $color_2;
				color: $color_1;
			}
		}

		label.radio {
			&:active {
				background-color: $background_color_2;
				border-color: $color_2;
				color: $color_1;
			}
		}
	
		input.radio {
			position: absolute;
			visibility: hidden;
		}

		input{ 
			width: 0px;
		}

		.form-select{
			font-size: 16px;
			font-weight: 300;
			border: 2px solid #ddd !important;
			height: 50px !important;
			border-radius: 1px;
		}

		.form-control {
			font-size: 16px;
			font-weight: 600;
			background: none!important;
			border: 2px solid #ddd!important;
			height: 50px!important;
			border-radius: 1px;
		}

		.btn-next{
			color: $color_1;
			padding:15px 10px;
			width:100%;
			cursor: pointer;
			border:0px;
			border-radius:5px !important;
			float: right;
			margin: 15px 0;
			background: #69a2d7;
			font-size: 20px;
			&:hover{
				color: #fff !important;
				background: #2f9de8;
			}
		}
		

		.option_but {  
			background: #69a2d7;
			display: inline-block;
			border: 0px solid $color_6;
			border-bottom: 3px solid $color_3;
			font-size:18px;
			color: $color_1;
			font-weight: 600;
			border-radius:1px !important;
			box-sizing: border-box;
			width: 100%;
			cursor: pointer;
			margin: 0 0 10px !important;
			position: relative;
			overflow: hidden;
			text-align: center;
			padding: 16px 15px;
			span {
				display: inline-block;
				vertical-align: middle;
				
			}
			&:hover{
				color: #fff !important;
				background: #2f9de8;
			}

			
	
		}	
		.option_but .checked {
			border-color: $color_6;
			background-color: $background_color_3;
			z-index: 100;
			color: $color_1;
		}  
			
		.statusbar{
			position: fixed;
			bottom: 0;
			left: 0;
			background: rgba(72,136,232,.68);			
			width: 100%;
			border-top: 1px solid #2f3996;
			padding: 5px 0;

				.arrow-up{
					background: $background_color_2 url(../../img/arrow-up.png) no-repeat center center;
					width: 20px;
					height: 12px;
					padding: 13px !important;
					float: right;
					margin: 2px;
					border-radius: 5px;
					cursor: pointer;
				}
				.arrow-down{
					background: $background_color_2 url(../../img/arrow-down.png) no-repeat center center;
					width: 20px;
					height: 12px;
					padding: 13px !important;
					float: right;
					margin: 2px;
					border-radius: 5px;
					cursor: pointer;
				}
		}

		#myProgress{
            width: 100%;
            background-color:#b6d4ff !important;
            margin-bottom: 0px;
            border-radius: 15px !important;
            overflow: hidden;
            height:15px;
            margin:9px 0;
            display: none;
        }
        
        .myBar {
            margin: 0px 0 0;
            padding: 0px 0 0;
            font-weight: 500;
            height: 15px;
            background-color: #4e87bd;
            text-align: center;
            color: $color_1;
            border-radius: 15px !important;
            transition: 0.4s linear;
            transition-property: width, background-color;
            font-size: 11px;
            transition: 0.4s linear;
            transition-property: width, background-color;
        }

        .progress-value{    
            width: 100%;
            background-color: #4888e8!important;
            margin-bottom: 0px;
            border-radius: 15px !important;
            overflow: hidden;
            height: 15px;
            margin:9px 0;
            color: $color_1;
            font-size: 11px;
            padding-left: 10px;
        }
	}

	.dis_none{
		display: none;
	}

	.space-top{
		padding-top:180px;
	}





	// ANIMATIONS

	.anim_ylw {
		-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		box-shadow: 0 0 0 0 #359f14;

		@-webkit-keyframes pulse {
				to {
				box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
				}
		}

		@-moz-keyframes pulse {
			to {
			box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}
		
		@-ms-keyframes pulse {
			to {
			box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}
		
		@keyframes pulse {
			to {
			box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}
	}

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.fadeIn {
		-webkit-animation-name: fadeIn;
		animation-name: fadeIn;
	}

	@-webkit-keyframes fadeInDown {
		from {
			opacity: 0;
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);
		}
		to {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}

	@keyframes fadeInDown {
		from {
			opacity: 0;
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);

		}
		to {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}

	.fadeInDown {
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
	}

	.animated {
		-webkit-animation-duration: 3s;
		animation-duration: 3s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}


	@keyframes fadeInUp {
		from {
			transform: translate3d(0,40px,0);
			-webkit-transition: -webkit-transform 2.6s ease-in-out;
			transition: transform 0.6s ease-in-out;
		}

		to {
			transform: translate3d(0,0,0);
			opacity: 1
		}
	}

	@-webkit-keyframes fadeInUp {
		from {
			transform: translate3d(0,40px,0)
		}

		to {
			transform: translate3d(0,0,0);
			opacity: 1
		}
	}

	.fadeInUp {
		opacity: 0;
		animation-name: fadeInUp;
		-webkit-animation-name: fadeInUp;
	}

	.form_modal{

		.modal-content{
			.modal-body{
				border: 4px solid #2f3996;
			}
			.modal-footer{
				background: #2f3996!important;
				border-top: none;
			}
		}
	}



	// Media Query
	@media (min-width: 576px){
		.container {
			max-width:100%;
		}
	}

	@media (max-width: 576px){

		section {
			.questionspart {
				&::after{
					background-size: 40%;
					top: 20px;
					height: 115px;
				}
				.space {
					margin-top: 50%;
					h3{
						font-size: 20px;
					}
				}
			}
		}
		.formsection{
			h2{
				font-size: 14px;
			}
			&::after{
				background-size: 40%;
				top: 20px;
				height: 115px;
			}
		}

		.space-top {
			padding-top: 130px;
		}
		
	}
}


























