
.TMS_BRN_V3_2_1B{

	.footer-document{
		cursor :pointer;
		color: #0d6efd;
		text-decoration: underline;
	}
	.analyseClass .modal-content {
		border: solid #2f9de8 !important;
		border-width: 5px 5px 25px !important;
		border-radius: 0 !important;
	}
	.analyseClass {
		background: rgba(0,0,0,.6)!important;
		margin-top: auto;
	}
    .modal-open .modal {
		overflow-x: hidden;
		overflow-y: auto;
		
	}
	.fadetoggle {
		transition: opacity 0.15s linear;
	}
	.error_msg {
		color: #ffffff;
		width: 100%;
		float: left;
		background: #f00;
		font-size: 13px;
		padding: 0px 5px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		margin-bottom: 10px;
	}

    .hide{
		display: none;
	}
	.show{
		display: block;
	}
header{
    background-color: #257eba;

    .top_content{
        background-color: #f50b0b;
        padding: 5px;
        margin-bottom: 15px;

        span{
            color: #fff;
            font-size: 15px;
            font-weight: 600;

            img{
                height: 20px;
            }
        }
    }
}

.form-section{


    .next-bttn {
        background: #2f9de8;
        color: #fff;
        font-weight: 400;
        font-size: 26px;
        padding: 15px 10px;
        width: 100%;
        cursor: pointer;
        border: 0;
        border-radius: 0!important;

        &:hover{
            color: #fff!important;
            background: #69a2d7;
        }
    }

}


.TMS_questi{
	padding: 0px;
	margin: 0px;
	&::before{
		z-index: -1;
		opacity: 0.4;
		content: "";
		position: fixed;
		height: 100%;
		width: 100%;
		background: #3ec1ff54!important;
		background: -webkit-gradient(left top,left bottom,color-stop(0,#3ec1ff54),color-stop(24%,#fff),color-stop(49%,#fff),color-stop(69%,#fff),color-stop(100%,#3ec1ff54))!important;
		background: linear-gradient(
		180deg,#3ec1ff54 0,#fff 24%,#fff 49%,#fff 69%,#3ec1ff54)!important;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3ec1ff54",endColorstr="#5cc0ad",GradientType=0);
	}
.radio-box {
	h4{
		color: rgba(9, 62, 177, 0.68);
	}
    .styl_i{
      color: #45474a;
      font-style: italic;
	  }
	  .styl2_i{
		color: #45474a;
		font-style: italic;
		}
  }

}

}