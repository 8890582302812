.FollowupFosV1{
$color1 :#63a311;
$color2 :#319cff;
$color3 :white;
$color4 :#e73e49;

.tms-wrap{
    &.bg-main-pr {
        background: url(../img/bg87899.jpg) fixed !important;
        background-size: cover  !important;
    
    
    }
    .main {
    
        h1 {
            font-weight: bold;
            font-size: 25px;
            &.h4 {
                font-size: 22px;
            }
            span {
          //      color: $background_color_2;
            }
           
        }
        select { 
            padding: 15px !important;
            border: 2px solid #e1e1e1;
        }
        h2{
            font-weight: bold;
            .small {
                color:$color1;
                font-size: 18px;
                font-weight: normal;
            }
        }
        p {
            font-size: 23px;
           
        }
    
        fieldset {
            background: #fff;
            position: relative;
            padding: 15px;
            box-sizing: border-box;
            border: solid #cdcdcd  1px;
            height: 140px;
            border-radius: 5px;
            p {
                font-size: 16px;
            }
    
            &.first {
                border-color: $color1;
                border-width: 3px;
            }
            
            legend {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            padding: 0 5px;
     
            }
        }
    
        .text2b {
            i {
                color: $color1;
            }
            font-size: 14px;
        }
        .text3b {
            font-style: italic;
            font-size: 14px ;
        }
        .slot-filed {
            background: #B7F4F8;
            font-size: 22px;
            font-weight: bold;
            padding: 15px;
            border-radius: 10px;
            color: #04858D;
            position: relative;
            cursor: pointer;
            input {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }
            .icon {
                height: 50px; 
                width: 50px; 
                background: #41CBD4;
                border-radius: 50%;
                display: inline-block;
                color: #fff;
                padding-top: 7px;
                font-size: 26px;
            }
            &.slot-email {
                background: #F8E9B7;
                font-size: 18px;
                font-weight: 500;
                color: #7A692F;
                .icon {
                    background: #E9B76D;
                }
            }
        }
    
    }
    
    .clearfix {
        &::after {
            content: "";
            clear: both;
            display: table;
          }
    }
    
    .btn-comm2{
        padding: 20px 60px;
        align-items: center;
      appearance: none;
      background-image: radial-gradient(100% 100% at 100% 0, $color2 0, $color2 100%);
      border: 0;
      border-radius: 6px;
      box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      justify-content: center;
      line-height: 1;
      list-style: none;
      overflow: hidden;
      position: relative;
      text-align: left;
      text-decoration: none;
      transition: box-shadow .15s,transform .15s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;
      will-change: box-shadow,transform;
      font-size: 22px;
      width: 100%;  
      text-align: center;
        span {
            font-size: 24px;
            background: #00000040;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: inline-block;
            text-align: center;
            line-height: 21px;
            margin-left: 10px;
        }
        
        &:focus {
            box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
          }
          &:hover {
            box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
            transform: translateY(-2px);
          }
    
          &:active {
            box-shadow: #3c4fe0 0 3px 7px inset;
            transform: translateY(2px);
          }
        
        &.second {
            background: $color1;
    
            &:focus {
                box-shadow: #446e0c 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c 0 -3px 0 inset;
              }
    
            &:hover {
                box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c  0 -3px 0 inset;
                transform: translateY(-2px);
              }
        
    
            &:active {
                box-shadow: #446e0c 0 3px 7px inset;
                transform: translateY(2px);
              }
              
            
        }
    }
    
    .btn-prev{
        background: transparent;
        outline: 0;
        border: 0;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        
    }
    .modal-body-xx {
        position: relative;
        .close-modal {
            position: absolute;
            border: none;
            background: $color2;
            color:  #fff;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            top: 4px;
            right: 4px;
            font-size: 30px;
            line-height: 0;
            text-align: center;
            padding: 0;
            transition: all ease-in 0.3s;
            &:hover {
                transform: rotate(180deg);
                transition: all ease-in 0.3s;
            }
        }
    
        
    }
     
    .textlop{
        text-wrap: balance; 
        font-size: 22px !important;
        background: #faffbb;
        border: 2px dashed $color2;
        border-radius: 7px;
        
    }
    .pulse {
        animation: pulse-animation 2s infinite;
      }
      @keyframes pulse-animation {
        0% {
          box-shadow: 0 0 0 0px #59d0d8;
        }
        100% {
          box-shadow: 0 0 0 20px #00000000;
        }
      }
      
    .claim-id-wraper {
        max-width: 40%;
        margin: 0 auto;
        @media (max-width: 1200px){ 
            max-width: 80%;  
        }
       
        h3 {
            font-size: 22px;

            @media (max-width: 490px){ 
               
                    font-size: 18px;
                    line-height: 24px;
            }
        }
        .claim-id {
           
            .in {
                background: #FFE5E5;
                padding: 10px 15px;
                border-radius: 5px;
                font-weight: bold;
                 
            }
        }
    }
    

    .loader-wrap {
        height: 100vh;
        width: 100%;
        overflow: hidden;
        .loader-logo {
           
            img {
                height: 65px;
                margin: 20px 0;
            }
        }
        .text-analys {
            font-size: 18px;
        }
        .loader {
            margin-top: 4rem;
            img {
                height: 130px;
            }

        }
        .loader-text {
            max-width: 600px;
            margin: 0 auto;
        }
    }
    
    .dis_none {
        display: none;
    }
    
    .main-form-wraper {
        margin-top: 5rem;
    }
    .brand-logo {
        img {
            margin: 20px 0;
        }
    }
    
    @media only screen and (max-width: 1200px) and (min-width: 960px)  {
    
        .main {
            p {
               font-size: 16px;
            }
        }
    }
    @media (min-width: 990px){ 
        
      
         
        
    }
    @media (max-width: 990px){
        .mt-val {
            margin-top: 4rem;
        }
        
      }
    
    @media (max-width: 768px){
        .main-form-wraper {
            margin-top: 2rem  ;
        }
        .loader-wrap {
            .text-analys {
                font-size: 14px;
                max-width: 80%;
                margin: 0 auto;
            }
           
        }

        .com-btn {
            font-size: 16px;
        }

        .mt-val {
            margin-top: 3rem;
        }
        .textlop {
            font-size: 16px !important;
        }
        .main {
            .slot-filed {
               
                font-size: 16px  ;
                &.slot-email {
                    font-size: 13px;
                }
                .ps-3{
                    text-align: left;
                }
            }
            p {
                font-size: 14px;
            }
            fieldset {
                height: auto;
               
                .bud-logo {
                    width: 115px;
                    margin-bottom: 15px;
                }
            }
            padding-bottom: 60px;
            h1 {
                font-size: 24px;
            }
            
            
        }
    .btn-comm2{
        font-size: 18px;
        padding: 15px 30px;
        display: flex;
        
    }
    
     
    
    }
    
    @media (max-width: 490px){
        .success-msg {
            h3 {
                font-size: 20px;
            }
        }
        .safe-secure-img {
            height: 36px;
        }
        .loader-wrap {
            
                .loader {
                    img {
                        height: 100px;
                    }
                }
           
            .loader-logo {
                img  {
                    height: 50px;
                }
            }
        }
        .main {
            
    
    
            h1 {
                font-size: 18px;
            }
            .text3b,.text2b {
                font-size: 12px;
            }
             .brand-logo {
                img {
                    height: 50px;
                }
             }
        }
        .btn-comm2{
            font-size: 14px;
             
     
            span {
                display: none;
            }
        }
        .btn-prev {
            font-size: 14px;
        }
    
    
    }
}
.iframe-container {
    position: relative;
    height: 100%;
    min-height: 100vh;
    iframe {
      height: 100%;
      width: 100%;    
      left: 0;
      top: 0;
      position: absolute;
      body,html {
        height: 100%;
        overflow: hidden;
        background: transparent;
      }
    }
  }
.inside_refresh{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

}