
.bankIncomeS3{
/*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 18-01-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========

$color1: #3A8DDB;
$color2: #0F1994;
$color3: #1ECD3A;
$color4: #1FCCF2;
$color5: #f85c2a;
$color6: #1FCCF2;
$color7: #DF2C2C;
$color8: #0157FF;
$color9: #1ED1BC;
$color10: #148325;
$color11: #a2d0f9;
$color12: #946FFF;
$color13: #9b9b9b;

$black-color: #000;
$white-color: #fff;



$font_family_1: 'Roboto', sans-serif !important;



$font-bold: bold;
$font-normal: normal;
$font-w500: 500;
$font-w600: 600;
$font-w700: 700;

// ======border===========

$border-style1: solid 1px #fff;
$border-style2: 1px solid #d3d3d3;
$border-style3: 5px solid #011a70;

$border-style4: 3px solid #057599;
$border-style5: 2px #1483ce solid;

$border-color1: #d3d3d3;
$border-color2: #dedcdc;
$border-color3: #000;

/*================================================
02 - Sass Mixins
==================================================*/

@mixin radius_18 {
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  border-radius: 18px;
}

@mixin shadow_1 {
  -webkit-box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

@mixin shadow_2 {
  -webkit-box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 11px 16px -10px rgba(0, 0, 0, 0.75);
}

@mixin shadow_in {
  -moz-box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 2px 5px 1px rgba(0, 0, 0, 0.15),
    0 1px 0px 0px rgba(230, 230, 230, 0.9), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
}

@mixin commButton {
  align-items: center;
  background-clip: padding-box;
  background-color: $color1;
  border: 1px solid transparent;
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3.8rem;
  padding: calc(1rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;

  &:focus {
    background-color: $color2;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 12px;
  }

  &:hover {
    background-color: $color2;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    transform: translateY(-2px);
  }

  &:active {
    background-color: $color2;
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    transform: translateY(0);
  }

  i {
    margin-left: 10px;
  }
}

@mixin commbg1 {
  background-image: linear-gradient(to bottom, #e1f2fd, #ebf5fe, #f3f7ff, #fafbff, #ffffff);
  background-repeat: no-repeat;
  background-position: bottom center;

}

/*================================================
03 - BODY STYLES
==================================================*/
.com-button {
  @include commButton;

}

.show{
  display: block !important;
}

.hide{
  display: none !important;
}

.btn-comm {
  background: $color8;
  padding: 20px 40px;
  border-radius: .4rem;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: $white-color;
  font-size: 18px;
  position: relative;
  overflow: hidden;

  &.next-btn {
    &::after {
      content: " Next >>";
      background: #f03583;
      transform: translateX(-100%);
      transition: all ease-in 0.3s;
    }
  }

  &.back-btn {
    &::after {
      content: "<< Back";
      background: #000;
      transform: translateX(100%);
      transition: all ease-in 0.3s;
    }
  }

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    border-radius: 24px;
    padding-top: 8px;
  }

  span {
    top: 0px;
    transition: all ease-in 0.3s;
  }

  &:hover {
    &:hover {
      color: $white-color;
    }

    &::after {
      transform: translateX(0%);
      transition: all ease-in 0.3s;
    }

    span {
      position: relative;
      top: 30px;
      transition: all ease-in 0.3s;
    }
  }
}

.error-message{
  background: #fb0404;
  color: #fff;
  font-size: 13px;
  width: 100%;
  float: left;
  text-align: center;
  padding: 6px;
}

.show{
  display: block !important;
}
.hide{
  display: none !important;
}

$color_1: #fff;
$background-color_1: #663dff;

/* 15 */
.btn-comm2 {
  background: $color8;
  padding: 20px 40px;
  border: none;
  position: relative;
  z-index: 1;
  color: $white-color;
  border-radius: 5px;
  cursor: pointer;

  span {
    position: relative;
    z-index: 4;
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #2a71ff;
    transition: all 0.3s ease;
    z-index: 3;
    border-radius: 5px;
  }

  &:hover {
    color: $color_1;

    &:after {
      left: 0;
      width: 100%;

    }
  }

  &:active {
    top: 2px;
  }

  &.clear {
    background: $color13
  }
}

.btn-comm3 {
  @extend .btn-comm2;
  background: $color5;

  &:after {
    background: #f35624;
  }
}





html {
  scroll-behavior: smooth;
  font-size: 1rem;
}


body {
  font-family: $font_family_1;
  color: $black-color;


}

ul,
li {
  list-style-type: none;
}

a {
  text-decoration: none !important;

}

/*================================================
04 - HEADER 
==================================================*/

.logo {
  position: absolute;
  top: 0;

  img {
    height: 60px;
  }
}


.wrap-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;


}

.panel-banner {
  max-width: 800px;
  width: 100%;
  height: 350px;
  margin: 0 auto;
  color: $white-color;

  .loader-panel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

  }

  &.desk {
    background: $color2 url(../img/bg-012.png) no-repeat right;
  }

  &.app-banner {
    height: 250px;
    background-position: right;
    background-size: 290px;

    .loader {
      p {
        font-size: 1.1rem;
      }
    }

  }

  .loader {
    img {
      height: 80px;
    }

    p {
      font-size: 1rem;
    }
  }
}


.app-header {
  background-image: url("../img/banner-img.png"), linear-gradient(to right bottom, #474aff, #3a3adf, #2d2bc0, #1e1ca3, #0b0d86);
  background-position: right center;
  background-repeat: no-repeat;
  border-radius: 0 0 40px 40px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 150px;
    height: 35px;
    display: block;
    background: url("../img/shape.png") center center;
    bottom: 0;
    right: 50%;
    transform: translate(50%, 88%);
    background-size: 145px;
    z-index: -1;
  }

  .user-text {
    .text-name {
      h3 {
        font-size: 1.3rem;
        font-weight: 200;

        span {
          font-weight: 500;
        }

      }

      .subtext {
        font-size: 13px;
      }
    }
  }

  .status-bar {
    position: relative;

    .inner {
      background: #211ea8;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -75%);
      display: flex;
      align-items: center;

      .progress {
        margin: 0 auto;
      }
    }
  }
}

.logo-two-wrap {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;

  .logohp {

    img {
      height: 40px;
    }
  }

  .iconmenu {
    color: $white-color;
    font-size: 1.3rem;

  }

}


.wrap-app {
  max-width: 700px;
  margin: 0 auto;
}

.dashboard-menu-wrap {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;

  .dashmenu-title {
    font-size: 1.5rem;
    margin-bottom: 26px;
  }

  .dash-menu-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;

    li {
      margin-bottom: 50px;

      &:nth-child(2) {
        .icon {
          background: #FFD4D4;
        }
      }

      &:nth-child(3) {
        .icon {
          background: #BAF9FF;
        }
      }

      &:nth-child(4) {
        .icon {
          background: #F0C3FF;
        }
      }

      &:nth-child(5) {
        .icon {
          background: #FFE39B;
        }
      }

      &:nth-child(6) {
        .icon {
          background: #FFD9B7;
        }
      }

      &:nth-child(8) {
        .icon {
          background: #6CD86A;

          svg {
            color: red;
          }
        }
      }
    }
  }

  a {
    color: #464646;

    &:hover {

      color: $black-color;

    }
  }

  .dash-menu {
    background: $white-color;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    .pending {
      position: absolute;
      background: #FFE8E4;
      color: #F74020;
      top: -23px;
      line-height: 0;
      padding: 10px;
      border-radius: 10px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 70px;
      font-size: 10px;
      text-align: center;
    }

    &.menu-two {
      .icon {
        background: #DADEFF;

      }


    }

    &.menu-three {
      .icon {
        background: $color4;
      }
    }

    &.menu-four {
      .icon {
        background: $color5;
      }
    }

    .icon {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      background: #DADEFF;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 10px auto;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

      svg {
        width: 60px;
        height: 60px;
      }

      .complt-status {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid $color3;
        display: block;
        background: $color3 url('../img/icon-menu.png') center;
        background-size: 18px;
        position: absolute;
        top: -7px;
        right: -4px;

        &.active {
          background: url(../img/tick.png);
          background-position: center center;
        }
      }

    }


    h4 {
      font-size: 1rem;
      font-weight: 400;
      text-align: center;
    }

    .sub-text {
      font-size: .8rem;
    }

  }
}





$color_1: #999;

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

.btn-eff {
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  small {
    &:nth-child(1) {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to right, #e4e4e418, $color10);
      animation: animate1 2s linear infinite;
    }

    // &:nth-child(2) {
    // 	position: absolute;
    // 	top: 0;
    // 	right: 0;
    // 	height: 100%;
    // 	width: 2px;
    // 	background: linear-gradient(to bottom, #e4e4e418,  $color10);
    // 	animation: animate2 2s linear infinite;
    // 	animation-delay: 1s;
    // }
    &:nth-child(3) {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 4px;
      background: linear-gradient(to left, #e4e4e418, $color10);
      animation: animate3 2s linear infinite;
    }

    // &:nth-child(4) {
    // 	position: absolute;
    // 	top: 0;
    // 	left: 0;
    // 	height: 100%;
    // 	width: 2px;
    // 	background: linear-gradient(to top, #e4e4e418,  $color10);
    // 	animation: animate4 2s linear infinite;
    // 	animation-delay: 1s;
    // }
  }
}


.connect-account {
  padding-bottom: 30px;

  .btn-comm2 {
    max-width: 350px;
    display: block;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

    span {
      font-size: 1.2rem;
    }
  }

  .title {
    font-size: 1.5rem;
  }


}

.bglock {
  background: url("../img/bg-lock.png") no-repeat center bottom;
}


.btfooter {
  padding-top: 2rem;
  background: #C1E8FF url("../img/border-img.png") repeat-x top;
  position: relative;

  p {
    font-size: 14px;
    color: #013959;
  }

  &::before {
    content: "";
    width: 58px;
    height: 58px;
    background: url("../img/secure-img.png");
    display: inline-block;
    position: absolute;
    top: -26px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}





.form-section {
  .form-control {
    height: 55px;
    border-color: $color11;
  }

  .title {
    font-size: 1.2rem;

  }

  fieldset {
    border: 1px solid $color11;
    border-radius: 10px;
    background: $white-color;


    legend {
      float: none;
      display: inline-block;
      background: transparent;
      width: initial;
      padding: 0 10px;
      text-align: left;
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }

  .btn-confrm {
    max-width: initial;
  }

  .poscode {
    .btn-postcode {
      background: $color3;
      color: $white-color;
      max-width: 250px;
    }
  }

  .car-regnumber {
    background: url("../img/gb.png") no-repeat;
    background-size: 40px;
    padding-left: 60px;


  }

  .btn-question {
    max-width: initial;
  }

  .option-type {


    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;

      &:checked+label {
        background: $color8;
        color: $white-color;
      }
    }

    label {
      background: #c9edff;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      padding: 20px 10px;
      font-size: 1.2rem;
      font-weight: bold;
      width: 100%;
      cursor: pointer;
      border-radius: 5px;

    }
  }

  .drop-down-multi {
    position: relative;
    text-align: left;
    z-index: 8;

    .choices__input {
      background: transparent;
    }

    .choices__inner {
      background: transparent;
      border-radius: 10px;
      padding: 10px;
      border-color: #a2d0f9;
    }

    .choices__list--multiple {
      .choices__item {
        font-size: 14px;
        background: $color8;

      }
    }
  }

}

.id-uploads {
  .body-id {
    border: 2px solid #cacaca;
  }

  .btn {
    font-size: 1rem;
  }

  .accordion-item {
    .accordion-button {
      background: #35C6F4;
      border-radius: 5px !important;
      color: $white-color;
      font-weight: 600;
      font-size: 1.2rem;

      &.btn-licence {
        background: $color9;
      }

      &.btnpassport {
        background: $color12;
      }
    }

    .btn-uploads {
      .btn {
        font-size: 1.1rem;

        i {
          font-size: 1.4rem;
        }
      }
    }
  }

  .brows {
    position: relative;

    input[type="file"] {
      position: absolute;
      opacity: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

}

.loader-logo {
  img {
    height: 60px;
  }
}

.kycupload {
  .text-start {
    font-size: 1.2rem;
    line-height: 22px;
  }

  .id-uploads {

    .accordion {
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }

  }

  .btn-comm2 {
    display: inline-block;
  }
}


.back-btn {
  font-size: 1.2rem;
  cursor: pointer;
}



// Progress Circle

$borderWidth: 10px;
$animationTime: 0.5s;
$border-color-default: #eaeaea;
$border-color-fill: $color2;
$size: 130px;
$size2: 100px;
$howManySteps: 100; //this needs to be even.

.progress {
  width: $size;
  height: $size !important;
  line-height: $size;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  background: none !important;


  @media only screen and (max-width: 490px) {
    width: $size2;
    height: $size2 !important;
    margin: inherit;
  }

  #progressBar_content {
    line-height: 1rem;
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white-color;
  }

  >span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .perce {
    font-size: 1.2rem !important;
    font-weight: bold;
    color: $black-color;
  }

  .progress-left {
    left: 0;
  }

  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $color3;
  }

  .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: ($size/2);
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }

  .progress-right {
    right: 0;

    .progress-bar {
      left: -100%;
      border-top-left-radius: ($size/2);
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }

  .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    z-index: 9;

    span {
      font-size: 12px;
      color: $black-color;
    }
  }
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
@for $i from 1 through $howManySteps {
  $stepName: (
    $i * (100 / $howManySteps)
  );

//animation only the left side if below 50%
@if $i <=($howManySteps/2) {
  .progress[data-percentage="#{$stepName}"] {
    .progress-right .progress-bar {
      animation: loading-#{$i} $animationTime linear forwards;
    }

    .progress-left .progress-bar {
      animation: 0;
    }
  }
}

//animation only the right side if above 50%
@if $i >($howManySteps/2) {
  .progress[data-percentage="#{$stepName}"] {
    .progress-right .progress-bar {
      animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
    }

    .progress-left .progress-bar {
      animation: loading-#{$i -
 ($howManySteps/2)
    }

    $animationTime linear forwards $animationTime;
  }
}
}
}

//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (
    180/ ($howManySteps/2)
  );
$degrees: (
  $degrees * $i
);

@keyframes loading-#{$i} {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate($degrees);
    transform: rotate(#{$degrees}deg);
  }
}
}

// End Progress



.credimodal {
  max-width: 400px !important;
  margin: 0 auto !important;
}

.welc-text {
  background: #DFE6FF;
  margin-top: 3rem;

  h2 {
    color: $color2;
    font-size: 1.3rem;
  }

  p {
    line-height: 18px;
    color: #213067;


  }
}

.popup-dailog-box {
  position: relative;

  .text-info-details {
    font-size: 1rem;
  }

  .title {
    font-size: 1.2rem;
  }

  .btn-close {
    position: absolute;
    right: 14px;
    top: 14px;
    z-index: 5;
  }

  .bi {
    font-size: 1.5rem;
  }
}


.sign-box {
  height: 200px;
  background: $white-color url(../img/SignHere.png) no-repeat center center;

  &.signed {
    background: $white-color url(../img/signed.png) no-repeat center center;
  }
}

.signcheck {

  font-size: 13px;

  .checker {
    width: 20px;
    height: 20px;
    display: block;
  }
}

.sub-text {
  font-size: 1.1rem;
}

.slot-filed {
  background: $color8;
  font-size: 20px;
  padding: 15px;
  border-radius: 10px;
  color:$white-color;
  position: relative;
  cursor: pointer;
  border: 1px solid $color1;
  transition: all ease-in 0.3s;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
   &:hover {
      transform: translateY(-5px);
       transition: all ease-in 0.4s;
     }


  &.btn-c2 {
    padding: 20px 15px;
      
    // transition: all ease-in 0.3s;

    
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .icon {
    height: 40px;
    width: 40px;
    background: $color8;
    border-radius: 50%;
    display: inline-block;
    color: #fff;
    padding-top: 7px;
    font-size: 20px;
  }

  &.slot-email {

    font-size: 18px;
    font-weight: 500;

  }
}

.skip-btn {
  background: transparent;
  border: 2px solid #dddddd;
  color: #333;
  text-transform: initial;
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 18px;
  padding: 16px 15px;

  &:hover {
    background: #eee;
  }
}

input[type=radio] {
 display: none !important;
}





/*================================================
 HEADER END
==================================================*/

/*================================================
04 - BANNER
==================================================*/




.arrows path {
  stroke: #2994D1;
  fill: transparent;
  stroke-width: 3px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
  {
  0% {
    opacity: 0
  }

  40% {
    opacity: 1
  }

  80% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
}


.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(41, 148, 209, 0.5);
  }

  50% {
    box-shadow: 0 0 0 20px rgba(41, 148, 209, 0.2);
  }

  100% {
    box-shadow: 0 0 0 0px rgba(41, 148, 209, 0);
  }
}


.forms-fieldset {
  background: rgb(247, 247, 255);

  .headline {
    font-size: 18px;
    font-weight: 700;
  }
}

.list-style {
  li {
    list-style-type: disc !important;
  }
}

.notesec {
  font-size: 13px;
}

ol li {
  list-style-type: decimal;
}

.bg-gray {
  background-color: #F7F7FF;
}

.anchor {
  scroll-margin-top: 100px;
}

.top-head {

  h2,
  .h2 {
    font-size: 2.2rem;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
}



.agree-scroll {
  width: 100%;
  height: 180px;
  overflow-y: scroll;
  padding: 20px;
  background-color: #fff;
}


.stickyBtn {
  position: fixed;
  width: 100%;
  z-index: 238;
  bottom: 0;
  background: #000;
  padding: 20px 0;
}

/*================================================
footer start
==================================================*/



/*================================================
footer end
==================================================*/



.flip-vertical-fwd {
  -webkit-animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-fwd 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.quastion-area {
  margin-top: 80px;
  margin-bottom: 80px;

  .accordion-item {
    border: 1px solid #000;

    &:not(:first-of-type) {
      border: 1px solid #000;
    }
  }

  .accordion-button {
    font-size: 20px;
    color: $black-color;
    text-align: center;

    &::after {
      background: url(../img/down-arrow.png) no-repeat center;
      width: 26px;
      background-size: 15px;
    }

    &:not(.collapsed) {
      color: $white-color;
      background: $color1;

      &::after {
        background: url(../img/down-arrow.png) no-repeat center;
        width: 26px;
        background-size: 15px;
      }
    }
  }

  .accordion-body {
    p {
      font-size: 18px;
    }
  }
}

.review-section {
  background: #ebf0ff;
  padding: 80px 0 40px 0;

  .swiper-wrapper {
    padding: 10px 30px 30px 0px;
  }

  .feefo-review {
    background: $color1;
    border-radius: 10px;
    padding: 20px;
  }

  .review-box {
    border: 1px solid #eee;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: $color1;
    border-radius: 10px;
    height: 180px;
    transition: all ease-in 0.2s;

    &:hover {
      border-bottom: 5px solid $color1;
      transform: rotate(-1deg);
      transition: all ease-in 0.2s;
    }

    h3 {
      font-size: 16px;
    }

    .starimg {
      height: 25px;
    }
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;

  .loader-box {
    .loader {
      -webkit-animation: rotate-center 0.8s linear infinite both;
      animation: rotate-center 0.8s linear infinite both;
      transform-origin: center;
      width: 80px;
      height: 80px;
    }
  }
}

// Progress Circle

$borderWidth: 10px;
$animationTime: 0.5s;
$border-color-default: #eaeaea;
$border-color-fill: $color2;
$size: 120px;
$howManySteps: 100; //this needs to be even.



// End Progress

@-webkit-keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@keyframes flip-vertical-fwd {
  0% {
    opacity: 0;
    -webkit-transform: translateZ(160px) rotateY(180deg);
    transform: translateZ(160px) rotateY(180deg);
  }

  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fade-in {
  -webkit-animation: fade-in 0.4s ease-in both;
  animation: fade-in 0.4s ease-in both;
}

.shake-vertical {
  -webkit-animation: shake-vertical 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
  animation: shake-vertical 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both;
}

/*================================================
Keyframe
==================================================*/

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

@keyframes shake-vertical {

  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
  }

  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }

  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

/*================================================
07 - MEDIA QUERY 
==================================================*/
@media screen and (max-width: 1800px) {}

@media screen and (max-width: 1200px) {}

@media screen and (max-width: 991px) {

  .wrap-loader {
    .panel-banner {
      max-width: 700px;
    }
  }



}

@media screen and (max-width: 760px) {
  .splashbg {
    background: #0F1994 url("../img/mobile-splash.jpg") center;
    background-size: cover;
  }

  .wrap-loader {

    .panel-banner {
      max-width: initial;
      width: 100%;
      height: initial;
      background: none;
      margin: initial;

      .loader-panel {
        grid-template-columns: repeat(1, 1fr);

      }

    }
  }
  .optionbtn {
    input {
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 590px) {

  .slot-filed {
    font-size: 16px;

    .icon {
      width: 35px;
      height: 35px;
      font-size: 16px;
    }
  }

  .skip-btn {
    font-size: 14px;
  }

  .input-group-text {
    font-size: 14px;
    width: 100%;
    border-radius: 0;
  }
}








@media screen and (max-width: 470px) {

  html {
    font-size: .9rem;
  }

  .logo-two {
    >img {
      height: 43px;
    }
  }

  .panel-banner {
    &.app-banner {
      background-size: 188px;

      .loader {
        p {
          font-size: 1rem;
          line-height: 1.3rem;

          &.first-name {
            font-size: 1rem;

          }
        }

      }
    }
  }

  .dashboard-menu-wrap {
    .dash-menu-list {
      li {
        margin-bottom: 35px;
      }
    }

    .dashmenu-title {
      font-size: 1rem;
    }

    .dash-menu {
      cursor: pointer;

      h4 {
        font-size: .9rem;
      }

      .icon {
        width: 60px;
        height: 60px;

        svg {

          height: 34px;
        }
      }
    }
  }

  .connect-account {
    background-position: center bottom;
    padding-bottom: 100px;
    background-size: 290px;

    .btn-comm2 {
      max-width: 100%;
      padding: 20px 40px;
    }

    .title {
      font-size: 1.2rem;
    }

    &.kycupload {
      .tab-content {
        .title {
          font-size: 1.2rem;
        }

      }

    }
  }

  .credimodal {
    .creditpopup {
      .alertimg {
        height: 40px;
      }
    }
  }

  .app-header {
    .status-bar {
      .inner {
        width: 110px;
        height: 110px;
      }
    }
  }

  .id-uploads {
    .id-image {
      height: 150px;
    }
  }

  .welc-text {
    p {
      font-size: 13px;
    }
  }

  .btn-comm2 {
    padding: 10px 10px;
  }

  .income-form {
    .input-group {
      display: block;
      span {
        border-radius: 0;
      }
      input {
        width: 100%;
        border-radius: 0;
      }
    }
  }

}


@media only screen and (max-width: 790px) and (min-width: 540px) {}

@media only screen and (max-width: 540px) and (min-width: 470px) {}

}

.input-group-text {
  white-space: normal;
  text-align: left;
}