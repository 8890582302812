.followups3Quest{

.tmssignature_2023 {
	// =======background colors========
	$background_color_1: #ffffff;
	$background_color_2: #2f3996;
	$background_color_3: #1f1f1f;
	$background_color_4: #f9f9f9;
	$background_color_5: #72286f;
	$background_color_6: #FEEF06;
	$background_color_7: #cecece;
	$background_color_8: #fb0404;
	$background_color_9: #077736;

	// ======font styles===============
	$color_1: #ffffff;
	$color_2: #212529;
	$color_3: #636363;
	$color_4: #72286f;

	$font_family_1: 'Roboto', sans-serif !important;

	$font_size_1: 30px;
	$font_size_2: 22px;
	$font_size_3: 13px;
	$font_size_4: 14px;
	$font_size_5: 15px;
	$font_size_6: 16px;
	$font_size_7: 17px;
	$font_size_8: 18px;
	$font_size_9: 20px;

	$font-bold: bold;
	$font-normal: normal;
	$font-w500: 500;
	// ======zero values===============
	$border_0: 0px;
	$margin_0: 0px;
	$padding_0: 0px;

	// =======floats============
	$float_r: right;
	$float_l: left;
	// ======border===========
	$border-rd4: 4px;
	$border-style1: solid 1px rgba(255, 255, 255, 0.30);
	$border-style2: 4px solid #72286f;
	$border-style3: 2px solid #d5d5d5;
	$border-style4: 1px solid #b4b4b4;
	$border-style5: 1px solid #2f3995;

	@mixin shadow-1 {
		-webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.51);
		-moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.51);
		box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.51);
	}


	@mixin shadow-3 {
		-webkit-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.63);
		-moz-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.63);
		box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.63);
	}

	header {
		background: #257eba !important;
		text-align: center;
		padding: 10px 0 15px;

		.logo-part {
			img {
				width: 15%;
			}
		}
	}

	.signature-section {
		height: auto;

		@include shadow-1;

		// ====== form style=====
		.sign-question {
			background: $background_color_1;
			padding: 0px;
			border-radius: 4px;
			margin-bottom: 10px;
			position: relative;
			@include shadow-3;


			h2 {
				text-align: center;
				font-size: 20px;
				color: $color_1;
				padding: 20px 15px;
				font-weight: 600;
				background: #257eba;
				margin-bottom: 10px;
			}

			ul {
				margin: 0px;
				padding: 0 40px;

				li {
					background: url(../img/arrow-li.png) no-repeat left center;
					list-style: none;
					padding: 5px 0 5px 35px;
					margin-bottom: 0px;
					font-size: 17px;
				}
			}

			.sign-div {
				padding: 20px;

				.sign-text {
					position: absolute;
					z-index: 999;
					font-size: 17px;
					color: #5f5f5f;
					width: 94.5%;

					img {
						margin-top: 100px;
					}
				}

				.signature-pad {
					display: block;
					background: url('../img/sign-bg.png') no-repeat #fff 49% 67px !important;
					padding: 0.375rem 0.75rem;
					font-size: 1rem;
					line-height: 1.5;
					color: #495057;
					background-color: #fff;
					background-clip: padding-box;
					border: 1px solid #ced4da;
					border-radius: 0.25rem;
					transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
					margin: 0 auto;
					width: 100%;
					min-height: 300px;


				}

				.btn-clear {
					background-color: #fb0404;
					font-size: 15px;
					color: #fff;
					font-weight: 400;
					font-size: 20px !important;
					border-radius: 4px;
					padding: 6px 15px;
					border: 0;
					width: auto;
					cursor: pointer;
					margin: 5px 0;
					text-transform: uppercase;
				}

				.btn-submit {
					background-color: #309814;
					font-size: 15px;
					color: #fff;
					font-weight: 400;
					font-size: 20px !important;
					border-radius: 4px;
					padding: 9px 50px;
					border: 0;
					width: auto;
					cursor: pointer;
					text-transform: uppercase;
					float: right;
				}
			}

			h3 {
				font-size: 22px;
				color: $color_2;
				margin: 20px 0;
				font-weight: 500;
				text-align: center;
				line-height: 1.1;

				span {
					font-weight: bold;
					color: $color_4;
				}
			}

			h4.value {
				font-size: 50px;
				font-weight: bold;
				color: #94C55B;

				&::before {
					content: " \00A3";
					margin-right: 7px;
				}
			}

			h5 {
				margin-bottom: 20px;
				text-align: center;
				font-size: 18px;
				color: $color_2;
				margin: 25px 0;
				font-weight: normal;
			}
		}
	}

	@media screen and (max-width: 767px) {

		.signature-section {
			padding: 0px 0 !important;

			.sign-question {
				margin-bottom: 0px !important;

				h2 {
					font-size: 17px;
				}

				ul {
					li {
						margin-bottom: 0px;
						font-size: 14px;
					}
				}
			}
		}
	}

}
}