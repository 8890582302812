
.signinpopup{
  
  .blackover{
    background: rgba(0, 0, 0, 0.98) !important;
}


    h2 {
      font-size:26px;
      color: #004c70;
      margin-bottom: 15px;
     }
    .popup-sign-sec {
  
      h5 {
       font-size: 13px;
      }
      .clearbutton {
       background: #eee;
       border: none;
       outline: 0;
       font-size: 14px;
       padding: 6px 15px;
       border-radius: 5px;
     }
    .signbutton{
      background-color:#ef453d;
      color:#fff;
      padding: 10px 20px;
      border: 0px;
      border-radius: 7px;
      min-width: 110px;
    }
   
       .signbox {
       
         canvas {
           //min-width:500px;
          //height: 200px;
           border: 1px solid  #d5d5d5;
           border-bottom: 2px dotted #000;
          //  background: url(../img/signhere.png) no-repeat center center;
         }
     
       }

       .sign-check {
        font-size: 13px;
        text-align: justify;
    }
     }
     
     .web-canvas{
        //margin-bottom: 20px;
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        margin: auto;
        padding: 10px;
    }
  ul{
    list-style-type: none;
    text-align: center;
    // display: inline-flex;
    margin: 10px 0 20px 0;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    li{
      border:0px !important;
      margin: 0 5px !important;
      a{
      list-style: none;
      // border-right: 1px solid #beb8b8;
      padding: 5px 10px;
      background-color: #e7e6e6;
      border-radius: 5px;
      font-size: 14px;
      &:hover{
        background-color: #1891a5;
        color: #fff;
      }
      }
    }
  }
  
  
  
  }
  
  
  @media screen and (max-width: 959px) {
    .signinpopup{
      ul{
        flex-direction: unset;
        li{
          a{
            width: 100%;
            margin: 5px 0;
            display: block;
          }
        }
      }
      .popup-sign-sec{
        // .signbox canvas {
        //   max-width: 300px;
        //   height: 200px;
        //   min-width: 300px;
        // }
        .signbutton{
          min-width:160px;
        }
      }
    }
  
  }
  
  
  @media screen and (max-width: 579px) {
    .signinpopup{
      ul{
        flex-direction: unset;
        li{
          a{
            width: 100%;
            margin: 5px 0;
            display: block;
          }
        }
      }
      .popup-sign-sec{
        // .signbox canvas {
        //   max-width: 300px;
        //   height: 200px;
        //   min-width: 300px;
        // }
        .signbutton{
          min-width:160px;
        }
      }
    }

    
  
  }
