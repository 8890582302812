.thankyoupage{ 
    z-index: 999999;
    position: relative;
    padding-top: 180px;
    padding-left: 40px ;
    padding-right: 40px;
    .check{
        fill:none;
        stroke: rgb(27, 76, 168);
        stroke-width: 2px;
        stroke-linecap:round;
        stroke-linejoin:round;
        stroke-miterlimit:10;
    }
      
    .check {
        stroke-dasharray: 60 200;
        animation-delay: 1.7s;
        animation: check 2s cubic-bezier(0.67, 0, 0.1, 1) forwards 0.0s; 
        opacity: 0;
    }
    
    @-webkit-keyframes check {
        from {stroke-dashoffset: 60;
    opacity: 1;}
    
        to {stroke-dashoffset: 293;
        opacity: 1;}
    }
    
    
    .thankyou1{
        display: block;
        width: 100%;
        .smaller svg {
           enable-background:new 0 0 60 60;
        }
    }
    
    h2{
        margin-bottom: 10px;
        text-align: center; 
        font-weight: 500;
        color: white;
    }
    p{
        margin-bottom: 30px;
        text-align: center;
        color: white;

    }
    
    
    .smaller {
        width: 130px;
        text-align: center; 
        margin: 20px auto -50px auto;
        margin-bottom: 16px;
    }

  
}

.showcase2{ 
    height: auto !important;
}
