.Privacy_Policy{
/*
*******************************************
Template Name: TMS Legal
Author:Ramees R J
Date: 15-12-2022
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the TMS Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - BODY 
03 - howitworks 
04 - FOOTER 
05 - ANIMATION
06 - MODAL 
07 - MEDIA QUERY
 
============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/
$background_color_1: #ffffff;
$background_color_2: #69a2d7;
$background_color_3: #3ebbe9;
$background_color_4: #333333;
$background_color_5: #257eba;
$background_color_6: #e2091d;
$background_color_7: #2f9de8;
$background_color_8: #f9f9fa;
$background_color_9: #ff0000;
$background_color_10: #ffff00;
$background_color_11:#2f9de8;

$color_1: #ffffff;
$color_2: #777777;
$color_3: #257eba;
$color_4: #beb8b8;
$color_5: #1483ce;
$color_6: #0070ba;
$color_7: #2f9de8;
$color_8:#212529;




html {
    scroll-behavior: smooth;
}

a {
    cursor: pointer;
}

/*================================================
02 - BODY 
==================================================*/
body {
    font-family: 'Titillium Web', sans-serif;
}



header {
    //background: $background_color_5;
    text-align: center;
    //padding:10px 0 20px;

    h1 {
        color: $color_1;
        font-size: 24px;
        font-weight: 600;
        padding-top: 50px;
    }
    h2 {
        color: $color_1;
        font-size:22px;
        padding: 0;
        font-weight: 600;
        margin-bottom: 15px;
        text-align: center;
    }
    h3{
        strong{
            color: $color_5;
            font-size: 20px;
            padding: 0;
            background-color: $background_color_10;
        }
    }
    img{
        //max-width: 35%;
        height: 60px;
    }

}


.modal {
	background: rgba(0,0,0,0.85) !important;
}

/*================================================
04 - footer
==================================================*/

footer {
    padding: 25px 0;
    background: $background_color_4;
    .brdr-left {
        border-left: solid 1px $color_4;
    }
    .m-0 {
        margin: 0px;
    }
    ul {
        padding: 0px;
        text-align: left;
        margin-top: 15px;
        li {
            list-style: none;
            border-right: 1px solid $color_4;
            padding: 0px 10px;
            display: inline;
            color: $color_4;
            &:first-child {
                padding-left: 0px;
            }
            &:last-child {
                border: 0px;
            }
            a {
                text-decoration: none;
            }
        }
    }
    p {
        color: $color_4;
        font-size: 14px;
        text-align: left;
    }
    img{
        height: 110px;
        margin-bottom: 22px;
    }
}

/*================================================
06 - modal
==================================================*/





/*================================================
07 - media query
==================================================*/

@media (min-width: 992px) and (max-width: 1199.98px) {
    header {
        h1 {
            font-size: 30px;
        }
        h2 {
            font-size: 28px;
            line-height:36px;
        }
    }

}


@media screen and (max-width: 991.98px) and (min-width: 768px) {

    .brdr-left {
        border: 0px;
    }
    header {
        h1 {
            font-size: 34px;
        }
        h2 {
            font-size: 26px;
            line-height: 35px;
        }
    }
}

@media screen and (max-width: 767px) {
    header {
        h1{
            font-size: 28px;
        }

    }
    footer {
        height: auto;
        padding: 25px 0;
        background: #424242;
        text-align: left;
        p {
            margin: 7px 0 20px;
            padding: 0;
            font-size: 13px;
            color: $color_1;
            font-weight: 300;
            text-align: center;
            font-family: "Open Sans", sans-serif;
            a {
                color: #b1b1b1;
            }
        }
        ul {
            text-align: left;
            margin: 0;
            padding: 0px 0;
            li {
                display: block;
                border-top: 1px dotted #94a2a6;
                padding: 6px 0;
                font-size: 14px;
                border-right: 0px;
                a {
                    color: $color_1 !important;
                    font-size: 13px;
                }
            }
        }
    }

    .brdr-left {
        border: 0px !important;
    }
    .form-part{
        form{
            .pfrp{
                font-size: 15px;
            }
            h4{
                padding-top:0px;
            }
            .signature-div {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 576px) {
    .container {
        max-width: 100%;
    }
    h3{
        strong{
            font-size: 16px !important;
        }
    }
    label.radioa{
        font-size: 11px;
    }
    .form-part{
        form{
            h3{
                font-size: 16px !important;
            }
            .regNextBtn{
                font-size: 18px;
                min-height: 0;
                padding: 10px;
            }
            .car-reg{
                font-size: 14px;
                height: 50px !important;
            }
            .gb{
                font-size: 10px;
                height: 50px !important;
            }
        }
    }
    .radio-box{
        h3{
            font-size: 16px;
        }
        .question11{
            h3{
                font-size: 20px;
            }
            p{
                font-size: 16px;
            }
        }
    }
    .option_but{
        font-size: 15px !important;
    }
    .input-group-text{
        width: 100% !important;
    }
    .input-group>.form-control, .input-group>.form-select {
        font-size: 14px;
    }
}


@media (max-width: 768px){
    
        .sun-content{
            padding: 5px 0;
            p{
                font-size: 13px;
                line-height: 10px;
               

                img{
                    width:auto;
                    max-height: 27px;
                }
            }
        }

}
@media screen and (max-width: 767px) {
    header {
        h1{
            font-size: 20px;
            padding-top: 20px;
        }
        h2{ 
            font-size: 14px;
        }
        h3{
            strong{ 
                font-size: 18px;
            } 
        }
        img{
            height: 50px;
        }
    }
    .form-part{
        form{
            .top_minus_1{
                margin-top: 20px;
                border-radius: 20px;
            }
            .slide-logo {
                margin-top: 20px !important;
            }
        }
    }
    .input-group-text{
        width: 100% !important;
    } 
    .options{ 
        .custom-checkbox+label{
            height: auto;
            padding: 3px 0 3px 35px;
            line-height: 24px;
        }
        li{
            margin: 0 0 10px;
            float: left;
            text-align: left;
        }
    }
}
}