
.FLPClicktoCall{
$color1 :#63a311;
$color2 :#319cff;
$color3 :white;
$color4 :#e73e49;

.bg-main-pr {
    background: url(../img/bg87899.jpg) fixed !important;
    background-size: cover  !important;
    min-height: 100vh;

    .loader-wrap {
        display: flex;
        align-items: center;
        justify-content: center; 
        height: 100vh;
        width: 100%;
        background: url(../img/logo.png) no-repeat center 20px; 
        background-size: 200px;
        .loader {
            img {
                width: 100px;
            }
        }
    }
    

}
.pay-per-call {
    font-family: 'Titillium Web', sans-serif !important;
    text-align: center;
  
    h1 {
        font-weight: bold;
        font-size: 32px;
        color: $color4;
    }

    .img_2{ 
        width: 80px; 
        height: auto;
    }

    .box_1{ 
        border: 1px solid black ;
        border-style: dashed;
        width: 300px;
        margin: 18px  auto  1px ;
        span{ 
            font-weight: 600;
            color: red;
        }
    }

    .counter_p{ 
        font-size: 18px;
        span{ 
            color: red;
        }
    }

    .text_2a{ 
        font-size: 23px;
    }

    p {
        font-size: 23px;
    }
    .sub_head_til{
        max-width: 800px; 
        margin: 0 auto;
    }

    .text2b {
        i {
            color: $color1;
        }
        font-size: 18px;
    }



.btn-comm2{
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, $color2 0, $color2 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    width: 100%;  
    text-align: center;
    font-size: 18px;
    padding: 15px 30px;
    letter-spacing: 1px;
    span {
        font-size: 28px;
        background: #00000040;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: inline-block;
        text-align: center;
        line-height: 17px;
        margin-left: 10px;
    }
    
    &:focus {
        box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
      }
      &:hover {
        box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
        transform: translateY(-2px);
      }

      &:active {
        box-shadow: #3c4fe0 0 3px 7px inset;
        transform: translateY(2px);
    }
    
    &.second {
        background: $color1;

        &:focus {
            box-shadow: #446e0c 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c 0 -3px 0 inset;
        }

        &:hover {
            box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c  0 -3px 0 inset;
            transform: translateY(-2px);
        }
    

        &:active {
            box-shadow: #446e0c 0 3px 7px inset;
            transform: translateY(2px);
        }
        
    }
}

.loader-wrap {
    display: flex;
    align-items: center;
    justify-content: center; 
    height: 100vh;
    width: 100%;
    background: url(../img/logo.png) no-repeat center 20px; 
    background-size: 200px;
    .loader {
        img {
            width: 100px;
        }
    }
}





@media only screen and ( min-width: 1600px) and (max-width: 4000px)  {

    .main {
        p {
            font-size: 16px;
        }
        h1 {
            font-size: 40px;
        }
        .img_2{ 
            width: 120px; 
            height: auto;
        }
        .text_2a{ 
            font-size: 26px;
        }
        .text2b{ 
            font-size: 20px;
        }
    }
}



@media only screen and (max-width: 1200px) and (min-width: 960px)  {

    .main {
        p {
            font-size: 16px;
        }
    }
}


@media (max-width: 3600px){ 
    .item-cent-flx {
        min-height: 64vh;        
        display: grid;
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
    }
}


@media (min-width: 990px){ 
    .brand-logo {
        width: 100%;
        // margin-top: 28px;
    }

}


@media (max-width: 768px){
    .main {
        padding-bottom: 60px;
        p {
            font-size: 14px;
        }
        h1 {
            font-size: 26px;
        }
    }
}

@media (max-width: 490px){
    .main {
        h1 {
            font-size: 24px;
        }
        p,.text3b,.text2b {
            font-size: 15px;
        }
        .text_2a{ 
            font-size: 19px;
        }
         .brand-logo {
            img {
                height: 50px;
            }
         }
    }



}


.anim_green1 {
    animation: 1200ms ease 0s normal none 1 running shake;
    animation-iteration-count: infinite;
    // -webkit-animation: 1200ms ease 0s normal none 1 running shake;
    // -webkit-animation-iteration-count: infinite;
}


.fa-phone{ 
    font-size: 18px !important;
    padding-right: 6px;
}




// ____________________________________________________________



@keyframes hoverWave {
    0% {
        box-shadow: 0 8px 10px rgba(7, 138, 29, 0.3), 0 0 0 0 rgba(7, 138, 29, 0.2), 0 0 0 0 rgba(7, 138, 29, 0.2)
    }

    16% {
        box-shadow: 0 8px 10px rgba(7, 138, 29, 0.3), 0 0 0 15px rgba(7, 138, 29, 0.2), 0 0 0 0 rgba(7, 138, 29, 0.2)
    }

    32% {
        box-shadow: 0 8px 10px rgba(7, 138, 29, 0.3), 0 0 0 30px rgba(7, 138, 29, 0), 0 0 0 26.7px rgba(7, 138, 29, 0.067)
    }

    40% {
        box-shadow: 0 8px 10px rgba(7, 138, 29, 0.3), 0 0 0 30px rgba(7, 138, 29, 0), 0 0 0 40px rgba(7, 138, 29, 0.0)
    }
    100% {
        box-shadow: 0 8px 10px rgba(7, 138, 29, 0.3), 0 0 0 30px rgba(7, 138, 29, 0), 0 0 0 40px rgba(7, 138, 29, 0.0)
    }
}

/* animations icon */

@keyframes shake {
    0% {
        transform: rotateZ(0deg);
        // -ms-transform: rotateZ(0deg);
        // -webkit-transform: rotateZ(0deg);
    }

    10% {
        transform: rotateZ(-2deg);
        // -ms-transform: rotateZ(-2deg);
        // -webkit-transform: rotateZ(-2deg);
    }

    20% {
        transform: rotateZ(2deg);
        // -ms-transform: rotateZ(2deg);
        // -webkit-transform: rotateZ(2deg);
    }

    30% {
        transform: rotateZ(-1deg);
        // -ms-transform: rotateZ(-1deg);
        // -webkit-transform: rotateZ(-1deg);
    }

    40% {
        transform: rotateZ(1deg);
        // -ms-transform: rotateZ(1deg);
        // -webkit-transform: rotateZ(1deg);
    }

    50% {
        transform: rotateZ(-.75deg);
        // -ms-transform: rotateZ(-.75deg);
        // -webkit-transform: rotateZ(-.75deg);
    }

    58% {
        transform: rotateZ(.75deg);
        // -ms-transform: rotateZ(.75deg);
        // -webkit-transform: rotateZ(.75deg);
    }

    68% {
        transform: rotateZ(-.5deg);
        // -ms-transform: rotateZ(-.5deg);
        // -webkit-transform: rotateZ(-.5deg);
    }

    75% {
        transform: rotateZ(.25deg);
        // -ms-transform: rotateZ(.25deg);
        // -webkit-transform: rotateZ(.25deg);
    }

    80% {
        transform: rotateZ(-.25deg);
        -ms-transform: rotateZ(-.25deg);
        -webkit-transform: rotateZ(-.25deg);
    }

    90% {
        transform: rotateZ(0deg);
        // -ms-transform: rotateZ(0deg);
        // -webkit-transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(0deg);
        // -ms-transform: rotateZ(0deg);
        // -webkit-transform: rotateZ(0deg);
    }
}

}

}