	/*
	*******************************************
	Template Name: Allegiant
	Author: Arun Ravi
	Date:18-07-2022
	Template By: Vandalay Designs
	Copyright 2021-2022 Vandalay Business Solution

	* This file contains the styling for the Allegiant, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/


.ObFollowUpV2{
	.__ExpampleDemo1 {
		min-height: 100vh;

		.showcase {
			height: 100vh;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: #fff;
			padding: 0 60px;

			@media only screen and (max-width: 968px) {
				padding: 0 20px;
			}

			@media only screen and (max-width: 600px) {
				padding: 0 20px;
			}

			@media only screen and (max-width: 480px) and (min-width: 430px) {
				padding: 30px 20px;
				justify-content: center;
			text-align: center;
			}

			@media only screen and (max-width: 430px) and (min-width: 392px) {
				padding: 100px 20px;
			}

			@media only screen and (max-width: 392px) {
				padding: 50px 20px;
			}

			.video-container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: hidden;

				video {
					width: 100%;
					min-height: 100%;
					object-fit: cover;
				}

				&:after {
					content: '' !important;
					z-index: 1 !important;
					height: 100% !important;
					width: 100% !important;
					top: 0 !important;
					left: 0 !important;
					background: rgba(0, 0, 0, 0.753) !important;
					position: absolute !important;
				}
			}

			.content {
				z-index: 2;

				h1 {
					font-size: 40px;
					line-height: 140%;

					@media only screen and (max-width: 980px) {
						font-size: 34px;
					}

					@media only screen and (max-width: 640px) {
						font-size: 21px;
					}
				}

				h3 {
					font-size: 22px;
					line-height: 140%;
					font-weight: 400;
					// color: black;
					padding: 5px;
					span {
						background: yellow;
						border-radius: 5px;
						 
						
					}
					@media only screen and (max-width: 980px) {
						font-size: 17px;
						max-width: 80%;
    					margin: 0 auto;
						span {padding: 5px;}
					}

					@media only screen and (max-width: 790px) {
						font-size: 18px;
						span { display: block;}
					}
				}

				.cls_btn {
					z-index: 2;

					.close_bbt {
						display: inline-block;
						border: none;
						outline: none;
						padding: 13px 30px;
						background: transparent;
						border: 2px solid #fff;
						color: #fff;
						border-radius: 5px;
						font-size: 18px;
						margin-top: 20px;
						width: 350px;
						font-weight: normal;

						@media only screen and (max-width: 600px) {
							width: auto;
						}
					}
				}
			}


			.com-btn {
				padding: 13px 30px;
				background: #049e0c;
				color: #fff;
				font-size: 20px;
				border: none;
				outline: 0;
				border-radius: 5px;
				width: 100% !important;
			}

			.splash-logo-top {
				position: absolute;
				top: 28px;
				z-index: 22;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}

		}

	}
}