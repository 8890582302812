.preview {
$font_family_1: Titillium Web,sans-serif;

html {
	scroll-behavior: smooth;
	
}

a {
	cursor: pointer;
}


.splash{
	padding: 0px;
	margin: 0px;
	&::before{
		z-index: -1;
		opacity: 0.4;
		content: "";
		position: fixed;
		height: 100%;
		width: 100%;
		background: #3ec1ff54!important;
		background: -webkit-gradient(left top,left bottom,color-stop(0,#3ec1ff54),color-stop(24%,#fff),color-stop(49%,#fff),color-stop(69%,#fff),color-stop(100%,#3ec1ff54))!important;
		background: linear-gradient(
		180deg,#3ec1ff54 0,#fff 24%,#fff 49%,#fff 69%,#3ec1ff54)!important;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3ec1ff54",endColorstr="#5cc0ad",GradientType=0);
	}
    .loader{        
        font-family: $font_family_1;
        img{
            width: 100px;
            margin-top: 20px;
        }
        p{
            color: #2f3996;
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            margin-top: 10px;
        }
        .btn-next {
            background: #69a2d7;
            color: #fff;
            font-weight: 400;
            font-size: 20px;
            padding: 15px 10px;
            width: 100%;
            cursor: pointer;
            border: 0;
            border-radius: 5px!important;
            float: right;
            margin: 15px 0;
            text-decoration: none;
            &:hover{
                background-color: #2387e3;
            }
        }
    }

}
}