.OpenBankingV1 {

  $color1 : #63a311;
  $color2 : #319cff;
  $color3 : white;
  $color4 : #e73e49;
  $color5 : #3e6d02;

  // background: url(../img/bg-new.jpg) fixed !important;
  background-size: cover !important;
  .showload{
    display: grid;
    min-height: 100vh;
  }

  .main {
    &::after {
      //background: url(../img/bg-00.png) no-repeat fixed right;
      //background-size: cover;
      content: "";
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      background-position: 0 10px;
      z-index: -1;

    }
    .step_head{
      color: #2e71b1;
    font-size: 21px;
    font-weight: 700;
    }

    h1 {
      font-weight: bold;
      font-size: 28px;
      color: $color4;

    }

    .red-text{
      color: $color4;
    }

    .anim_green{
      animation: pulse 1.25s cubic-bezier(.66,0,0,1) infinite;
      box-shadow: 0 0 0 0 #4a7a0d;
    }

    h2 {
      font-weight: bold;

      .small {
        color: $color1;
        font-size: 18px;
        font-weight: normal;
      }
    }

    p {
      font-size: 23px;

    }

    fieldset {
      background: #fff;
      position: relative;
      padding: 15px;
      box-sizing: border-box;
      border: solid #cdcdcd 1px;
      height: 140px;
      border-radius: 5px;

      p {
        font-size: 16px;
      }

      &.first {
        border-color: $color1;
        border-width: 3px;
      }

      legend {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        padding: 0 5px;

      }
    }

    .text2b {
      i {
        color: $color1;
      }

      font-size: 18px;
    }

    .text3b {
      font-style: italic;
      font-size: 16px;
    }

  }

  .btn-info_section {
    min-height: 100vh;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;

    &::before {
      z-index: -1;
      opacity: 0.3;
      content: "";
      position: fixed;
      height: 100%;
      width: 100%;
      background: #ffffff !important;
    }

    .inside_refresh{
      min-height: 100vh;
      padding: 0px;
      margin: 0px;
      display: flex;
      align-items: center;
    }
  .btn-info_part {
    display: flex;
    justify-content: center;
    align-items: center;

      .space {

        .anim_blue {
          animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
          box-shadow: 0 0 0 0 #113e67;
        }

        .refresh_btn_outer{
          height: 41px !important;
          margin: auto !important;
          border-radius: 50px;
        }

        .button {
          position: relative;
          width: 40%;
          height: 53px;
          margin: 30px auto 0;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          overflow: hidden;
          //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 3px 8px rgba(0, 0, 0, 0.1);
          transition: all 0.1s cubic-bezier(0, 0.22, .3, 1);
          color: #fff;
          font-size: 1rem;
          z-index: 10;
          font-weight: 600;
          letter-spacing: 2px;
          border:none;
          
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.1);
          }

          .btn_proceed{
            position: relative;
            width: 100%;
            height: 53px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            overflow: hidden;
            //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 3px 8px rgba(0, 0, 0, 0.1);
            transition: all 0.1s cubic-bezier(0, 0.22, .3, 1);
            color: #fff;
            font-size: 1rem;
            background: transparent;
            z-index: 10;
            font-weight: 600;
            letter-spacing: 2px;
            border:none;
          }

          .refresh_btn{
            height: 41px !important;
            padding: 0 45px;
          }
          &._1 {
            background: #2980b9
          }
          .back {
            position: absolute;
            width: 0;
            height: 0;
            filter: url(#filter);
            border-radius: 50%;
            z-index: 5;
            transition: all 1.1s cubic-bezier(0.1, 0.22, .3, 1);
          }

          &._1 .back {
            top: -43%;
            background: #113e67;
          }

          &:hover .back {
            width: 692px;
            height: 92px;
          }
        
        }

      }
  }
}

  .clearfix {
    &::after {
      content: "";
      clear: both;
      display: table;
    }
  }

  .p-style{
    max-width: 800px; 
    margin: 0 auto;
  }

  .btn-comm2 {
    padding: 20px 60px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, $color2 0, $color2 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 22px;
    width: 100%;
    text-align: center;

    span {
      font-size: 28px;
      background: #00000040;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: inline-block;
      text-align: center;
      line-height: 17px;
      margin-left: 10px;
    }

    &:focus {
      box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    }

    &:hover {
      box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
      transform: translateY(-2px);
    }

    &:active {
      box-shadow: #3c4fe0 0 3px 7px inset;
      transform: translateY(2px);
    }

    &.second {
      background: $color1;
      border-bottom: solid 3px $color5;

      &:focus {
        box-shadow: #446e0c 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c 0 -3px 0 inset;
      }

      &:hover {
        box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c 0 -3px 0 inset;
        transform: translateY(-2px);
      }


      &:active {
        box-shadow: #446e0c 0 3px 7px inset;
        transform: translateY(2px);
      }


    }
  }



  .loader-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: url(../img/logo.png) no-repeat center 20px;
    background-size: 200px;

    .loader {
      img {
        width: 100px;
      }
    }
  }

  .dis_none {
    display: none;
  }

   button.disablebtn:disabled {
      opacity: 0.7;
      cursor: not-allowed;
   }


  @media only screen and (max-width: 1200px) and (min-width: 960px) {

    .main {
      p {
        font-size: 16px;
      }
    }
  }

  @media (min-width: 990px) {
    .brand-logo {
      position: absolute;
      top: 25px;
      width: 100%;
    }

    .item-cent-flx {
      height: 100vh;
      display: grid;
      grid-template-columns: 1fr;
      justify-content: center;
      align-items: center;

    }

  }

  @media (max-width: 768px) {
    .main {
      p {
        font-size: 14px;
      }

      fieldset {
        height: auto;

        .bud-logo {
          width: 115px;
          margin-bottom: 15px;
        }
      }

      padding-bottom: 60px;

      h1 {
        font-size: 24px;
      }


    }

    .btn-comm2 {
      font-size: 18px;
      padding: 15px 30px;
    }



  }

  @media (max-width: 490px) {
    .main {
      h1 {
        font-size: 18px;
      }

      p,
      .text3b,
      .text2b {
        font-size: 14px;
      }

      .brand-logo {
        img {
          height: 50px;
        }
      }
    }
  }


  @media only screen and (max-width: 768px) and (min-width: 480px) {
    .space {
      .htext {
        font-size: 16px !important;
      }

      .api-logo{
        width: 15% !important;
      }

      .sub-text {
        font-size: 14px !important;
      }

      .button {
        width: 50% !important;
      }
      .w-100{
        width: 100% !important;
      }
      .btn_proceed{
        width: 100% !important;
        font-size: 12px !important;
      }
    }
  }

  
  @media (max-width: 480px) {
    .container {
      max-width: 100%;
    }

    .space {
      .logo_img {
        width: 160px !important;
      }
      .api-logo{
        width: 22% !important;
      }

      .htext {
        font-size: 16px !important;
      }

      .sub-text {
        font-size: 12px !important;
      }

      .button {
        width: 100% !important;
      }
      .btn_proceed{
        width: 100% !important;
        font-size: 12px !important;
      }
    }

    .loader {
      .loader-anime {
        img {
          width: 85%;
        }
      }
    }

  }
 
.iframe-container {
  position: relative;
  height: 100%;
  min-height: 100vh;
  iframe {
    height: 100%;
    width: 100%;    
    left: 0;
    top: 0;
    position: absolute;
    body,html {
      height: 100%;
      overflow: hidden;
      background: transparent;
    }
  }
}
}