.Question_section_one{
  /*
*******************************************
Template Name: TMS Legal
Author: SHAREEF
Date: 14-02-2024
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - RANGE SLIDER 
06 - form-part 
07 - MEDIA QUERY  

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/


/*================================================
03 - BODY STYLES
==================================================*/

$color1: #3A8DDB;
$color2: #1891a5;
$color3: #1B1B1B;
$color4: #1FCCF2;
$color5: #DF4514;
$color6: #1FCCF2;
$color7: #DF2C2C;
$color8: #EF8019;
$color9: #1ED1BC;
$color10: #81e05f;
$color11: #a2d0f9;
$color12: #946FFF;
$color13: #9b9b9b;

$black-color: #000;
$white-color: #fff;
* {
  outline: none;
}

$font_family_1: 'Poppins', sans-serif !important;
font-family: $font_family_1;
  color: $black-color;

.show{
  display: block !important;
}

.hide{
  display: none !important
}

.modalcontentcar {
  opacity: 0.5; /* Adjust the opacity value as needed */
}

.que-title{
  color: #000 !important;
}

.active{
  .que-title{
    color: #9b9b9b !important;
  }

}

$diagonal: 26px; // 13px arrow
$size: $diagonal / 1.414; // Формула диагонали квадрата, d = sqrt(2)*a;
$half: ($diagonal / 2);
$halfNegative: ($diagonal / -2);

.tipbox {
  width: 100%;
  height: auto;
  position: relative;
  background:rgba(24, 206, 15, 0.8);
  margin-top: 10px;
  color:#fff;
  padding:10px;
  border-radius:7px;
  font-size: 15px;
  text-align: center;
  animation: pulse1 1500ms infinite;
  margin-bottom: 15px;
}

.arrow {
  position: absolute;
  overflow: hidden; 
  &:after {
    content: '';
    background: rgba(24, 206, 15, 0.8);
    width: $size;
    height: $size;
    position: absolute;
    border: 2px solid #0dd624;
    transform-origin: 0 0;
  }
}

.arrow-top {
  width: $diagonal;
  height: $half;
  left: 10%;
  top: $halfNegative;
  margin-left: $halfNegative;
  
  &:after {
    left: $half;
    top: 0;
    transform: rotate(45deg);
  }
}

.banner {
  &.qs-section {
    height: initial;
    overflow-x: inherit;
  }

  &.lenderSection {
    height: inherit;
    min-height: 100vh;
    overflow: inherit;
  }
}


.questionnair-section {
  padding-left: 10rem;
  position: relative;

  ul {
    li {
      // &:nth-child(1) {
      //   .questitem {
      //     h1 {
      //       color: black;
      //     }
      //   }
      // }
    }
  }

  .listStyle{
    opacity: 0.6;
    font-size: 15px;
    li{
      list-style-type: disc !important;
    }
  }

  &::before {
    content: "";
    width: 2px;
    height: 100%;
    background: #ffffff24;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(77px);
  }

  .questitem {
    background: $white-color;
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
    cursor: pointer;
    a {
      color: $black-color;
    }

    .skip-btn {
      color:#949494;
    }    
    &::before {
      content: "";
      width: 25px;
      height: 25px;
      background: $white-color;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      transform: translateX(-95px);
      transition: all ease-in 0.5s;
      animation: pulse 1500ms infinite;

      @keyframes pulse {
        0% {
          box-shadow: #fa7159 0 0 0 0;
        }

        75% {
          box-shadow: #ff69b400 0 0 0 16px;
        }
      }


    }

    &.active {
      &::before {
        background: $color7 url("../img/check.png") no-repeat;
        transition: all ease-in 0.5s;
        background-position: center center;
        animation: initial;
      }
    }

    h1 {
      font-size: 1.1rem;
      margin: 0;
      color: #9b9b9b;

      &.text-style {
        color: $black-color !important;
        // text-decoration: line-through;
      }

      &.text-finish {

        color: #9b9b9b !important;
      }
    }
  }
}

.list {
  li {
    margin-bottom: 10px;

    .optionbtn {



      label {
        padding: 8px;
        display: block;
        font-size: .9rem;
      }


    }
  }
}
.more-option {
  width: 24%;
  label {
    width: 100%;
    text-align: center;
  }
}

.optionbtn {
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 100%;
    visibility: hidden;
  }

  input:checked+label {
    background: $color4;
    color: $white-color;
  }

  label {
    padding: 15px 20px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border: 1px solid $color4;
  }
}


.option-grid {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  &.doculist {
    li {
      width: 32.5%;
    }
  }

  li {
    width: 24%;

    .optionbtn {
      label {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.popupclaim {
  h4 {
    font-size: 1.2rem;
  }
}

.loader-wrap {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    line-height: 1.5rem;
  }
  .succtext {
      animation: showtext 2.5s ease 100ms backwards;
      opacity: 1;
      line-height: 2.3rem;
      animation-delay: 1s;
      small{
        font-size: 1rem;
      }    
  }
  @keyframes showtext {
    from {
      opacity: 0;
       
    }
  
    to {
      opacity: 1;
       
    }
  }
  .loaderrdiv{
    padding-top: 90px;
  }

 
}


.drop-down-multi {
  position: relative;
  text-align: left;
  z-index: 999;
  select{
    height: 55px;
  }
  .choices__input {
    background: transparent;
  }

  .choices__inner {
    background: transparent;
    border-radius: 10px;
    border-color: #a2d0f9;
  }

  .choices__list--dropdown {
    z-index: 999 !important;
  }

  .choices__list--multiple {
    .choices__item {
      font-size: 14px;
      background: #ff8989;

    }
  }
}


.section-lender {
  padding-bottom: 8rem;
  .title {
    font-size: 1.8rem;
  }
  .choices__input {
    font-size: 18px;
  }
  .qs-title {
    line-height: 2rem;
    font-size: 1.3rem;
  }
  .btn-comm2 {
    height: 50px;
  }
}

.btn-comm4 {
	background:  $color7;
  padding: 20px 30px;
	border: none;
  position: relative;
	z-index: 1;
  color: $white-color;
  border-radius:5px;
  font-weight: bold;
  span {
    position: relative;
    z-index: 4;
  }

	&:after {
		position: absolute;
		content: "";
		width: 0;
		height: 100%;
		top: 0;
		right: 0;
		background-color:  #f54242;
		transition: all 0.3s ease;
    z-index: -1;
    border-radius:5px;
	}
	&:hover {
		color: $white-color;
		&:after {
			left: 0;
			width: 100%;

		}
	}
	&:active {
		top: 2px;
	}
}

.btn-height {
  min-height: 80px !important;
}
 

//////////////////////////////////////////////////////////////////////


.height-scale {
  -webkit-animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: height-scale 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.scale-down-ver-center {
  -webkit-animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-down-ver-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: .3s;
}

////////////////////////// Keyframe ////////////////////////////////////////////

@-webkit-keyframes scale-down-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {

    -webkit-transform: scaleY(1);
    transform: scaleY(1);

  }
}

@keyframes scale-down-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}






////////////////////////// Keyframe ////////////////////////////////////////////
@media screen and (max-width: 1200px) {
  .questionnair-section  {
    .option-grid  {
      li{
        width: 32%;
      }
    }
    .more-option {
      width: 32%;
    }

  }
    
   
 
}


@media screen and (max-width: 990px) {

  .questionnair-section {
    .more-option {
      width: 100%;
      
    }
    .questitem {
      h1 {
        font-size: 1rem;
      }
    }

    .option-grid {

      li {
        width: 100%;

      }

      &.doculist {
        li {
          width: 100%;
        }
      }
    }
  }

  .list {
    li {
      .optionbtn {
        label {
          padding: 10px;
        }
      }
    }
  }

  .section-lender {
    .title {
      font-size: 1.2rem;
      line-height: 1.6rem;
      img {
        height: 110px;
        margin-right: 10px;
      }
    }
    .qs-title {
      line-height: initial;
      font-size: 1rem;
    }
    .choices__input {
      font-size: 14px;
    }
    
  }

 
}


@media screen and (min-width: 960px) {
 



}

@media screen and (max-width: 470px) {
    
  .questionnair-section {
    padding-left: 2rem;

    &::before {
      transform: translateX(8px);
    }

    .questitem {
      h1 {
        font-size: 1rem;
      }

      &::before {
        transform: translateX(-29px);
        width: 18px;
        height: 18px;

      }

      &.active {
        &::before {
          background-size: 12px;
        }
      }
    }

    .section-lender {
      .title {
        font-size: 1.2rem;
        img {
          height: 86px;
          margin-right: 10px;
        }
      }
      .qs-title {
        line-height: initial;
        font-size: 1.1rem;
      }
      
    }

   
    
  }
  
        .loaderrdiv{
          padding-top: 80px !important;
        }

}
}

.carmodal{
  max-width: 300px;
    margin: 0 auto;
  .modelxp {
    min-height: 150px;
    img {
      height: 70px;
    }
  }
}

@media screen and (max-width: 576px){
}