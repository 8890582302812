.id_followup {
  /*
	
*******************************************
	Template Name: Tms
	Author: Anush 
	Date:29-04-2023
	Template By: Vandalay Designs
	Copyright 2023-2024 Vandalay Business Solution

	* This file contains the styling for the Tms, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/


  // 	============================================
  // ==== T A B L E   O F   C O N T E N T S =====
  // ============================================
  // 01 - Sass Variables
  // 02 - BODY
  // 03 - MEDIA QUERY



  /*================================================
01 - Sass Variables
==================================================*/
  $background_black: #0000;
  $background_white: #ffffff;
  $background_color_1: #ececec;

  $color_1: #1374f6;
  $color_2: #ffffff;
  $color_3: rgb(54, 54, 54);
  $color_4: #252525;
  $color_5: rgb(238, 20, 20);

  $background_gradient_1: linear-gradient(264deg, #f69b00, #ed6a00);
  $background_gradient_01: linear-gradient(92deg, #f69b00, #ed6a00);
  $background_gradient_2: url(/assets/IdFollowup/img/shape-inside.png)no-repeat right bottom,
  linear-gradient(90deg, #1374f6, #066ef7);
  $background_gradient_02: url(/assets/IdFollowup/img/shape-inside-hover.png)no-repeat right bottom,
  #0449a6;
  $background_gradient_3: url(/assets/IdFollowup/img/768b5161fb435eaf5cc833c580a362cc.svg),
  linear-gradient(264deg, #4796ff, #3b90ff);
  $background_gradient_03: url(/assets/IdFollowup/img/768b5161fb435eaf5cc833c580a362cc.svg),
  linear-gradient(92deg, #4796ff, #3b90ff);
  $background_gradient_4: url(/assets/IdFollowup/img/768b5161fb435eaf5cc833c580a362cc.svg),
  linear-gradient(264deg, #08b932, #038a23);
  $background_gradient_04: url(/assets/IdFollowup/img/768b5161fb435eaf5cc833c580a362cc.svg),
  linear-gradient(92deg, #08b932, #038a23);

  /*================================================
01 - BODY
==================================================*/
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  background-image: url(/assets/IdFollowup/img/bg-img.png) !important;
  background-size: cover;
  background-position: center;
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Roboto',
  sans-serif !important;

  .error-msg {
    width: 100% !important;
    float: left !important;
    background: #ff0000 !important;
    font-size: 13px !important;
    color: white !important;
    padding: 0px 5px !important;
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    margin-top: 1px !important;
  }

  .bg_img {
    .img-here {
      background-position: center !important;
      background-repeat: no-repeat !important;
      padding: 4% 0 0;
      width: 210px !important;
    }
  }

  .dis_none {
    display: none;
  }

  .dis_block {
    display: block;
  }

  .tms_id_upload {

    .button-transparent {
      border: transparent;
      padding: 0;
    }

    .tmsbodysec {
      .tms_main {
        .tms_main_row {
          padding-top: 9%;

          .ques-title {
            color: $color_4;
            font-weight: 650;
            font-size: 22px;
          }

          .sub_title {
            font-size: 16px;
            font-weight: 600;
            font-style: italic;
          }
        }

        .select_box_combo {
          padding-top: 2%;

          .select_box_1 {
            padding: 15px 0 0;
            background: $background_gradient_2;
            border-radius: 10px;
            transition: all 1s;
            cursor: pointer;

            p {
              font-size: 18px;
              font-weight: 700;
              color: $color_2;
              padding: 0 15px;
              text-align: left;
              margin-bottom: 44px;
            }

            .tik_box1 {
              .icon_sec {
                img {
                  padding: 10px;
                  background: white;
                  border-top-left-radius: 16px;
                  border-bottom-right-radius: 8px;
                }
              }
            }
          }

          .select_box_2 {
            padding: 15px 0 0;
            background: $background_gradient_2;
            border-radius: 10px;
            transition: all 1s;
            cursor: pointer;

            p {
              font-size: 18px;
              font-weight: 700;
              color: $color_2;
              padding: 0 15px;
              text-align: left;
              margin-bottom: 44px;
            }

            .tik_box2 {
              .icon_sec {
                img {
                  padding: 10px;
                  background: white;
                  border-top-left-radius: 16px;
                  border-bottom-right-radius: 8px;
                }
              }
            }
          }

          .select_box_3 {
            padding: 15px 0 0;
            background: $background_gradient_2;
            border-radius: 10px;
            transition: all 1s;
            cursor: pointer;

            p {
              font-size: 18px;
              font-weight: 700;
              color: $color_2;
              padding: 0 15px;
              margin-bottom: 71px;
              text-align: left;
            }

            .tik_box3 {
              .icon_sec {
                img {
                  padding: 10px;
                  background: white;
                  border-top-left-radius: 16px;
                  border-bottom-right-radius: 8px;
                }
              }
            }
          }

          .hover-eff {
            transition: all 1s ease-in-out;

            &:hover {
              background: $background_gradient_02;
              transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
              -webkit-box-shadow: 3px 6px 18px 7px rgba(204, 204, 204, 0.81);
              -moz-box-shadow: 3px 6px 18px 7px rgba(204, 204, 204, 0.81);
              box-shadow: 3px 6px 18px 7px rgba(204, 204, 204, 0.81);
            }
          }
        }


        .preview_row1,
        .preview_row2,
        .preview_row3 {
          margin: 0;
          padding-top: 70px;

          .preview_col {
            background: $background_color_1;
            padding: 23px
          }

          .b-left-radius {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }

          .b-right-radius {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }

          .preview_img {
            background-image: url(/assets/IdFollowup/img/plus-mark.png);
            background-repeat: no-repeat;
            background-position: center;

            .profile_pic {
              max-width: -webkit-fill-available;
              border: 3px solid whitesmoke;
              border-radius: 15px;
              -webkit-box-shadow: 6px 16px 21px -2px rgba(219, 219, 219, 1);
              -moz-box-shadow: 6px 16px 21px -2px rgba(219, 219, 219, 1);
              box-shadow: 6px 16px 21px -2px rgba(219, 219, 219, 1);
              opacity: 0.38;
            }

            .profile_pic_two {
              max-width: 250px;
              max-height: 200px;
            }
          }

          .preview_btn {
            padding: 0;
          }

          .btn_upload {
            font-size: 19px;
            font-weight: 600;
            padding: 9px 0;
          }

          p {
            color: $color_4;
            font-size: 17px;
            font-weight: 700;
            margin-bottom: 10px !important;
          }

          .button-tab {
            background: $background_gradient_3;
            color: #fff !important;
            padding: 8px 0;
            border: 0px;
            border-radius: 5px;
            font-weight: 500;
            font-size: 17px;
            display: inline-block;
            text-decoration: none;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            margin-bottom: 2%;

            input[type=file] {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              text-align: right;
              filter: alpha(opacity=0);
              opacity: 0;
              outline: none;
              display: block;
              cursor: pointer;
              -webkit-filter: alpha(opacity=0);
            }

            &:hover {
              background: $background_gradient_03 !important;
            }

          }

          .button-submit {
            background: $background_gradient_4;
            color: #fff !important;
            padding: 11px 0;
            border: 0px;
            border-radius: 5px;
            font-weight: 500;
            font-size: 17px;
            display: inline-block;
            text-decoration: none;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            margin-top: 0%;

            &:hover {
              background: $background_gradient_04 !important;
            }
          }

          .backhere {
            font-size: 14px;
            font-weight: 600;
            text-decoration: none;
          }
        }
      }
    }

    footer {
      padding: 7% 0 1%;

      p {
        font-size: 13px;
        font-style: italic;
        color: $color_4;
        font-weight: 600;
      }

      .br-ryt {
        border-right: 2px solid $color_4;
      }
    }
  }


  /*================================================
03 - MEDIA QUERY
==================================================*/

  @media (max-width: 576px) {
    .tms_main_row {
      padding-top: 5% !important;

      .ques-title {
        font-size: 19px !important;
      }

      .sub_title {
        font-size: 12px !important;
      }
    }
  }


  @media (max-width: 992px) {
    .b-left-radius {
      border-top-left-radius: 20px !important;
      border-bottom-left-radius: 0px !important;
      border-top-right-radius: 20px !important;
    }

    .b-right-radius {
      border-top-right-radius: 0px !important;
      border-bottom-left-radius: 20px !important;
      border-bottom-right-radius: 20px !important;
    }

    .br-ryt {
      border-right: none !important;
    }
  }
}