.modal_contnt1 {
    font-size: 22px;
    color: #f6334c;
    font-weight: 600;
}

.modal_img1 {
    margin-bottom: 20px;
    height: 80px;
}

.modal_bdy1 {
    padding-bottom: 30px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.modal_bdy1 .btn-yes {
    background: #257eba;
    padding: 10px 30px;
    text-decoration: none;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.modal_contnt_22 {
    font-size: 32px;
    font-weight: bolder;
}

.modal_bdy1 .form-check-input {
    visibility: hidden;
}

// @media screen and (min-width: 600px) {
//     .modal-300 {
//         width: 300px;
//     }
// }