/*
*******************************************
Template Name: ACA Benefits
Author: Anush
Date: 19-09-2023
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************
*/

// html {
//     scroll-behavior: smooth;
// }

// body {
//     padding: 0;
//     margin: 0;
//     box-sizing: border-box;
// }

@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
.loader_1{
    background: url(../img/white-bg-img.jpg) fixed !important;
    background-size: cover !important;
    z-index: 2;
    position: relative;
    .loader-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100%;
        background: url(../img/logo.png) no-repeat center 20px;
        background-size: 200px;
    
        .loader {
          img {
            width: 100px;
          }
        }
      }
}
.__ExpampleDemo{
    
   

    font-family: 'Titillium Web', sans-serif !important;
    min-height: 100vh;

    .showcase {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        padding: 0 60px;
        @media only screen and (max-width: 968px) {
            padding: 0 20px;
        }
        @media only screen and (max-width: 600px) {
            padding: 0 20px;
            justify-content: left;
            text-align: left;
        }
        @media only screen and (max-width: 480px) and (min-width: 430px){
            padding: 30px 20px;
            justify-content: start;
            align-items: start;
        }
        @media only screen and (max-width: 430px) and (min-width: 392px){
            padding: 100px 20px;
        }
        @media only screen and (max-width: 392px) {
            padding: 50px 20px;
        }

        .video-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            video {
                width: 100%;
                min-height: 100%;
                object-fit: cover;
            }
            &:after {
                content: '';
                z-index: 1;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.753);
                position: absolute;
            }
        }
        .content {
            z-index: 2;
            h1{
                font-size: 40px;
                // margin-bottom: 30px;
                line-height: 140%;
                @media only screen and (max-width: 728px) and (min-width: 510px)  {
                    font-size: 34px;
                }
                @media only screen and (max-width: 510px) and (min-width: 210px)  {
                    font-size: 25px;
                    line-height: normal;
                    text-align: center;
                }
            }
            h3{
                font-size: 22px;
                max-width: 80%;
                line-height: 140%;
                font-weight: 400;
                margin: 0 auto;
                padding: 5px;
                @media only screen and (max-width: 728px) and (min-width: 510px)  {
                    font-size: 26px;
                }
                @media only screen and (max-width: 510px) and (min-width: 210px)  {
                    font-size: 21px;
                    line-height: normal;
                    max-width: 100%;
                    font-size: 18px;
                    text-align: center;
                }
            }
            .btn {
                display: inline-block;
                padding: 13px 30px;
                background: #049e0c;
                color: #fff;
                width: 100%;
                font-size: 20px;
                border-radius: 5px;
                border: solid #049e0c 1px;
                // margin-top: 25px;
                font-weight: 400;
                @media only screen and (max-width: 980px) {
                    width: 80%;
                }
                @media only screen and (max-width: 760px) {
                    width: 70%;
                }
                @media only screen and (max-width: 600px) {
                    width: 100%;
                    padding: 10px 30px;
                }
            }
            .cls_btn{
                z-index: 2;
                .close_bbt{
                    display: inline-block;
                    border: none;
                    outline: none;
                    padding: 13px 30px;
                    background: #555555;
                    color: #fff;
                    border-radius: 5px;
                    font-size: 18px;
                    margin-top: 20px;
                    font-weight: 600;
                    @media only screen and (max-width: 600px) {
                        width: 100%;
                        padding: 10px 30px;
                    }
                }
            }
        }

    }

   

}
