
.MB_UFGN_1_KYC{
/*
*******************************************
Template Name: Tms Legal
Author: Abhilash GP
Date: 05-12-2022
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the  Tms Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - 
05 - MODAL
06 - MEDIA QUERY

============================================
============================================

*/
.tms-wrap2 {

/*================================================
01 - Sass Variables
==================================================*/

$background_color_1:#ffffff;
$background_color_2:#69a2d7;
$background_color_3:#166176;
$background_color_4:#c7dbde;
$background_color_5:#2aa406;


$color_1:#FFFFFF;
$color_2:#4f4f4f;
$color_3: #69a2d7;
$color_4: #166176;


$font_family_1:'Poppins', sans-serif;

$font_size_1:16px;
$font_size_2:14px;

 


.accordion-item{
    background: none !important;
}

.accordion-button{
    background-color: #2f9de8 !important;
    margin-bottom: 15px;
    color: $color_1 !important;
    border-radius: 6px !important;
    font-size: 20px;
    &:focus{
        box-shadow: none !important;
    }
    &::after {
        border: solid 5px #2f9de8;
        padding: 15px;
        background-position: center;
        border-radius: 100%;
        background-color: $background_color_1;
    }

}

/*================================================
04 - BUTTON
==================================================*/

.button-tab{
    background: $background_color_5;
    color: $color_1 !important;
    padding: 15px 0;
    border: 0px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    img{
        padding-right: 10px;
    
    }
    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;   
        display: block;
        cursor: pointer;
    }
    &:hover {
        background: $background_color_2 !important;
      }

      &.retakered-btn {
        background: red;
      }
   
}
.browsebut{
    background: $background_color_5 !important;
    cursor: pointer;
    &:hover{
        background: $background_color_2!important;
    }
}


.documents-uploads {
    .text-p1,.text-p2  {
        font-size: 18px;
        font-weight: 600;
    }
    .text-p2 {
        font-size: 14px;
        
    }
}
}
}
    
 