
.OB_Thankyou02{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: url(../img/bg-new.jpg) no-repeat right;
  background-size: cover;
min-height: 100vh;

.btn-info_part {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;

    .space {
        margin-top: 90px;
      img {
        width: 202px;
      }

      .tq-img {
        width: 100px;
        margin-bottom: 1rem;
      }

      .bounce-in {
        animation: bounce-in 2s ease;
        -webkit-animation: bounce-in 2s ease;
      }

      @keyframes bounce-in {
        0% {
          opacity: 0;
          transform: scale(.3);
        }

        50% {
          opacity: 1;
          transform: scale(1.05);
        }

        70% {
          transform: scale(.9);
        }

        100% {
          transform: scale(1);
        }
      }

      .htext {
        font-weight: normal;
        text-align: center;
        margin-top: 20px;
        color: #2f3996;
        font-size: 20px;
      }
      ._sub {
        font-weight: normal;
        text-align: center;
        margin-top: 20px;
        color: #2f3996;
        font-size: 20px;
      }

    }
  }

    /*================================================
LOADER ANIMATION SCSS WITH KEYFRAME
==================================================*/


.loader {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-anime {
    display: contents;

    img {
      width: 40%;
    }
  }
}

  .hr_tag{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 11px;
    hr{
        height: 2px;
        background: #121212;
        width: 200px;
        border: 0;
    }
  }


  button {
    border: none;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.95);
    cursor: pointer;
  }


  .button {
    background: white;
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    margin: 0 !important;
    font-weight: 600;
    position: relative;
    will-change: transform;
  }


  .btn-comm2.second {
    background: #63a311;
    border-bottom: 3px solid #3e6d02
}

.btn-comm2.second_1 {
    border-bottom: 7px solid #1172cd
}

.btn-comm2 {
    padding: 20px 60px;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #319cff 0, #319cff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45,35,66,0.4) 0 2px 4px,rgba(45,35,66,0.3) 0 7px 13px -3px,rgba(58,65,111,0.5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 22px;
    width: 100%;
    text-align: center
}

.btn-comm2 span {
    font-size: 28px;
    background: #00000040;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
    line-height: 17px;
    margin-left: 10px
}

.btn-comm2:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset,rgba(45,35,66,0.4) 0 2px 4px,rgba(45,35,66,0.3) 0 7px 13px -3px,#3c4fe0 0 -3px 0 inset
}

.btn-comm2:hover {
    box-shadow: rgba(45,35,66,0.4) 0 4px 8px,rgba(45,35,66,0.3) 0 7px 13px -3px,#3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px)
}

.btn-comm2:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px)
}

.btn-comm2.second {
    background: #63a311
}

.btn-comm2.second:focus {
    box-shadow: #446e0c 0 0 0 1.5px inset,rgba(45,35,66,0.4) 0 2px 4px,rgba(45,35,66,0.3) 0 7px 13px -3px,#446e0c 0 -3px 0 inset
}

.btn-comm2.second:hover {
    box-shadow: rgba(45,35,66,0.4) 0 4px 8px,rgba(45,35,66,0.3) 0 7px 13px -3px,#446e0c 0 -3px 0 inset;
    transform: translateY(-2px)
}

.btn-comm2.second:active {
    box-shadow: #446e0c 0 3px 7px inset;
    transform: translateY(2px)
}

  
.anim_green {
  -webkit-animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  box-shadow: 0 0 0 0 #4a7a0d
}

.anim_blue {
  -webkit-animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
  box-shadow: 0 0 0 0 #319cff
}

@keyframes pulse {
  100% {
      box-shadow: 0 0 0 45px rgba(232,76,61,0)
  }
}






/*================================================
05 - MEDIA QUERY
==================================================*/



@media only screen and (max-width: 750px) {
.button {
  margin: 0.8rem 0;
}

.space {
    margin-top: 33px !important;
}

}

@media (min-width: 768px) {
  .space {
    margin-top: 30px !important;
  }
}

@media only screen and (max-width: 680px) and (min-width: 480px) {
.space {
    .htext {
        font-size: 27px !important;
    }
}
}

@media (max-width: 480px) {
.container {
  max-width: 100%;
}

.space {
  img {
    width: 160px !important;
  }
  .tq-img {
    width: 100px !important;
    margin-bottom: 1rem;
  }
  .htext {
    font-size: 27px !important;
  }
}

.hr_tag{
  padding-bottom: 11px;
  hr{
      width: 40% !important;
  }
}

.loader {
  .loader-anime {
    img {
      width: 85%;
    }
  }
}

}
.disablebtn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
}