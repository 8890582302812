$color1 :#63a311;
$color2 :#319cff;
$color3 :white;
$color4 :#e73e49;
.ObFollowUpV2{
    .bg-main-pr{
    // background: url(../img/bg87899.jpg) fixed !important;
    background-size: cover  !important;
    float: left;
    width: 100%;
    height: 100vh;

}
.main {
    &::after {
      //  background: url(../img/bg-00.png) no-repeat fixed right;
        //background-size: cover;
        content: "";
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        background-position: 0 10px;
        z-index: -1;

    }
    h1 {
        font-weight: bold;
        font-size: 25px;
        &.h4 {
            font-size: 22px;
        }
        span {
        //    color: $background_color_2;
        }
       
    }
    select { 
        padding: 15px !important;
        border: 2px solid #e1e1e1;
    }
    h2{
        font-weight: bold;
        .small {
            color:$color1;
            font-size: 18px;
            font-weight: normal;
        }
    }
    p {
        font-size: 23px;
       
    }

    fieldset {
        background: #fff;
        position: relative;
        padding: 15px;
        box-sizing: border-box;
        border: solid #cdcdcd  1px;
        height: 140px;
        border-radius: 5px;
        p {
            font-size: 16px;
        }

        &.first {
            border-color: $color1;
            border-width: 3px;
        }
        
        legend {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        padding: 0 5px;
 
        }
    }

    .text2b {
        i {
            color: $color1;
        }
        font-size: 16px;
    }
    .text3b {
        font-style: italic;
        font-size: 16px ;
    }
    .slot-filed {
        background: #B7F4F8;
        font-size: 20px;
        font-weight: bold;
        padding: 15px;
        border-radius: 10px;
        color: #04858D;
        position: relative;
        cursor: pointer;
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
        .icon {
            height: 50px; 
            width: 50px; 
            background: #41CBD4;
            border-radius: 50%;
            display: inline-block;
            color: #fff;
            padding-top: 5px;
 
        }
        &.slot-email {
            background: #F8E9B7;
            font-size: 18px;
            font-weight: 500;
            color: #7A692F;
            .icon {
                background: #E9B76D;
            }
        }
    }

}

.clearfix {
    &::after {
        content: "";
        clear: both;
        display: table;
      }
}

.btn-comm2{
    padding: 20px 60px;
    align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, $color2 0, $color2 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 22px;
  width: 100%;  
  text-align: center;
    span {
        font-size: 28px;
        background: #00000040;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: inline-block;
        text-align: center;
        line-height: 17px;
        margin-left: 10px;
    }
    
    &:focus {
        box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
      }
      &:hover {
        box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
        transform: translateY(-2px);
      }

      &:active {
        box-shadow: #3c4fe0 0 3px 7px inset;
        transform: translateY(2px);
      }
    
    &.second {
        background: $color1;

        &:focus {
            box-shadow: #446e0c 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c 0 -3px 0 inset;
          }

        &:hover {
            box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c  0 -3px 0 inset;
            transform: translateY(-2px);
          }
    

        &:active {
            box-shadow: #446e0c 0 3px 7px inset;
            transform: translateY(2px);
          }
          
        
    }
}

.btn-prev{
    background: transparent;
    outline: 0;
    border: 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    color: #767171;
}


.loader-wrap2 {
    display: flex;
    align-items: center;
    justify-content: center; 
    height: 100vh;
    width: 100%;
    background: url(../img/logo.png) no-repeat center 20px; 
    background-size: 200px;
    overflow: hidden;
    
    .loader {
        img {
            width: 100px;
        }
    }
}

.dis_none {
    display: none;
}

.mt-val {
    margin-top: 8rem;
}

.anim_green{
    animation: pulse 1.25s cubic-bezier(.66,0,0,1) infinite;
    box-shadow: 0 0 0 0 #0d767a7d  ;
  }

.skip-active {
    background: #67ed87;
    color: #157b2e;
    border-color: #2acf51f7;
}

@media only screen and (max-width: 1200px) and (min-width: 960px)  {

    .main {
        p {
           font-size: 16px;
        }
    }
}
@media (min-width: 990px){ 
    
    .brand-logo {
        position: absolute;
        top: 25px;
        width: 100%;
    }
    .item-cent-flx {
        // height: 100vh;
        // display: grid;
        // grid-template-columns: 1fr;
        // justify-content: center;
        // align-items: center;
      
    }
    
}
@media (max-width: 960px){
    .mt-val {
        margin-top: 4rem;
    }
  }

@media (max-width: 768px){

    .mt-val {
        margin-top: 3rem;
    }
    
    .main {
        .slot-filed {
           
            font-size: 16px  ;
            &.slot-email {
                font-size: 13px;
            }
            .ps-3{
                text-align: left;
            }
        }
        p {
            font-size: 14px;
        }
        fieldset {
            height: auto;
           
            .bud-logo {
                width: 115px;
                margin-bottom: 15px;
            }
        }
        padding-bottom: 60px;
        h1 {
            font-size: 24px;
        }
        
        
    }
.btn-comm2{
    font-size: 18px;
    padding: 15px 30px;
}

 

}

@media (max-width: 490px){
    .main {
        h1 {
            font-size: 18px;
        }
        p,.text3b,.text2b {
            font-size: 14px;
        }
         .brand-logo {
            img {
                height: 50px;
            }
         }
    }



}


.brand-logotp {
   position: absolute;
   top: 8px;
   width: 100%;
   z-index: 10;
}
}