/*
*******************************************
Template Name: TMS Legal LTD
Author:Ramees R J
Date: 02-11-2022
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the TMS Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - BODY 
03 - howitworks 
04 - FOOTER 
05 - ANIMATION
06 - MODAL 
07 - MEDIA QUERY
 
============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/
$background_color_1: #ffffff;
$background_color_2: #69a2d7;
$background_color_3: #3ebbe9;
$background_color_4: #333333;
$background_color_5: #257eba;
$background_color_6: #e2091d;
$background_color_7: #2f9de8;
$background_color_8: #f9f9fa;
$background_color_9: #ff0000;
$background_color_10: #ffff00;
$background_color_11: #2f9de8;

$color_1: #ffffff;
$color_2: #777777;
$color_3: #257eba;
$color_4: #beb8b8;
$color_5: #1483ce;
$color_6: #0070ba;
$color_7: #2f9de8;
$color_8: #212529;



.modalcontent {
  border: solid 5px $color_7  !important;
  border-bottom: solid 25px $color_7  !important;
  border-radius: 0px !important;
}

.MB_UBRF_2 {
  font-family: 'Titillium Web', sans-serif !important;
  background: $background_color_1  !important;

  html {
    scroll-behavior: smooth;
  }

  a {
    cursor: pointer;
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }

  /*================================================
02 - BODY 
==================================================*/
  body {
    font-family: 'Titillium Web', sans-serif !important;
    background: $background_color_1  !important;
  }


  .sun-content {
    background-color: $background_color_6  !important;

    p {
      color: $color_1;
      text-align: center;
      background-color: $background_color_6;
      line-height: 35px;
      margin: auto;

      img {
        width: 10%;
      }
    }
  }

  header {
    background: $background_color_5;
    text-align: center;
    padding: 20px 0 75px;

    h1 {
      color: $color_1;
      font-size: 34px;
      font-weight: 600;
      padding-top: 20px;
      font-family: 'Titillium Web', sans-serif;
    }

    h2 {
      color: $color_1;
      font-size: 23px;
      padding: 0;
      font-weight: 400;
    }

    // h3{
    //     color: $color_1;
    //     font-size: 20px;
    //     padding: 0;
    //     font-weight: 400;
    // }
    h3 {
      strong {
        color: $color_5;
        font-size: 24px;
        padding: 0;
        background-color: $background_color_10;
      }
    }

    img {
      max-width: 35%;
    }

    .spacemob {
      padding-bottom: 80px;
    }

    ul {
      display: inline-block;
      padding-left: 0;

      li {
        color: $color_1;
        font-size: 18px;
        text-align: left;
      }
    }

    #slide_header {
      h1 {
        color: $color_1;
        font-size: 26px;
        font-weight: 600;
      }

      h2 {
        color: $color_1;
        font-size: 18px;
        padding: 0px 0px 15px;
        font-weight: 400;
      }

      ul {
        padding-bottom: 30px;
      }
    }

  }

  .form-part {

    form {
      .form-group {
        margin-bottom: 1rem;
      }

      .option_but {
        background: $background_color_7;
        display: inline-block;
        border: 0px solid $color_6;
        border-bottom: 3px solid $color_3;
        font-size: 20px;
        color: $color_1;
        font-weight: 600;
        border-radius: 1px !important;
        box-sizing: border-box;
        width: 100%;
        cursor: pointer;
        margin: 0 0 10px !important;
        position: relative;
        overflow: hidden;
        text-align: center;
        padding: 16px 15px;

        span {
          display: inline-block;
          vertical-align: middle;

        }

      }

      .activeclass {
        background: #166497;
      }

      .mb-0 {
        margin-bottom: 0 !important;
      }

      .option_but .checked {
        border-color: $color_6;
        background-color: $background_color_3;
        z-index: 100;
        color: $color_1;
      }

      .option_notbut {
        text-align: center;
        width: 100%;
        font-size: 18px;
        text-decoration: underline;
        font-weight: 600;
        color: $color_2;
      }


      label {
        input[type="radio"] {
          display: none !important;
        }
      }

      h3 {
        margin: 0 !important;
        padding: 0px 0 15px;
        font-size: 20px !important;
        color: $color_2;
        text-align: center;

        span {
          //color: $color_3;
          color: #f2b932;
        }
      }

      h4 {
        margin: 0 !important;
        padding: 0px 0 10px;
        font-size: 18px !important;
        color: $color_2;
        text-align: center;
        font-weight: 600;
      }

      .top_minus {
        position: relative;
        margin-top: -55px;
        padding: 0 15px;
      }

      .top_minus_1 {
        position: relative;
        margin-top: -63px;
        padding: 0 15px;

        a {
          color: $color_2;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
        }
      }

      .gb {
        position: absolute;
        background: $background_color_5  !important;
        color: $color_1  !important;
        padding: 6px 10px;
        border-radius: 10px 5px 5px 10px;
        height: 60px !important;
        font-size: 15px;
        text-align: center;
      }

      .car-reg {
        height: 60px !important;
        border: 2px solid $color_2  !important;
        border-radius: 15px;
        font-size: 18px !important;
        font-weight: 600;
        text-align: center;
        width: 100% !important;
      }

      .regNextBtn {
        color: $color_1;
        border-radius: 1px !important;
        display: inline-block;
        background: $background_color_11;
        border-bottom: 3px solid $color_3;
        border: 0px solid $color_6;
        font-size: 24px;
        font-weight: 600;
        line-height: 45px;
        align-items: center;
        min-height: 65px;
        padding: 9px 23px;
        transition: all .3s;
        text-decoration: none;
        margin: 20px 0 !important;
        width: 100%;
        flex: 0 0 140px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        img {
          max-width: 8%;
          font-size: 16px;
        }

        i {
          font-size: 16px;
        }

      }

      .option_bank {
        input[type=checkbox] {
          display: none;

          &:checked+label {
            background-color: $color_5;
            border: 1px $color_5 solid;
            cursor: pointer;
            display: inline-block;
          }
        }

        input[type=checkbox]+label {
          background: $background_color_7 center;

          border: 1px solid $color_5;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          height: 60px;
          position: relative;
          cursor: pointer;
          outline: none;
          margin: 0;
          padding: 0 0 0 0px;
          line-height: 60px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          width: 100%;
          clear: none;
          color: $color_1;
          font-weight: normal;
          display: block;
          font-size: 18px;
          text-align: center;
          margin-bottom: 5px;
        }

        label {
          float: left;
          max-width: 100%;
          margin-bottom: 5px;
          font-weight: 700;

        }


      }

      .form-control {
        width: 100%;
        font-size: 16px;
        font-weight: 300;
        // background: none !important;
        border: 2px solid #ddd !important;
        height: 50px !important;
        border-radius: 1px;
      }

      fieldset {
        border: 1px solid $background_color_7;
        padding: 10px;
        border-radius: 0px;
        margin-bottom: 15px;
      }

      legend {
        padding: 0 10px;
        display: block;
        float: none;
        width: auto !important;
        max-width: 100%;
        margin-bottom: 8px;
        font-size: 24px;
        line-height: inherit;
        color: inherit;
        white-space: normal;
      }

      .btn-next {
        background: $background_color_7;
        color: $color_1;
        font-weight: 400;
        font-size: 26px;
        padding: 15px 10px;
        width: 100%;
        cursor: pointer;
        border: 0px;
        border-radius: 0px !important;
        float: right;

        a {
          color: $color_1  !important;
        }

        &:hover {
          color: $color_1  !important;
          background: $background_color_3;
        }

        &:active {
          background: $background_color_3  !important;
        }
      }

      .input-group {

        .form-control {
          width: 1%;
        }

      }

      p {
        font-size: 20px;
      }

      .slide-logo {
        padding-bottom: 20px;

        img {
          height: 50px;
        }
      }

      .slide-pad {
        padding-top: 20px;
      }

      .slide-padng {
        padding: 40px;

        h5 {
          color: #ff0000;
          margin-bottom: 30px;
        }
      }

      .signature-div {
        max-width: 100%;
        margin-bottom: 10px;
        width: 100%;
        min-height: 150px;
        border: 2px dotted #2f9de8;
        background: #fff;
        // box-shadow: -1px -1px 5px 0px rgba(0,0,0,0.75);
        // -webkit-box-shadow: -1px -1px 5px 0px rgba(0,0,0,0.75);
        // -moz-box-shadow: -1px -1px 5px 0px rgba(0,0,0,0.75);
      }

      .button-clear {
        background-color: transparent;
        border-radius: 10px;
        padding: 7px 0;
        transition: all .3s;
        border: 0;
        width: auto;
        cursor: pointer;
        margin-bottom: 10px;
        color: #000 !important;
        font-size: 14px;
        font-weight: bold;
        position: relative;
        text-align: left;

      }

      .form-check {
        margin: 15px 0;
        font-size: 16px;
        position: relative;
        display: block;
        padding-left: 1.25rem;

        .form-check-label {
          font-size: 14px;
        }
      }

    }

  }

  .input-group-text {
    display: flex;
    padding: 10px 12px !important;
    background-color: #e9ecef !important;
    border: 1px solid #ced4da !important;
    border-radius: 5px;
  }

  .modal {
    top: 15% !important;
  }

  .formmodal {
    .option_but {
      background: #2f9de8;
      display: inline-block;
      border: 0px solid #0070ba;
      border-bottom: 3px solid $color_3;
      font-size: 20px;
      color: $color_1;
      font-weight: 600;
      border-radius: 1px !important;
      box-sizing: border-box;
      width: 100%;
      cursor: pointer;
      margin: 0 0 20px !important;
      position: relative;
      overflow: hidden;
      text-align: center;
      padding: 16px 15px;

      span {
        display: inline-block;
        vertical-align: middle;

      }

    }

    label {
      input[type="radio"] {
        display: none !important;
      }
    }

    h3 {
      margin: 0 !important;
      padding: 0px 0 20px;
      font-size: 20px !important;
      color: $color_2;
      text-align: center;

      span {
        color: $color_3;
      }
    }

  }



  .nopad {
    padding: 0px !important;
  }

  .error_msg {
    color: $color_1;
    width: 100%;
    float: left;
    background: #ff0000;
    font-size: 13px;
    padding: 0px 5px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    margin-bottom: 0px;
  }

  .validate {
    position: relative;
    float: right;
    margin-top: -38px;
    right: 2px;
  }

  .success {
    background: url("../img/v-tick.png") no-repeat center center;
    width: 23px;
    height: 23px;
  }

  .error {
    background: url("../img/v-close.png") no-repeat center center;
    width: 23px;
    height: 23px;
  }

  .rspace {
    right: 105px !important;
  }

  .alert-text {
    color: #ff0000 !important;
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    font-weight: normal;
    text-align: justify;
    margin-bottom: 12px !important;
  }

  @media screen and (min-width: 1px) {
    input.radio {
      position: absolute;
      left: -9999px;
    }
  }

  /*================================================
03 - missold
==================================================*/

  .missold {
    background-color: $background_color_8;
    padding: 40px 0 30px;
    color: $color_8;

    .accordion-body {
      color: $color_8;
    }




  }

  /*================================================
03 - howitworks
==================================================*/

  .howitworks {
    background: $background_color_1;
    color: $color_2;
    padding: 15px 0;

    h2 {
      font-size: 36px;
    }

    img {
      margin: 40px 0;
    }

    h4 {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }


  /*================================================
04 - footer
==================================================*/

  footer {
    padding: 25px 0;
    background: $background_color_4;

    .brdr-left {
      border-left: solid 1px $color_4;
    }

    .m-0 {
      margin: 0px;
    }

    ul {
      padding: 0px;
      text-align: left;
      margin-top: 15px;

      li {
        list-style: none;
        border-right: 1px solid $color_4;
        padding: 0px 10px;
        display: inline;
        color: $color_4;

        &:first-child {
          padding-left: 0px;
        }

        &:last-child {
          border: 0px;
        }

        a {
          text-decoration: none;
        }
      }
    }

    p {
      color: $color_4;
      font-size: 14px;
      text-align: left;
    }

    img {
      height: 110px;
      margin-bottom: 22px;
    }
  }

  /*================================================
05 - animation 
==================================================*/

  .anim_ylw {
    -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    box-shadow: 0 0 0 0 #f1ee05;
  }

  @-webkit-keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
  }

  @-moz-keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
  }

  @-ms-keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
  }

  @keyframes pulse {
    to {
      box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
    }
  }

  @-webkit-keyframes pulse {
    to {
      box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
  }

  @-moz-keyframes pulse {
    to {
      box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
  }

  @-ms-keyframes pulse {
    to {
      box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    }
  }

  @-moz-keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /* IE */
  @-ms-keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /* Opera and prob css3 final iteration */
  @keyframes blink {
    0% {
      opacity: 1;
      height: 10px;
    }

    50% {
      opacity: 1;
      height: 14px;
    }

    100% {
      opacity: 1;
      height: 18px;
    }
  }

  /*================================================
06 - modal
==================================================*/

  .modal-content {
    border: solid 5px $color_7  !important;
    border-bottom: solid 25px $color_7  !important;
    border-radius: 0px !important;
  }

  .slidehead h4 {
    background: #ffffff;
    color: #000000 !important;
    padding: 20px 0px 10px;
    margin: 0 !important;
    font-size: 20px !important;
    font-weight: 700;
  }



  /*================================================
07 - media query
==================================================*/

  @media (min-width: 992px) and (max-width: 1199.98px) {
    header {
      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: 28px;
        line-height: 36px;
      }
    }

  }


  @media screen and (max-width: 991.98px) and (min-width: 768px) {

    .brdr-left {
      border: 0px;
    }



    header {
      h1 {
        font-size: 34px;
      }

      h2 {
        font-size: 26px;
        line-height: 35px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    header {
      h1 {
        font-size: 28px;
      }

    }

    .mobspacer {
      height: 270px;
    }

    .howitworks {
      padding: 30px 0;

      h2 {
        font-size: 24px;
      }

      img {
        margin: 30px 0;
        height: 70px;
      }

      h4 {
        text-align: center !important;
        margin: 10px 0;
        font-weight: 600;
        color: #000000;
        font-size: 20px;
      }

      p {
        font-weight: 400;
        color: #000000;
        font-size: 14px;
      }

      .bordr {
        border-bottom: dashed 2px #72286f;
        border-top: dashed 2px #72286f;
      }
    }

    // .missold {
    //     background-color: $background_color_2;
    //     padding: 40px 0 30px;
    //     color: $color_1;

    //     h2 {
    //         font-size: 20px;
    //     }

    //     p {
    //         font-size: 15px;
    //     }

    //     .btn-link {
    //         margin-bottom: 0px;
    //         color: #525252;
    //     }

    //     .btn-link:hover {
    //         color: #0056b3;
    //         text-decoration: underline;
    //         background-color: transparent;
    //         border-color: transparent;
    //     }
    //     .card-body {
    //         color: #000000;
    //     }
    // }

    footer {
      height: auto;
      padding: 25px 0;
      background: #424242;
      text-align: left;

      p {
        margin: 7px 0 20px;
        padding: 0;
        font-size: 13px;
        color: $color_1;
        font-weight: 300;
        text-align: center;
        font-family: "Open Sans", sans-serif;

        a {
          color: #b1b1b1;
        }
      }

      ul {
        text-align: left;
        margin: 0;
        padding: 0px 0;

        li {
          display: block;
          border-top: 1px dotted #94a2a6;
          padding: 6px 0;
          font-size: 14px;
          border-right: 0px;

          a {
            color: $color_1  !important;
            font-size: 13px;
          }
        }
      }
    }

    .brdr-left {
      border: 0px !important;
    }

    .form-part {
      form {
        .pfrp {
          font-size: 15px;
        }

        h4 {
          padding-top: 0px;
        }

        .signature-div {
          max-width: 100%;
        }
      }
    }
  }

  // @media (min-width: 576px) {
  //   .container {
  //     max-width: 100%;
  //   }
  // }

  @media (max-width: 576px) {}

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  /*PROGRESS BAR ROUNDED*/


  $borderWidth: 7px;
  $animationTime: .5s;
  $border-color-default: #eaeaea;
  $border-color-fill: #2f9de8;
  $size: 120px;
  $howManySteps: 100; //this needs to be even. 

  .top60 {
    margin-top: 60px;
  }

  .progress {
    width: $size;
    height: $size  !important;
    line-height: $size;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;
    background: none !important;
    margin-bottom: 30px;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: $borderWidth solid $border-color-default;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $background_color_1;
    }

    >span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    .perce {
      font-size: 28px !important;
      font-weight: 100;
    }

    .progress-left {
      left: 0;
    }

    .progress-bar {
      width: 100%;
      height: 100%;
      background: none;
      border-width: $borderWidth;
      border-style: solid;
      position: absolute;
      top: 0;
      border-color: $border-color-fill;
    }

    .progress-left .progress-bar {
      left: 100%;
      border-top-right-radius: ($size/2);
      ;
      border-bottom-right-radius: ($size/2);
      ;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
      //animation: loading-2 1.5s linear forwards 1.8s;
    }

    .progress-right {
      right: 0;

      .progress-bar {
        left: -100%;
        border-top-left-radius: ($size/2);
        ;
        border-bottom-left-radius: ($size/2);
        ;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
        //animation: loading-1 1.8s linear forwards;
      }
    }

    .progress-value {
      display: flex;
      border-radius: 50%;
      font-size: 1rem;
      text-align: center;
      line-height: 20px;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      font-weight: 300;
      z-index: 9;

      div {
        //margin-top: 10px;
      }

      span {
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }

  /* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
  @for $i from 1 through $howManySteps {
    $stepName: ($i*(100 / $howManySteps));

    //animation only the left side if below 50%
    @if $i <=($howManySteps/2) {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
          animation: loading-#{$i} $animationTime linear forwards;
        }

        .progress-left .progress-bar {
          animation: 0;
        }
      }
    }

    //animation only the right side if above 50%
    @if $i >($howManySteps/2) {
      .progress[data-percentage="#{$stepName}"] {
        .progress-right .progress-bar {
          animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
        }

        .progress-left .progress-bar {
          animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
        }
      }
    }
  }

  //animation
  @for $i from 1 through ($howManySteps/2) {
    $degrees: (180/($howManySteps/2));
    $degrees: ($degrees*$i);

    @keyframes loading-#{$i} {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate($degrees);
        transform: rotate(#{$degrees}deg);
      }
    }
  }


  @media (max-width: 768px) {

    .sun-content {
      padding: 5px 0;

      p {
        font-size: 13px;
        line-height: 10px;


        img {
          width: auto;
          max-height: 27px;
        }
      }
    }

  }

  @media screen and (max-width: 767px) {
    header {
      h1 {
        font-size: 24px;
      }

      h2 {
        font-size: 14px;
      }

      h3 {
        strong {
          font-size: 18px;
        }
      }
    }
  }
}