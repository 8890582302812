.TMSV_SQL_V1{
  /*
  *******************************************
  Template Name: The Financial Guru
  Author: ABC
  Date: 09-11-2022
  Template By: Vandalay Designs
  Copyright 2020-2022 Vandalay Business Solution

  * This file contains the styling for laonclaims.co.uk, this
  is the file you need to edit to change the look of the
  site.

  This files contents are outlined below >>>>

  *** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
  *******************************************

  ============================================
  ==== T A B L E   O F   C O N T E N T S =====
  ============================================
  01 - Sass Variables
  02 - Sass Mixins
  03 - RESET
  04 - BODY 
  05 - HEADER SECTION
  06 - MAIN SECTION
  07 - SIDE SECTION
  08 - FOOTER 
  09 - MEDIA QUERY

  ============================================
  ============================================
  */

  /*================================================
  01 - Sass Variables
  ==================================================*/

  $color_1: #bfb533;
  $color_2: #048d2d;
  $color_3: #000;
  $color_4: #fff;
  $color_5: #11b6b5;
  $color_6: #333;

  $color_7: #beb8b8;
  $color_8: #303291;
  $color_9: #0a0641;
  $color_10: #e0eec7;
  $color_11: #a3c26e;
  $color_12: #fff;
  $color_13: #6c757d;

  $popTransp: rgba(0, 0, 0, 0.425);
  // ======font styles===============

  $font_family_1: "Titillium Web", sans-serif;

  $font_size_1: 14px;
  $font_size_2: 22px;
  $font_size_3: 28px;
  $font_size_4: 26px;
  $font_size_5: 18px;
  $font_size_6: 15px;
  $font_size_7: 17px;
  $font_size_8: 20px;

  $font-bold: bold;
  $font-w600: 600;
  $font-w700: 700;
  $font-w900: 900;

  $shadowLight: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  $shadow01: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

  // ======border===========
  .border {
    border: 1px solid $color_3;
  }
  /*================================================
  02 - Sass Mixins
  ==================================================*/

  @mixin bnt-style {
    background: $color_2;
    color: $color_12;
    padding: 1.5rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    font-weight: $font-w600;
    display: inline-block;
    text-decoration: none;
    transition: ease-in 0.4s;
    outline: navajowhite;
    border: 0;

    &:hover,
    &:focus {
      color: $color_12;
      transition: ease-in 0.4s;
      transform: translateY(-5px);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      background: $color_2;
    }
  }

  @mixin comTitle($textSize) {
    font-size: ($textSize);
    font-family: $font_family_1;
  }

  /*================================================
    End Sass Mixins
  ==================================================*/

  /*================================================
  03 - RESET
  ==================================================*/
  * {
    box-sizing: border-box;
    /*padding: 0;
    margin: 0;*/
    outline: 0;
    text-decoration: none;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    line-height: 20px;
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
    }
  }

  .d_none {
    display: none !important;
  }

  /*================================================
  04 - BODY STYLES
  ==================================================*/

  
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 100%;

  font-family: $font_family_1 !important;
  font-size: 1rem;
  line-height: 1rem;
  

  /*================================================
  END - BODY STYLES
  ==================================================*/

  .under_line {
    text-decoration: underline;
  }
  .com_bnt {
    @include bnt-style;
  }
  .comm_title {
    @include comTitle(2rem);
    color: $color_8;
    font-weight: $font-w600;
    margin-bottom: 1rem;
  }

  .btn-lgr {
    max-width: 600px;
    padding: 1.5rem 3rem;
    font-size: 1.8rem;
  }
  

  /*================================================
  05 - HEADER SECTION
  ==================================================*/

  .top_fix_stcik {
    background: $color_2;

    color: $color_4;
    line-height: 18px;

    p {
      font-size: 13px;
      margin-bottom: 0px;
    }
  }

  .section_main {
    background: $color_1;
    color: $color_12;
    .text-adver {
      font-size: 20px;
    }
    h1 {
      font-weight: bold;
      font-size: 43px;
      max-width: 1000px;
      margin: 20px auto 15px auto;
    }
    .com_bnt {
      font-size: 30px;
      animation: anim-changeColor infinite 3s;
    }
  }

  .sec_middle {
    .img-mid {
      height: 560px;
    }
    .com_bnt {
      margin-top: 30px;
      border-radius: 0;
      border: 1px solid $color_10;

      img {
        animation: anim-arrow infinite 1.2s;
        position: relative;
        left: 0;
        transition: ease-in-out;
      }
    }
  }
  @-webkit-keyframes anim-changeColor {
    0% {
      background-color: $color_2;
    }
    50.0% {
      background-color: #15a999;
    }
    100.0% {
      background-color: $color_5;
    }
  }

  @keyframes anim-changeColor {
    0% {
      background-color: $color_2;
    }
    50.0% {
      background-color: #15a999;
    }
    100.0% {
      background-color: $color_5;
    }
  }

  @-webkit-keyframes anim-arrow {
    0% {
      left: 0;
      opacity: 1;
    }
    50.0% {
      left: 5;
      opacity: 0.6;
    }
    100.0% {
      left: 10px;
      opacity: 0;
    }
  }

  @keyframes anim-arrow {
    0% {
      left: 0;
      opacity: 1;
    }
    50.0% {
      left: 5;
      opacity: 0.6;
    }
    100.0% {
      left: 10px;
      opacity: 0;
    }
  }

  .form_wrap {
    padding: 30px;
    box-shadow: $shadowLight;

    .plt_space {
      margin-top: -28px;
    }

    .otp_box {
      .com_bnt {
        width: 200px;
      }
    }
    .form_wrap {
      box-shadow: none;
      max-width: 700px;

      &.resend_area {
        .title_sub {
          color: $color_3;
        }

        .form-control {
          height: 100%;
        }
        .com_bnt {
          width: 100%;
          background: $color_2;
        }
      }
    }

    legend {
      float: none;
      width: auto;
      padding: 5px;
      font-weight: 600;
      color: $color_8;
      font-size: 17px;
    }
    .title {
      font-weight: 600;
      font-size: 18px;
    }
    .drop_select {
      width: 350px;
      padding: 5px;
      height: 100%;
      border-color: #c3c3c3;
    }

    .box_verifi {
      display: flex;
      gap: 10px;
      align-items: center;
      input {
        padding: 10px;
        width: 60px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: $color_8;
        border-radius: 5px;
        border: 1px solid #a1a1a1;
      }
    }
  }

  footer {
    background: $color_6;
    color: $color_4;
    padding: 20px 0;
    border-top: 1px solid $color_10;
    /*padding-left: 80px;*/
    p {
      color: $color_7;
    }

    .ft_menu {
      li {
        display: inline-block;
        border-right: 1px solid $color_7;
        padding-left: 0;
        padding-right: 0;
        &:last-child {
          border-right: 0;
        }
        &:first-child {
          a {
            padding-left: 0;
          }
        }

        a {
          color: $color_4;
          display: block;
          padding: 0px 8px;
        }
      }
    }
  }
  .model-text {
    a {
      text-decoration: underline;
    }
    p {
      font-size: 16px;
    }
    strong {
      font-size: 18px;
    }
    ul {
      margin-left: 35px;
      margin-bottom: 5px;
      strong {
        font-size: 16px;
        margin: 6px 0;
      }

      li {
        list-style-type: disc;
        padding: 5px;
        line-height: 20px;
      }
    }
  }

  //////////////////////////////
  .efct_filed {
    animation: blinkBG 1.5s infinite;
  }

  @keyframes blinkBG {
    0% {
      box-shadow: 0 0 0 0 rgb(255, 226, 63);
    }

    70% {
      box-shadow: 0 0 0 30px rgba(255, 177, 66, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
  }

  /*================================================
  09 - MEDIA QUERY
  ==================================================*/

  $break-large: 1367px;
  $break-ipad-pro: 1200px;
  $break-min-ipad: 768px;
  $break-max-ipad: 991px;
  $break-min-tab: 577px;
  $break-max-tab: 768px;
  $break-mob: 576px;
  $break-smlmob: 391px;
  $break-ex_smlmob: 322px;

  // maxwidth  991px
  @media screen and (max-width: $break-max-ipad) {
    .sec_middle {
      .com_bnt {
        margin-bottom: 30px;
        padding: 15px;
        font-size: 25px;
      }
    }
    footer {
      .ft_menu {
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
  }


  @media screen and (max-width: $break-max-tab) {

    .section_main {
      h1 {
        font-size: 30px;
      }
    }
    .sec_middle {
      padding-bottom: 30px;
      .com_bnt {
        margin-bottom: 30px;
        padding: 15px;
        font-size: 20px;
      }
    }
    footer {
      .ft_menu {
        li {
          display: block;
          border-right: 0;
          a {
            padding: 3px 0;
          }
        }
      }
    }

    .sec_middle {
      .img-mid {
        height: auto;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $break-mob) {
    .sec_middle{
      .com_bnt {
        font-size: 18px;
      }
    }
  }

  //////////////////////////////////////////////////////
  @media screen and (max-width: $break-smlmob) {
    footer {
      padding: 30px 0;
    }
  }


  /*================================================
  END - MEDIA QUERY
  ==================================================*/
}