/*
*******************************************
Template Name: Allegiant
Author: Arun Ravi
Date:18-07-2022
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Allegiant, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************
*/

.FollowupNDY {

	$background_color_1: #ffffff;
	$background_color_2: #2f3996;
	$background_color_3: #3ebbe9;

	$color_1: #ffffff;
	$color_2: #2f3996;
	$color_3: #257eba;
	$color_6: #0070ba;


	html {
		scroll-behavior: smooth;
	}

	a {
		cursor: pointer;
	}

	.error-msg {
		background: #fb0404;
		color: #fff;
		font-size: 13px;
		width: 100%;
		float: left;
		text-align: center;
		margin-top: 5px;
		border-radius: 3px;
	}

	font-family: 'Titillium Web',
	sans-serif !important;
	/*background:  $background_color_1 !important;*/

	.loading_section {
		padding: 0px;
		margin: 0px;

		&::before {
			z-index: -1;
			opacity: 0.3;
			content: "";
			position: fixed;
			height: 100%;
			width: 100%;
			background: #ffffff !important;
			background: -moz-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
			background: -webkit-gradient(left top, left bottom, color-stop(0%, #67c4b1), color-stop(24%, #fff), color-stop(49%, #fff), color-stop(69%, #fff), color-stop(100%, #5cc0ad)) !important;
			background: -webkit-linear-gradient(top, #69a2d7 0%, #fff 24%, #fff 49%, #fff 69%, #69a2d7 100%) !important;
			background: -o-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
			background: -ms-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
			background: linear-gradient(to bottom, #69a2d7 0%, #fff 24%, #fff 49%, #fff 69%, #69a2d7 100%) !important;
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#67c4b1', endColorstr='#5cc0ad', GradientType=0);
		}

		.questionspart {
			&::after {
				z-index: -1;
				content: "";
				position: fixed;
				height: 173px;
				width: 100%;
				background-size: 17%;
				top: 2%;
				background-image: url(/assets/img/logo_1.png) !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				opacity: 0.28;
			}

			.space {
				margin-top: 20%;

				img {
					width: 100px;
					margin-top: 20px;
				}

				.htext {
					font-weight: normal;
					text-align: center;
					margin-top: 10px;
					color: #2f3996;
					font-size: 18px;
				}
			}
		}
	}

	//height: auto;
	//background-position: center;
	//padding: 0px 0px;
	//margin-bottom: 20px;

	ul {
		list-style: none !important;
		margin-bottom: 15px;
		margin-left: 0px;
		padding-left: 0px;
	}

	footer{
		padding: 25px 0;
    	background: #333333;
	}

	ul.options {
		list-style: none !important;

		li {
			cursor: pointer;
			margin: 0 0 1% 0;
			width: 100%;
			float: left;

			&:nth-child(2n) {
				float: right;
			}
		}

		display: block;
		padding: 0px 0 0 0;
	}


	.custom-checkbox {
		display: none;

		&+label {
			background: #fff url(../img/unchecked.png) no-repeat 7px center;
			border: 2px solid #78a7ec;
			box-sizing: border-box;
			height: 45px;
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 0;
			padding: 0 0 0 35px;
			line-height: 40px;
			border-radius: 4px;
			width: 100%;
			clear: none;
			color: #2f3996;
			font-weight: normal;
			display: block;
			font-size: 14px;
			// overflow: hidden;
			// -webkit-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
			//-moz-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
			//box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
		}

		&:checked {
			&+label {
				background: #78a7ec url(../img/checked.png) no-repeat 7px center;
				color: $color_1;
				border: 1px solid #ddd;
			}
		}
	}

	.disabledcheckbox {
		background: #fff url(../img/unchecked.png) no-repeat 7px center !important;
		color: #aeb2d9 !important;
		border: 2px solid #cbd5e4 !important;
	}


	&::before {
		z-index: -1;
		opacity: 0.3;
		content: "";
		position: fixed;
		height: 100%;
		width: 100%;
		background: #ffffff !important;
		background: -moz-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
		background: -webkit-gradient(left top, left bottom, color-stop(0%, #67c4b1), color-stop(24%, #fff), color-stop(49%, #fff), color-stop(69%, #fff), color-stop(100%, #5cc0ad)) !important;
		background: -webkit-linear-gradient(top, #69a2d7 0%, #fff 24%, #fff 49%, #fff 69%, #69a2d7 100%) !important;
		background: -o-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
		background: -ms-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
		background: linear-gradient(to bottom, #69a2d7 0%, #fff 24%, #fff 49%, #fff 69%, #69a2d7 100%) !important;
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#67c4b1', endColorstr='#5cc0ad', GradientType=0);
	}

	&::after {
		z-index: -1;
		content: "";
		position: fixed;
		height: 173px;
		width: 100%;
		background-size: 17%;
		top: 2%;
		background-image: url(/assets/img/logo_1.png) !important;
		background-position: center !important;
		background-repeat: no-repeat !important;
		opacity: 0.15;
	}

	.gb {
		position: absolute;
		background: #69a2d7 !important;
		height: 60px !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: $color_1 !important;
		padding: 6px 10px;
		border-radius: 5px 0px 0px 5px;
		font-size: 16px;
		text-align: center;
		font-weight: 600;

		p {
			margin-bottom: 0;
		}
	}

	.gb2 {
		height: 50px !important;
	}

	.car-reg {
		height: 60px !important;
		border: 2px solid $color_2 !important;
		font-size: 18px !important;
		font-weight: 600;
		text-align: center;
		border-radius: 6px;
		width: 100% !important;
	}

	.amount-text {
		padding-left: 45px;
		width: 100%;
	}

	.radio-box {
		margin: 30px 0;
		display: inline-block;
		width: 100%;

		h3 {
			line-height: 28px;
			margin: 0 !important;
			padding: 0px 0 20px;
			font-size: 20px !important;
			text-align: center;
			color: #69a2d7;
			;

			span {
				color: $color_3;
			}
		}

		label.radioa {
			//background-color: #FFF  !important;
			border: 2px solid #69a2d7;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			height: 48px;
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 0;
			padding: 0px 0px 0px 50px;
			line-height: 44px;
			margin-bottom: 10px;
			width: 100%;
			float: left;
			text-align: left;
			clear: none;
			//color: $color_2;
			font-weight: normal;
			display: block;
			font-size: 14px;

			span {
				position: absolute;
				left: 2px;
				color: $color_1;
				font-weight: 400;
				height: 40px;
				line-height: 40px;
				padding: 0px 14px;
				margin: 2px 0px;
				background-color: #69a2d7;
				border-radius: 5px;
			}
		}

		label.radiob {
			background-color: #69a2d7 !important;
			border: 2px solid #257eba;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			-webkit-border-radius: 25px;
			-moz-border-radius: 25px;
			height: 60px;
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 0;
			padding: 0px 15px 0px 35px;
			line-height: 26px;
			margin-bottom: 10px;
			width: 100%;
			float: left;
			text-align: left;
			clear: none;
			color: $color_1;
			font-weight: normal;
			display: block;
			font-size: 14px;

			span {
				position: absolute;
				left: 2px;
				color: $color_1;
				font-weight: 400;
				height: 40px;
				line-height: 40px;
				padding: 0px 14px;
				margin: 2px 0px;
				border-radius: 5px;
			}
		}

		label.radio {
			background-color: rgba(0, 156, 127, 0.18) !important;
			border: 2px solid $color_2;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			border-radius: 4px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			height: 48px;
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 0;
			padding: 0px 0px 0px 50px;
			line-height: 44px;
			margin-bottom: 10px;
			width: 100%;
			float: left;
			text-align: left;
			clear: none;
			color: $color_2;
			font-weight: normal;
			display: block;
			font-size: 17px;
		}

		.spechead {
			ul {
				text-align: left;
				padding: 15px 0 0 20px;

				li {
					list-style: upper-latin;
					text-align: left;
					font-size: 16px;
					color: #262626;
					font-style: italic;
					margin-bottom: 4px;
					padding-left: 7px;
				}
			}
		}
	}

	.radio {
		&:checked+label {
			background-color: rgba(47, 57, 150, 0.68) !important;
			border: 2px solid $color_2;
			color: $color_1;
		}
	}

	label.radio {
		&:active {
			background-color: $background_color_2;
			border-color: $color_2;
			color: $color_1;
		}
	}

	input.radio {
		position: absolute;
		visibility: hidden;
	}

	input {
		width: 0px;
	}

	.form-select {
		font-size: 16px;
		font-weight: 300;
		border: 2px solid #ddd !important;
		height: 50px !important;
		border-radius: 1px;
	}

	.form-control {
		font-size: 16px;
		font-weight: 300;
		background: none !important;
		border: 2px solid #ddd !important;
		height: 50px !important;
		border-radius: 1px;
	}

	.btn-next {
		color: $color_1;
		font-weight: 400;
		padding: 15px 10px;
		width: 100%;
		cursor: pointer;
		border: 0px;
		border-radius: 5px !important;
		float: right;
		margin: 15px 0;
		background: #69a2d7;
		font-size: 20px;

		&:hover {
			color: #fff !important;
			background: #2f9de8;
		}
	}

	.option_but {
		background: #69a2d7;
		display: inline-block;
		border: 0px solid $color_6;
		border-bottom: 3px solid $color_3;
		font-size: 20px;
		color: $color_1;
		font-weight: 600;
		border-radius: 1px !important;
		box-sizing: border-box;
		width: 100%;
		cursor: pointer;
		margin: 0 0 20px !important;
		position: relative;
		overflow: hidden;
		text-align: center;
		padding: 16px 15px;

		span {
			display: inline-block;
			vertical-align: middle;

		}

		&:hover {
			color: #fff !important;
			background: #2f9de8;
		}

	}

	.option_but .checked {
		border-color: $color_6;
		background-color: $background_color_3;
		z-index: 100;
		color: $color_1;
	}

	.statusbar {
		position: fixed;
		bottom: 0;
		left: 0;
		background: rgba(72, 136, 232, .68);
		width: 100%;
		border-top: 1px solid #2f3996;
		padding: 5px 0;

		.arrow-up {
			background: $background_color_2 url(/assets/img/arrow-up.png) no-repeat center center;
			width: 20px;
			height: 12px;
			padding: 13px !important;
			float: right;
			margin: 2px;
			border-radius: 5px;
			cursor: pointer;
		}

		.arrow-down {
			background: $background_color_2 url(/assets/img/arrow-down.png) no-repeat center center;
			width: 20px;
			height: 12px;
			padding: 13px !important;
			float: right;
			margin: 2px;
			border-radius: 5px;
			cursor: pointer;
		}
	}

	#myProgress {
		width: 100%;
		background-color: #b6d4ff !important;
		margin-bottom: 0px;
		border-radius: 15px !important;
		overflow: hidden;
		height: 15px;
		margin: 9px 0;
		display: block;
	}

	.myBar {
		margin: 0px 0 0;
		padding: 0px 0 0;
		font-weight: 500;
		height: 15px;
		background-color: #4e87bd;
		text-align: center;
		color: $color_1;
		border-radius: 15px !important;
		transition: 0.4s linear;
		transition-property: width, background-color;
		font-size: 11px;
		transition: 0.4s linear;
		transition-property: width, background-color;
	}

	.progress-value {
		width: 100%;
		background-color: #4888e8 !important;
		margin-bottom: 0px;
		border-radius: 15px !important;
		overflow: hidden;
		height: 15px;
		margin: 9px 0;
		color: $color_1;
		font-size: 11px;
		padding-left: 10px;
		display: none;
	}

	// .btn-fileimp input[type=file] {
	// 	position: absolute;
	// 	top: 0;
	// 	right: 0;
	// 	left: 0;
	// 	min-width: 100%;
	// 	min-height: 100%;
	// 	font-size: 100px;
	// 	text-align: right;
	// 	filter: alpha(opacity=0);
	// 	opacity: 0;
	// 	outline: none;
	// 	display: block;
	// 	cursor: pointer;
	// }

	.btn-file {
		font-size: 13px;
		position: relative;
		padding: 15px 10px;
		height: 45px;
		cursor: pointer;
		border: 1px solid #cfcfcf;
		background: #fff;
		border: 2px solid #78a7ec;
		border-radius: 4px;

		.take_picture {
			width: auto;
			opacity: 0;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
		}

		img {
			height: 20px;
		}
	}

	// .btn:hover {
	// 	border-color: var(--bs-btn-hover-border-color);
	// 	color: #fff !important;
	// 	background: #69a2d7;
	// }

	.activeclass {
		background: #69a2d7;
		color: $color_1;
	}

	.disabledclass {
		border-color: var(--bs-btn-hover-border-color);
		background: #69a2d7;
		opacity: 0.5;
		cursor: default;
	}


	.blackover {
		background: rgba(0, 0, 0, 0.85) !important;
		opacity: 1;
		.modal-content {
			box-shadow: 1px 1px 23px -1px rgb(75, 118, 158);
		}

		.box-img {
			border: black solid 1px;
			margin-bottom: 12px;
		}

		/* CSS */
		.button-19 {
			appearance: button;
			background-color: #69a2d7;
			border: solid transparent;
			border-radius: 4px;
			border-width: 0 0 4px;
			box-sizing: border-box;
			color: #FFFFFF;
			cursor: pointer;
			display: inline-block;
			font-size: 15px;
			font-weight: 500;
			letter-spacing: .8px;
			line-height: 20px;
			margin: 0;
			outline: none;
			overflow: visible;
			padding: 8px 24px;
			text-align: center;
			text-transform: uppercase;
			touch-action: manipulation;
			transform: translateZ(0);
			transition: filter .2s;
			user-select: none;
			-webkit-user-select: none;
			vertical-align: middle;
			white-space: nowrap;

			&::after {
				background-clip: padding-box;
				background-color: #69a2d7;
				border: solid transparent;
				border-radius: 16px;
				border-width: 0 0 4px;
				bottom: -4px;
				content: "";
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: -1;
			}

			&:hover {
				-webkit-filter: brightness(1.1);
			}
		}
	}

	.dis_none {
		display: none;
	}

	.space-top {
		padding-top: 180px;
	}

	.space-top2 {
		padding-top: 100px;
	}

	// ANIMATIONS

	.anim_ylw {
		-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		box-shadow: 0 0 0 0 #359f14;

		@-webkit-keyframes pulse {
			to {
				box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}

		@-moz-keyframes pulse {
			to {
				box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}

		@-ms-keyframes pulse {
			to {
				box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}

		@keyframes pulse {
			to {
				box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}
	}

	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	.fadeIn {
		-webkit-animation-name: fadeIn;
		animation-name: fadeIn;
	}

	@-webkit-keyframes fadeInDown {
		from {
			opacity: 0;
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);
		}

		to {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}

	@keyframes fadeInDown {
		from {
			opacity: 0;
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);
		}

		to {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}

	.fadeInDown {
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
	}

	.animated {
		-webkit-animation-duration: 3s;
		animation-duration: 3s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}


	@keyframes fadeInUp {
		from {
			transform: translate3d(0, 40px, 0);
			-webkit-transition: -webkit-transform 2.6s ease-in-out;
			transition: transform 0.6s ease-in-out;
		}

		to {
			transform: translate3d(0, 0, 0);
			opacity: 1
		}
	}

	@-webkit-keyframes fadeInUp {
		from {
			transform: translate3d(0, 40px, 0)
		}

		to {
			transform: translate3d(0, 0, 0);
			opacity: 1
		}
	}

	.fadeInUp {
		opacity: 0;
		animation-name: fadeInUp;
		-webkit-animation-name: fadeInUp;
	}

	.form_modal {

		.modal-content {
			.modal-body {
				border: 4px solid #2f3996;
			}

			.modal-footer {
				background: #2f3996 !important;
				border-top: none;
			}
		}
	}

	// Media Query
	@media (min-width: 576px) {
		.container {
			//max-width: 100%;
		}
	}

	// @media only screen and (max-width: 600px) and (min-width: 400px)  {

	// }
	@media (max-width: 1400px){
        ul.secondop .popupLink:nth-child(2) label  {
			line-height: 20px !important;
          }
    }

	@media (max-width: 480px){
        ul.secondop .popupLink label  {
			line-height: 14px !important;
			display: flex;
    		align-items: center;
          }
    }

	@media (max-width: 410px){
        ul.secondop .popupLink:nth-child(2) label  {
			line-height: 13px !important;
			padding-right: 3px !important;
          }
    }

	@media (max-width: 991px) {
		.formsection {
			.custom-checkbox+label {
				height: auto;
				padding: 10px 40px;
				line-height: 18px;
			}

			ul.options {
				li {
					margin-bottom: 10px;
				}
			}
		}

	}

	@media (max-width: 576px) {


		.space-top {
			margin-top: 0 !important;
		}

		.input-mobile {
			width: 100%;
			border-top-right-radius: 4px !important;
			border-top-left-radius: 4px !important;
			border-radius: 0px;
		}

		section {
			.questionspart {
				&::after {
					background-size: 40%;
					top: 20px;
					height: 115px;
				}

				.space {
					margin-top: 50%;

					h3 {
						font-size: 20px;
					}
				}
			}
		}

		.formsection {
			h2 {
				font-size: 14px;
			}

			&::after {
				background-size: 40%;
				top: 20px;
				height: 115px;
			}

			.radio-box {
				label.radioa.a2 {
					line-height: 20px !important;
					font-size: 13px !important;
					padding-left: 45px !important;
					min-height: 48px !important;
					height: auto !important;
				}
			}

			.custom-checkbox+label {
				height: auto;
				min-height: 36px;
				margin: 0 0 5px 0;
				padding: 5px 5px 5px 35px;
				line-height: 20px;
			}

			ul.options {
				list-style: none !important;

				li {
					cursor: pointer;
					margin: 0 0 5px 0;
					width: 100%;
					float: left;
					margin-bottom: 5px;

					&:nth-child(2n) {
						float: right;
					}
				}

				display: block;
				padding: 0px 0 0 0;
			}
		}

		.space-top {
			padding-top: 130px;
		}



	}

	.show {
		display: block !important;
	}

	.hide {
		display: none !important;
	}

	.error_msg {
		background: #fb0404;
		color: #fff;
		font-size: 13px;
		width: 100%;
		float: left;
		text-align: center;
		padding: 6px;
	}

	.show_box {
		width: 100%;
		background: rgba(0, 0, 0, .5411764705882353);
		z-index: 99999;
		border-radius: 5px;
		position: absolute;
		top: 0;
		height: 100%;
		padding: 50px 30px;
		left: 0;

		.show_txt {
			background: #004f92;
			border-radius: 10px;
			padding: 15px 15px 23px 15px;
			box-shadow: 1px 2px 13px 1px rgba(0, 0, 0, 0.4);
			-webkit-box-shadow: 1px 2px 13px 1px rgba(0, 0, 0, 0.4);
			-moz-box-shadow: 1px 2px 13px 1px rgba(0, 0, 0, 0.4);
			margin-top: 10px;

			p {
				color: #fff;
				font-size: 20px;
				font-weight: 600;
			}

			.bckbt {
				background: #a0a0a0;
				color: #fff;
			}
		}
	}
}