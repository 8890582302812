	/*
	*******************************************
	Template Name: Allegiant
	Author: Arun Ravi
	Date:18-07-2022
	Template By: Vandalay Designs
	Copyright 2021-2022 Vandalay Business Solution

	* This file contains the styling for the Allegiant, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/

	@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.FollowupFosV1{
	font-family: 'Poppins', sans-serif !important;
	.tms-wrap{
		min-height: 100vh;
	
		.showcase {
			height: 100vh;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: #fff;
			padding: 0 60px;
			@media only screen and (max-width: 968px) {
				padding: 0 20px;
			}
			@media only screen and (max-width: 600px) {
				padding: 0 20px;
			}
			@media only screen and (max-width: 480px) and (min-width: 430px){
				padding: 30px 20px;
				justify-content: start;
				align-items: start;
			}
			@media only screen and (max-width: 430px) and (min-width: 392px){
				padding: 100px 20px;
			}
			@media only screen and (max-width: 392px) {
				padding: 50px 20px;
			}
	
			.video-container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: hidden;
				 

				video {
					width: 100%;
					min-height: 100%;
					object-fit: cover;
				}
				&:after {
					content: '';
					z-index: 1;
					height: 100%;
					width: 100%;
					top: 0;
					left: 0;
					background: rgba(0, 0, 0, 0.753);
					position: absolute;
				}
			}
			.content {
				z-index: 2;
				h1{
					font-size: 34px;
					line-height: 140%;
					@media only screen and (max-width: 980px) {
						font-size: 30px;
					}
					@media only screen and (max-width: 640px) {
						font-size: 24px;
					}
				}
				h3{
					font-size: 20px;
					line-height: 140%;
					font-weight: 400;
					@media only screen and (max-width: 980px) {
						font-size: 20px;
					}
					@media only screen and (max-width: 640px) {
						font-size: 15px;
					}
				}

				.com-btn {
					
					@media only screen and (max-width: 1200px) {
						font-size: 18px;
					}
					@media only screen and (max-width: 590px) {
						font-size: 14px;
					}
				}

				
			 
				.cls_btn{
					z-index: 2;
					.close_bbt{
						display: inline-block;
						border: none;
						outline: none;
						padding: 13px 30px;
						background: transparent;
						border: 2px solid #fff;
						color: #fff;
						border-radius: 5px;
						font-size: 18px;
						margin-top: 20px;
						width: 350px;

						@media only screen and (max-width: 600px) {
						  width: auto;
						}
					}
				}
			}
	
		}
	
	}
	

	.loader-wrap {
        height: 100vh;
        width: 100%;
        overflow: hidden;
        .loader-logo {
           
            img {
                height: 65px;
                margin: 20px 0;
				@media (max-width: 490px){
					height: 50px;
				}
            }
        }
        .text-analys {
            font-size: 18px;
			@media (max-width: 768px){
						font-size: 14px;
						max-width: 80%;
						margin: 0 auto;
			}
        }
        .loader {
            margin-top: 4rem;
            img {
                height: 130px;
				@media (max-width: 490px){
					height: 100px;
				}
            }

        }
        .loader-text {
            max-width: 600px;
            margin: 0 auto;
        }
    }

	}
	
	
	























