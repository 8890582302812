/*
*******************************************
Template Name: The Financial Guru
Author: ABC
Date: 09-11-2022
Template By: Vandalay Designs
Copyright 2020-2022 Vandalay Business Solution

* This file contains the styling for laonclaims.co.uk, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - RESET
04 - BODY 
05 - HEADER SECTION
06 - MAIN SECTION
07 - SIDE SECTION
08 - FOOTER 
09 - MEDIA QUERY

============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/
.view_doc {
$color_1: #00aff0;
$color_2: #5290ca;
$color_3: #000;
$color_4: #fff;
$color_5: #303291;
$color_6: #f1f1f2;
 

$popTransp: rgba(0, 0, 0, 0.425);
// ======font styles===============

$font_family_1: "Titillium Web", sans-serif;
 
// ======border===========
.border {
  border: 1px solid $color_3;
}
 
/*================================================
03 - RESET
==================================================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
  text-decoration: none;
}

p {
  font-size: 14px;
  line-height: 22px;
}
a {
  cursor: pointer;
  text-decoration: none;
  line-height: 20px;
}
ul {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
  }
}

.d_none {
  display: none !important;
}

/*================================================
04 - BODY STYLES
==================================================*/

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-size: 100%;
}
body {
  font-family: $font_family_1 !important;
  font-size: 1rem;
  line-height: 1rem;
}

/*================================================
END - BODY STYLES
==================================================*/

.under_line {
  text-decoration: underline;
}

.suc_page {
  .tms_logo {
   
    img {
      width: 300px;
      
    }
    
  }
  .loader {
    img{
      width: 80px;
    }
  }
  .text {
    font-size: 1.5rem;
    color: $color_5;
    line-height: 30px;
    font-weight: 600;
    max-width: 800px;
    margin: 0 auto;
 
  }

  .btn-view {
    background: $color_2;
    padding: 20px;
    width: 600px;
    color: $color_4;
    border-radius: 5px;
    font-size: 20px;
    display: inline-block;
    transition: ease-in 0.5s;
    &:hover {
      background: #303291;
      transition: ease-in 0.5s;
    }

    

  }
  .suc_midle {
    margin-top: 7rem;
  }
  
}
 
/*================================================
05 - HEADER SECTION
==================================================*/
 


$break-max-tab: 768px;
 
@media (max-width: $break-max-tab) {
  .suc_page {
    .btn-view {
      width: 100%;
      font-size: 1rem;
    }
  }

  .container{
    margin: auto !important;
    width: 90% !important;
  }

  .suc_page {
    .tms_logo {
      img {
        width:100%;
        
    }
    }
    .text {
      font-size: 1.1rem;
      line-height: 25px;
    }
  }
}
}