.FollowupContainer{
	/*
	*******************************************
	Template Name:tax allowance awareness
	Author: Allen Mathew
	Date:05-12-2022
	Template By: Vandalay Designs
	Copyright 2021-2022 Vandalay Business Solution

	* This file contains the styling for the TMS Legal, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************


	============================================
	==== T A B L E   O F   C O N T E N T S =====
	============================================
	01 - Sass Variables
	02 - Sass Mixins
	03 - BODY 
	04 - HEADER 
	05 - Questionnaie 

	08 - FOOTER 
	09 - MODAL 
	10 - MEDIA QUERY
	11 - ANIMATION
	============================================
	============================================

	*/


	/*================================================
	01 - Sass Variables
	==================================================*/

	$background_color_1:#ffffff;
	$background_color_2:#2f3996;
	$background_color_3:#3f4792;
	$background_color_4:#359f14;
	$background_color_5:#1e7b01;
	$background_color_6:rgba(21, 54, 111, 0.45);



	$color_1:#ffffff;
	$color_2:#000000;
	$color_3:#2f3996;
	$color_4:#881f1f;
	$font-normal:normal;


	// ======border===========
	$border_0:0px;
	$border-rd4:4px;
	$border-style1:solid 1px rgba(255, 255, 255, 0.30);
	$border-style2:4px solid #23df6a;
	$border-style3:2px solid #d5d5d5;
	$border-style4:1px solid #b4b4b4;
	$border-style5:1px solid #2f3996;
	/*================================================
	02 - Sass Mixins
	==================================================*/

	@mixin shadow-1 {
		-webkit-box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.51);
			-moz-box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.51);
			box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.51);
	}
	@mixin shadow-2 {
		-webkit-box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.90);
			-moz-box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.90);
			box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.90);
	}

	@mixin shadow-3 {
		-webkit-box-shadow: 0px 3px 24px 0px rgba(0,0,0,0.63);
		-moz-box-shadow: 0px 3px 24px 0px rgba(0,0,0,0.63);
		box-shadow: 0px 3px 24px 0px rgba(0,0,0,0.63);
	}

	@mixin shadow-4{
		-webkit-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
		-moz-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
		box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
	}
	@mixin rounded{
		-moz-border-radius: 100%;
			-webkit-border-radius: 100%;
			border-radius: 100%;
	}
	@mixin radius_5{
		-moz-border-radius: 5px;
			-webkit-border-radius: 5px;
			border-radius: 5px;
	}
	/*================================================
	03 - BODY STYLES
	==================================================*/

	scroll-behavior: smooth;
	
	a {
		cursor: pointer;
	}

	// ======BODY STYLE========

	
	// padding: 0px;
	// margin: 0px; 
	.Followup{
		&::before{
			z-index: -1;
			opacity: 0.3;
			content: "";
			position: fixed;
			height: 100%;
			width: 100%;
			background: #ffffff !important;
			background: -moz-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
			background: -webkit-gradient(left top, left bottom, color-stop(0%, #67c4b1), color-stop(24%, #fff), color-stop(49%, #fff), color-stop(69%, #fff), color-stop(100%, #5cc0ad)) !important;
			background: -webkit-linear-gradient(top, #2f3996 0%, #fff 24%, #fff 49%, #fff 69%, #2f3996 100%) !important;
			background: -o-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
			background: -ms-linear-gradient(top, #67c4b1 0%, #fff 24%, #fff 49%, #fff 69%, #5cc0ad 100%) !important;
			background: linear-gradient(to bottom, #2f3996 0%, #fff 24%, #fff 49%, #fff 69%, #2f3996 100%) !important;
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#67c4b1', endColorstr='#5cc0ad', GradientType=0 );
		}
	}	
	.container{
		max-width: 600px !important;
	}

	// ======CUSTOM ELEMENTS========

	.nopad {
		padding: 0px !important;
	}
	.pl-0{
		padding-left:0px !important;
	}

	.pr-0{
		padding-right:0px !important;
	}

	.space-sec{
		margin-top: 9px;
			margin-left: 2px;
	}
	.pb-0{
		padding-bottom:0px !important;
	}
	.p-15{
		padding: 0 15px !important;
		.spcl{
			max-width: 16%;
			margin: -6px 5px;
		}
	}
	.blue-bg{
		background: $background_color_2;
		@include radius_5;
		p{
			font-size: 15px;
			text-align: center;
			padding:7px 10PX;
			color: $color_1;
		}
	}
	
	.questionspart{
		&::after{
			z-index: -1;
			content: "";
			position: fixed;
			height: 183px;
			width: 100%;
			top:5%;
			background-image: url(../img/logo_bg.png) !important;
			background-position:center !important;
			background-repeat: no-repeat!important; 
			opacity: 0.28;
		}
		.btn-submit{
			background: $background_color_4 !important;
			color: $color_1 !important;
			padding: 12px 10px !important;
			border: 0px;
			border-bottom: solid 4px $background_color_5 !important;
			@include radius_5;
			width: 100% !important;
			margin-top: 15px;
			text-transform: uppercase;
			bottom: 0;
			position: fixed;
			left: 0;
		}
		h3{
			font-size: 20px;
			text-align: center;
			padding: 10PX;
			color: $color_3;
		}
		h4{
			font-size: 19px !important;
			text-align: center;
			padding: 10PX;
			color: $color_1 !important;
			margin-bottom: 0px !important;
			padding-bottom: 0px !important;
			strong{
				font-weight: 700;
			}
		}
		.li-box{
			ul{
				margin: 0px;
				padding: 20px 15px 20px 15px;
				li{
					list-style: none;
					padding: 7px 0 7px 35px;
					margin-bottom: 0px;
					font-size: 16px;
					font-style: italic;
					background: url(../img/after-up.png) no-repeat left center;
				}
			}	
		}
	}
	
	.form-control{	
		background-color:$background_color_1;
		border: 2px solid #d5d5d5;
		font-size: 16px;
		font-weight: normal;
		height: 50px !important;
		outline: none !important;
		padding: 0px 10px;
	}

	.inp-wid{
		width: 92% !important;
	}
	.secured{
		position: relative;
		margin-top: 0px;
	}
	p.sec_t{
		color: #757575;
		font-size: 14px;
		margin-top: 10px;
		line-height: 22px;
	}
	// ======YES OR NO========
	.space{
		margin: 45% 0 !important;
	}
	.space2{
		margin: 25% 0 !important;
	}

	legend {
		font-size: 16px  !important;
		width: auto  !important;
		padding: 0 10px  !important;
		font-weight: normal  !important;
	}

	fieldset {
		border: 1px solid #2f3996  !important;
		padding: 10px 10px 0  !important;
		border-radius: 4px  !important;
		margin-bottom: 15px  !important;
	}

	.radio-box {
		margin:30px 0 ;
		display: inline-block;
		width: 100%;
		h3{
			line-height: 28px;
			margin: 0 !important;
			padding: 0px 0 20px;
			font-size: 20px !important;
			text-align: center;
			color: #69a2d7 !important;;
			span{
				color: $color_3;
			}
		}
		h5{
			font-size:16px;
			font-weight: 400;
			margin-bottom:20px;
		}
		.styl_i{
			font-size: 14px;
			margin-bottom: 27px;
			line-height: 1.2em;
			color: #45474a;
			font-style: italic;
		}
		.styl2_i{
			font-size: 14px;
			margin-top: 27px;
			line-height: 1.2em;
			color: #45474a;
			font-style: italic;
		}

		.mar-5{
			margin-bottom: 5px !important;
		}
		label.radioa {
			border:2px solid $color_3;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			border-radius: 4px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			height:48px;
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 0;
			padding: 0px 0px 0px 50px;
			line-height: 44px;
			margin-bottom: 10px;
			width: 100%;
			float: left;
			text-align: left;
			clear: none;
			color: $color_3;
			font-weight: normal;
			display: block;
			font-size: 14px;
			.st1{
				background-color: #ff0505 !important;
				font-size: 22px;
			}
			span{
				position: absolute;
				left: 2px;
				color: $color_1;
				font-weight: 400;
				height: 40px;
				line-height: 40px;
				padding: 0px 14px;
				margin: 2px 0px;
				background-color: $background_color_2;
				border-radius: 5px;      
			}
		}
		label.radio {
			background:   rgba(255, 255, 255, 0.26);
			border:2px solid $color_3;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			border-radius: 4px;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			height:48px;
			position: relative;
			cursor: pointer;
			outline: none;
			margin: 0;
			padding: 0px 0px 0px 50px;
			line-height: 44px;
			margin-bottom: 10px;
			width: 100%;
			float: left;
			text-align: left;
			clear: none;
			color: $color_3;
			font-weight: normal;
			display: block;
			font-size: 17px;
		}
		label.l_g{
			background-color: rgba(0, 156, 127, 0.18) !important;    
		}
		label.l_r{
			background-color: rgba(167, 34, 4, 0.18) !important;
			color: #ff0505;
			border: solid 2px #ff0505;
		}
		.bgno{
			background:   rgba(255, 255, 255, 0.26) !important;
			color: $color_2 ;
		}


		.style3a{
			text-align: center;
			background-color:$background_color_5 !important;
			padding: 0px !important;
			border: solid 1px $color_4 !important;
			margin-top: 10px;
		}
		.btn-fileimp{
			border: 2px solid #78a7ec;
			background: #fff;
			&:hover,&:hover button,:focus,:focus button {
				background: #69a2d7;
				color: $color_1;
				border-radius: 5px;
			}
			i {
				font-size: 20px;
			}
			button{
				 
				width: 100%;
				left: 0;
				top: 0;
				height: 100%;
				border: none;
				background: none;
			}
		}
		.btn-file {
			font-size: 15px;
			position: relative;
			padding: 15px 10px;
			height: 45px;
			cursor: pointer;
			img{
				height: 20px;
			}

			.take_picture  {
				width: auto;
				opacity: 0;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
		}
	}
	.radio {
		&:checked+label.radio {
			background-color:$background_color_6 !important;
			background: url(../img/tick.png) no-repeat left center;
			border: 2px solid $color_3;
			color: $color_1;
		}
	}
	label.radio {
		&:active {
			background-color: $background_color_2;
			border-color: $color_3;
			color: $color_1;
		}
	}

	.radioa {
		&:checked+label.radio {
			background-color:$background_color_6 !important;
			background: url(../img/tick.png) no-repeat left center;
			border: 2px solid $color_3;
			color: $color_1;
		}
	}
	.radioa {
		&:active {
			background-color: $background_color_2 !important;
			border-color: $color_3;
			color: $color_1;
		}
	}

	@media screen and (min-width: 1px) {
		input.radio {
			position: absolute;
			left: -9999px;
		}
	}
	
	.info-list{
		padding: 0px 0 10px !important;
		margin: 0px;
		ul{
			padding: 0px;
			margin: 0px;
			li{
				display: block;
				font-size: 13px;
				padding: 5px 0 5px 28px;
				font-style: italic;
				font-weight: normal;
				background: url(../img/li-bulb.png) no-repeat left center;
				color: #fb0101;
			}
		}
	}
	
	// ======STATUS BAR========


	// ======Button ========

	.btn-next-pop{
		background: $background_color_4 !important;
		color: $color_1 !important;
		padding: 12px 10px !important;
		border: 0px;
		border-bottom: solid 4px $background_color_5 !important;
		@include radius_5;
		width: 100% !important;
		margin-top: 15px;
		text-transform: uppercase;
		bottom: 0;
		position: fixed;
		left: 0;
		z-index: 9999;
	}

	.share-btn{
		// background:url("../img/share.png;") no-repeat center center;
		padding:5px 7px;
		color: $color_1;
		font-size: 14px;
		font-weight: normal;
		border: 0px;
		border-radius: 5px;
		font-style: italic;
		margin-left: 7px;
	}
	.ver-but {
		background-color: #359f14;
		color: #fff !important;
		font-size: 15px !important;
		border-radius: 4px;
		padding: 9px 20px !important;
		border: 0px;
		width: 100%;
		cursor: pointer;
		margin-bottom: 10px;
		text-transform: uppercase;
		float: right;
		text-align: center;
	}
	.add-btn{
		background:none;
		padding:5px 10px;
		color: red;
		font-size: 14px;
		font-weight: normal;
		border: 2px solid red;
		border-radius: 5px;
		font-style: italic;
		min-width: 90px;
	}

	.error{
		display: none;
		color: #fff;
		background: #f00;
		width: 100%;
		padding: 2px 5px;
		float: left;
		text-align: center;
		border-radius: 4px;
		font-size: 13px;
	}

	.btn-clear{
		background-color:red;
		color: $color_1 !important;
		font-size: 15px !important;
		border-radius: $border-rd4;
		padding: 9px 15px;
		border: $border_0;
		width:auto;
		cursor: pointer;
		margin-bottom: 10px;
		text-transform: uppercase;
		&:hover{
			background-color: $background_color_2;
		}
	}

	.submitpop{
		background-color: $background_color_4;
		color: $color_1 !important;
		font-size: 15px !important;
		border-radius: $border-rd4;
		padding: 9px 20px;
		border: $border_0;
		width:auto;
		cursor: pointer;
		margin-bottom: 10px;
		text-transform: uppercase;
		float: right;
		text-align: center;
		&:hover{
			background-color: $background_color_2;
		}
	}
	
	.data-div{
		padding: 0px 0 !important;
		background: $background_color_1;
		margin: 70px 0 40px !important;
		@include radius_5;
	}
	.q-col{
		padding: 7px 0;
		p{
			font-size: 13px;
			color:$color_2;
			font-weight: normal;
			margin: 0px;
			padding-bottom: 7px;
		}
		.q-num{
			width: 35px;
			padding-bottom: 7px;
		}
		.an-box{
			font-weight: bold;
			width: 85px;
		}

		.an-box2{
			font-weight: bold;
			width: 175px;
		}
		.y-box{		
			width: 85px;
		}
		.n-box{
			width: 85px;
		}
		
		.green-tick{

			height: 23px;
			position: relative;
			background-image: url(../img/green-tick.jpg);
			background-repeat: no-repeat;
			display: inline-block;

		}
		.red-tick{
			
			height: 23px;
			position: relative;
			background-image: url(../img/red-tick.jpg);
			background-repeat: no-repeat;
			display: inline-block;

		}
		.tick-col{
			margin: 0 3px;
			width: 24px;
		}
	}
	
	.sp-h{
		clear: both;
		height: 400px;
	}
	.blink_me {
		animation: blinker 1s linear infinite;
		color: red;
	}
	.custom-checkbox{
		display: none;
	}
		
	.cstfrm{
		label {
			background: hsla(0,0%,92.5%,.54) url(../img/unchecked.png) no-repeat 6px;
		}
		label.radioa{
			width: 49%;
			margin: 2px 1px 10px;
			line-height: 44px;
			padding: 0px 0px 0px 34px;
		}
	}
	.custom-checkbox:checked+label {
		background: rgba(9, 131, 168, 0.68) url(../img/checked.png) no-repeat 6px !important;
		color: #fff;
		border: 2px solid $color_3;
	}


   .disabledcheckbox {
		background: #fff url(../img/unchecked.png) no-repeat 7px center !important;
		color: #aeb2d9 !important;
		border: 2px solid #cbd5e4 !important;
	}

	.slctbdr{
		border: 2px solid #540463 !important;
	}
	.radioa {
		&:active {
			background: rgba(9, 131, 168, 0.68) url(../img/checked.png) no-repeat 6px !important;
			color: #fff !important;
			border: 2px solid $color_3;
		}
		&:focus {
			background-color: $background_color_2 !important;
			border-color: $color_3;
			color: $color_1;
		}
	}

	@media screen and (min-width: 1px) {
		input.radio {
			position: absolute;
			left: -9999px;
		}
	}

	.mb20{
		margin-bottom:0px !important;
	}

	.mt20{
		margin-top:20px !important;
	}
	

	/*================================================
	09 - MODAL 
	==================================================*/
	.modal-backdrop{
		z-index:1 !important;

		.first-modal{

		}
	}
	
		.modal{
			padding-right: 0px !important;
		}
		.modal-content{
			border:0px !important;
			.modal-header{
				background: $background_color_2;
				border-radius: $border_0 !important;
				.close{
					color: $color_1 !important;
				}
				.modal-title{
					color: $color_1;
					font-size: 18px;
				}
				.modal-body{
					h2{
						color: #2f3996 !important;
						font-size:18px !important;
						font-weight: 600;
						margin-bottom: 20px;
					}
					ol{
						li{

								padding: 5px 0;
								color: $color_2;
								a{
									color: $color_2 !important;
								}
						}
					}
				}
			}

			.modal-footer{
				background: $background_color_1;
				border: $border_0;
				padding: 10px 20px !important;
			}

			h3{
				font-size: 18px;
				color: $color_2;
			}
			p{
				font-size: 14px;
				margin-bottom: 7px;
			}

			ul.options {
				list-style: none !important;
				display: block;
				padding: 0px 0 0 0;
				li {
					cursor: pointer;
					margin: 0 0 1% 0;
					width: 100%;
					float: left;
					&:nth-child(2n) {
						float: right;
					}
				}
				.custom-checkbox {
					display: none;
					&+label {
						background: #fff url(../img/unchecked.png) no-repeat 7px center;
						border: 2px solid #78a7ec;
						box-sizing: border-box;
						height: 45px;
						position: relative;
						cursor: pointer;
						outline: none;
						margin: 0;
						padding: 0 0 0 35px;
						line-height: 40px;
						border-radius: 4px;
						width: 100%;
						clear: none;
						color: #2f3996;
						font-weight: normal;
						display: block;
						font-size: 14px;
		 				// overflow: hidden;
					 	// -webkit-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
						//-moz-box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
						//box-shadow: inset -5px -27px 0px -13px rgba(0, 0, 0, 0.11);
					}
					&:checked {
						&+label {
							background: #78a7ec url(../img/checked.png) no-repeat 7px center;
							color: $color_1;
							border: 1px solid #ddd;
						}
					}
				}
				
			}
		}

		.modal-open {
			.modal {
				background: rgba(0,0,0,0.85) !important;
			}
		}

	.sign-sec{
		ul{
			margin: 0px;
			padding: 0 0px;
			li{
				background: url(../img/arrow-li.png) no-repeat left center;
				list-style: none;
				padding:5px 0 5px 35px;
				margin-bottom: 0px;
				font-size: 15px;
			}
		}
		.t-area{
			min-height: 200px;
		}
		
		.sign-div{
			padding: 20px 0 0;
			.sign-text{
				position: absolute;
				z-index: 999;
				font-size: 17px;
				color: #5f5f5f;
				width: 94.5%;
				img{
					margin-top:100px;
				}
			}
		}		
	}

	// LOA

	.pdf-section{
		background-color: $background_color_1;
		
		padding:10px !important;
		margin-bottom: 15px;
		.logo{
			width:185px;
		}
		.headtxt{
			p{
				font-size:13px;
				color:$color_2;
			}
		}
	}

	.content_txt{
		h2{
			font-size:26px;
			color:$color_2;
			margin-bottom:15px;
			font-weight: bold;
			margin-top: 20px;
		}
		p{
			font-size: 12px;
			color:$color_2;
			font-weight: normal;
			margin: 0px;
		}
		h3{
			font-size: 15px;
			color:$color_2;
			font-weight: normal;
			margin: 5px 0;
		}
		h4{
			font-size: 14px;
			color:$color_2;
			font-weight:600;
			margin: 5px 0;
			text-align: center;
		}
		.text_small{
		
			p{
				font-size: 10px !important;
				color:$color_2;
				font-weight: normal;
				margin: 0px;
				line-height: 11px;

			}
		}
	}


	.content_txt_terms{
		h2{
			font-size:20px;
			color:$color_2;
			margin-bottom:15px;
			font-weight: bold;
			margin-top: 20px;
		}
		p{
			font-size: 14px;
			color:$color_2;
			font-weight:600;
			margin: 0px 0 15px;
		}
		h3{
			font-size: 17px;
			color:$color_2;
			font-weight: 800;
			margin: 5px 0 15px;
		}
		h4{
			font-size: 14px;
			color:$color_2;
			font-weight:600;
			margin:10px 0;
		}

		ul{
			li{
				font-size: 14px;
				color:$color_2;
				font-weight:600;
				margin-bottom: 15px;
			}
		}

	}
	.w90{
		width: 90px;
	}
	.w35{
		width: 35px;
		font-weight: bold;
	}
	.w100{
		width:100px;
	}
	.h100{
		height:100px;
	}
	.h75{
			height:75px;
	}
	.pad5{
		padding: 5px;
	}

	.padb5{
		padding-bottom: 5px !important;
	}
	.padb10{
		padding-bottom: 10px;
	}
	.mtop25{
		margin-top: 25px;
	}
	.mtb25{
		margin: 20px 0;
	}
	.mtb10{
		margin: 10px 0;
	}
	.mb0{
		margin-bottom: 0px !important;
	}
	// .table-bordered td {
	//     border: 1px solid #808080 !important;
	// }
	.bor-r{
		border-right: 1px solid #808080 !important;
	}
	.name-div{
		width: 100%;
		border-bottom: solid 1px $color_2;
		padding: 40px 0 !important;
		font-size: 13x !important;
		color:$color_2;
		font-weight: normal;
		margin: 0px;

	}
	.sign_div{
		width: 100%;
		border: solid 1px #000;
		padding: 30px 15px !important;
		font-size: 13x !important;
		color: #000;
		font-weight: normal;
		margin: 0px;
	}
	
	.client_div{
		width: 100%;
		border: solid 1px #376289;
		padding: 15px !important;
		margin: 0px;
		border-radius: 45px;
		margin-bottom: 15px;
	}
	.redtext {
			color: red !important;
	}
	.bor-box{
		border: solid 1px $color_2;
	}
	.pl75{
		padding-left: 75px !important;
	}
	.pt15{
		padding-top: 15px;
	}
	.arrowbg{
		background: url(../img/ar_bg.png) no-repeat;
		
	}
	.grey_bg{
		background-color: #e6e7e9;
	}

	.button{
		background: #00b63d;
		padding: 10px 20px;
		border-radius: 5px;
		min-width: 200px;
		display: inline-block;
		text-align: center;
		font-size: 17px;
		-webkit-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.51);
		-moz-box-shadow: 0px 4px 7px 0px rgba(0,0,0,0.51);
		box-shadow: 0px  4px 7px 0px rgba(0,0,0,0.51);
		font-weight: 800;
		cursor: pointer;
		// position: relative;
		color: #FFFFFF !important;
		border: solid 1px #16ef5f;
		text-decoration: none !important;
		left: 0;
		position: fixed;
		bottom: 0;
		width: 100%;
	}

	.table{
		th{ padding: 10px !important; vertical-align: middle !important; }
		td{ padding: 10px !important;    vertical-align: middle !important;}
	}
	.f-normal{
		font-weight: 400 !important;
	}

	// ======ANIMATION ========
	.animated-effect {
		-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
		box-shadow: 0 0 0 0 #359f14;
	}

	@-webkit-keyframes pulse {
		to {
			box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
		}
	}

	@-moz-keyframes pulse {
		to {
			box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
		}
	}

	@-ms-keyframes pulse {
		to {
			box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
		}
	}

	@keyframes pulse {
		to {
			box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
		}
	}
		
	@keyframes blinker {
		50% {
			opacity: 0;
		}
	}
	@-webkit-keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.fadeIn {
		-webkit-animation-name: fadeIn;
		animation-name: fadeIn;
	}

	@-webkit-keyframes fadeInDown {
		from {
			opacity: 0;
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);
		}
		to {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}

	@keyframes fadeInDown {
		from {
			opacity: 0;
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);

		}
		to {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}

	.fadeInDown {
		-webkit-animation-name: fadeInDown;
		animation-name: fadeInDown;
	}

	.animated {
		-webkit-animation-duration: 3s;
		animation-duration: 3s;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}


	@keyframes fadeInUp {
			from {
					transform: translate3d(0,40px,0);
			-webkit-transition: -webkit-transform 2.6s ease-in-out;
			transition: transform 0.6s ease-in-out;
			}

			to {
					transform: translate3d(0,0,0);
					opacity: 1
			}
	}

	@-webkit-keyframes fadeInUp {
			from {
					transform: translate3d(0,40px,0)
			}

			to {
					transform: translate3d(0,0,0);
					opacity: 1
			}
	}

	.fadeInUp {
			opacity: 0;
			animation-name: fadeInUp;
			-webkit-animation-name: fadeInUp;
	}

	.form-box2 {
		border: 1px solid  #c7c7c7;
		padding: 10px;
		border-radius: 5px;
		width: 100%;
		text-align: center;
	
		legend {
		  width: auto;
		  padding: 2px 10px;
		  font-size: 16px;
		  font-weight: normal;
		  float: none;
		  text-align: left;
		 
		  &.first {
			width: 90%;
		  }
		}
		.option_but_2 {
			padding: 10px;
			border-bottom: 0;
			border-radius: 5px !important;
			width: 100%;
			margin-bottom:3px !important;
			margin-left: 0 !important;
		}
	  }

	  .option_but_2 {  
		background: #2f3996;
		display: inline-block;
		border: 0px solid #2f3996;
		border-bottom: 3px solid #257eba;
		font-size:20px;
		color: $color_1;
		font-weight: 600;
		border-radius:1px !important;
		box-sizing: border-box;
		width: 45%;
		cursor: pointer;
		margin-top: 5px !important;
		margin-bottom: 20px !important;
		/*margin: 5px 0 20px !important;*/
		position: relative;
		overflow: hidden;
		text-align: center;
		padding: 16px 15px;
		span {
			display: inline-block;
			vertical-align: middle;
			
		}
		&:hover{
			color: #fff !important;
			background: #111857;
		}

	}	
	.checked_option_but {
		border-color: #111857;
		background-color: #111857;
		z-index: 100;
		color: $color_1;
	}

	
	.blackover{
		background: rgba(0, 0, 0, 0.85) !important;
		opacity: 1;
		.modal-content{ 
			box-shadow: 1px 1px 23px -1px rgb(75, 118, 158);		
		}

		.box-img{ 
			border: black solid 1px; 
			margin-bottom: 12px;
		}
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 1;
		background: #0057e3;
	}

	// ====== MEDIA QUERY ========

	@media screen and (max-width:1700px) and (min-width:992px) {
		.questionspart{
			&::after{
				background-size: 15%;
			}
		}
	}

	@media screen and (max-width:992px) and (min-width: 768px) {
		.questionspart{
			&::after{
				background-size: 15%;
			}
		}
	}

	@media screen and (max-width: 767px) {
		.questionspart{
			&::after{
				background-size: 40%;
			}
		}

		.data-div{
			padding: 0px 0 0px !important;
			margin: 50px 0 55px !important;
		}
		.table{
			th{ padding: 10px !important; vertical-align: middle !important; }
			td{ padding: 10px !important;    vertical-align: middle !important;}
			}

			.mb20{
			margin-bottom:0px !important;
		}

		.mt20{
			margin-top:20px !important;
		}
	}
	
	@media (max-width: 576px) {
		.questionspart{
			&::after{
				background-size: 40%;
			}
		}
		.container {
			max-width: 100%;
		}

		.cstfrm{
			label {
				background: hsla(0,0%,92.5%,.54) url(../img/unchecked.png) no-repeat 6px;
			}
			label.radioa{
				width: 49%;
				margin: 2px 1px 10px;
				line-height: 44px;
				padding: 0px 0px 0px 25px;
				font-size: 13px;
			}
		} 
		.mob-pad5{
			padding: 5px !important;
		}

	}

	@media (max-width: 380px) {
		.cstfrm{
			label {
				background: hsla(0,0%,92.5%,.54) url(../img/unchecked.png) no-repeat 6px;
			}
			label.radioa{
				width: 49%;
				margin: 2px 1px 10px;
				line-height: 44px;
				padding: 0px 0px 0px 25px;
				font-size: 12px;
			}
		} 

		.content_txt{
			h4{
				font-size: 13px;
			}
		}
	}

	.signature_canvaseWrap .signature-pad {
    border: 1px dotted #000!important;
    background: url(/assets/img/sign-bg.png) no-repeat #fff 49% 67px!important;
    border-radius: 5px;
    display: block;
    margin: 20px auto;
    position: relative;
    width: 90%;
    height: 72%;
	}
	.sign_msg {
		color: #ffffff;
		width: 100%;
		float: left;
		background: #f00;
		font-size: 13px;
		padding: 0px 5px;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		margin-bottom: 10px;
		text-align:center;
	}

	.gb {
    position: absolute;
    background: #2f3996 !important;
    color:white;
    height: 60px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color_1 !important;
    padding: 6px 10px;
    border-radius: 10px 5px 5px 10px;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    p{ 
        margin-bottom: 0;
    }
	}
	.car-reg{
    height: 60px !important;
    border: 2px solid #2f3996 !important;
    font-size: 18px !important;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    width: 100% !important;
  } 

	.amount-text {
    padding-left: 45px;
    width: 100%;
	}
	.btn-next{
    color: $color_1;
    font-weight: 400;
    padding:15px 10px;
    width:100%;
    cursor: pointer;
    border:0px;
    border-radius:5px !important;
    float: right;
    margin: 15px 0;
    background: #2f3996;
    font-size: 20px;
    &:hover{
      color: #fff !important;
      background: #3b46ac;
    }
	}

	.option_but {  
    background: #2f3996;
    display: inline-block;
    border: 0px solid #2f3996;
    border-bottom: 3px solid #2f3996;
    font-size:20px;
    color: $color_1;
    font-weight: 600;
    border-radius:1px !important;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
    margin: 0 0 20px !important;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 16px 15px;
    span {
			display: inline-block;
			vertical-align: middle;
    }
    &:hover{
			color: #fff !important;
			background: #2f3996;
    }
		input{
			width: 0px;
		}
	}	
	.option_but .checked {
		border-color: #2f3996;
		background-color: #2f3996;
		z-index: 100;
		color: $color_1;
	} 

	.show_box {
    width: 100%;
    background: rgba(0,0,0,.5411764705882353);
    z-index: 99999;
    border-radius: 5px;
    position: absolute;
    top: 0;
    height: 100%;
    padding: 50px 30px;
    left: 0;
		.show_txt {
			background: #004f92;
			border-radius: 10px;
			padding: 15px 15px 23px 15px;
			box-shadow: 1px 2px 13px 1px rgba(0, 0, 0, 0.4);
			-webkit-box-shadow: 1px 2px 13px 1px rgba(0, 0, 0, 0.4);
			-moz-box-shadow: 1px 2px 13px 1px rgba(0, 0, 0, 0.4);
			margin-top: 10px;
			p {
				color: #fff;
				font-size: 20px;
				font-weight: 600;
			}
			.bckbt {
				background: #a0a0a0;
				color: #fff;
			}
		}
	}
	.thead-light th {
		color: #495057 !important;
		background-color: #e9ecef !important;
		border-color: #dee2e6 !important;
		font-size: 16px !important;
	}
	table {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
  }
	table tbody {
    width: 100%;
  }
	.page-break {
    page-break-after: always;
  }
	p {
    margin: 0px;
    font-size: 14px;
    padding: 0px;
    color: #000;
    line-height: 22px;
    margin-bottom: 10px;
  }
  ul,
  ol {
    margin: 0px;
    font-size: 13px;
    padding-left: 20px;
  }
  li {
		font-size: 14px;
		padding-left: 10px;
		line-height: 24px;
		margin-bottom: 10px;
  }
  h1 {
    font-size: 20px;
    color: #312783;
    margin: 0px;
    padding: 0;
  }
  h2 {
    color: #4e7f36;
    margin: 0px;
  }
  td {
    vertical-align: top;
  }
  h1 {
    padding: 0;
  }
  .f-wb {
    font-weight: bold;
  }
	.color_one {
    color: #271c6f;
  }
  .color_two {
    color: #68a56f;
  }
  .color_three {
    color: #585858;
  }
  .color_black {
    color: #000;
  }
  .text_one {
    font-size: 26px;
    font-weight: bold;
    line-height: 20px;
  }
  .text_two {
    font-size: 22px;
    font-weight: bold;
    line-height: 16px;
  }
  .text_thre {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 0px;
  }
  .sml_text {
    font-size: 12px
  }
  .border {
    border: 1px solid #4f8491;
    border-collapse: collapse;
    border-spacing: 0;
  }
  .border td {
    border: 1px solid #000;
    padding: 4px
  }
  .border th {
    border-right: 1px solid #000
  }
  .text-un-line {
    text-decoration: underline
  }
  .table_low_pad td {
    padding: 0
  }
  .table_styl_cb td {
    height: 30px;
    vertical-align: middle;
  }
  .body_all {
    max-width: 1000px;
    margin: 0 auto;
  }
  .complnt-frm1{
    float:right;
  }
  .adition_page2 p{
    font-size: 14px;
    margin-bottom: 15px;
 	}
 	.adition_page2 ul li{   
     font-size: 14px;
  }
	.border-1 {
		border: 1px solid #000000;
		border-collapse: collapse;
	}
	.txt-1 {
		text-align: center;
	}
	.txt-2 {
		padding-left: 120px;
		color: red;
		padding-top: 20px;
	}
	.txt-3 {
		padding-left: 100px;
	}
	.tble-wdth{
		width:48%;
	}
	.checkbox11{
		width:14px;
	}
	input.radio {
    position: absolute;
    visibility: hidden;
	}
	.rewpge{
		input{ 
				width: 0px;
		}
	}		
	.vbtm{
		td{
			vertical-align: bottom;
		}
	}
	.dotted-underline{
		border-bottom: dotted;
	}
	.tm-idupload {
		.intro{
			margin-bottom: 20px;
			img{
				height:150px;
			}
			h2{
				color: #69a2d7;
				font-weight: 600;
				font-size: 26px;
				margin: 15px 0;
			}
	
			h3{
				color: $color_2;
				font-weight: 600;
				font-size: 20px;
				padding-bottom: 15px;
			}	
			p{
				color: $color_2;
				font-weight: 400;
				font-size: 14px;
				font-style: italic;
			}
		}
		.doc-op{
			ul{
				margin-bottom: 50px;
				padding: 0px;
				li{
					display: inline;
					font-size: 13px;
					font-style: italic;
					font-weight: 600;
					padding: 0 10px;
					color: $color_2;
					border-right: solid 3px $color_3;
					&:last-child{
							border:0px;
					}
				}
			}
		}
		.uploading-part{
			.id-box{
				padding: 20px;
				box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
				-webkit-box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
				-moz-box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
				border-radius: 0 0 20px 20px;
				margin: 0px;
				margin-bottom: 15px;
				background-color: rgba(255, 255, 255, 0.6);
			}
			.upload-area{
				margin:20px 0;
				border-radius: 20px;
				padding: 20px 0 ;
				text-align: center;
				background: $background_color_1;
				box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
				-webkit-box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
				-moz-box-shadow: -1px 0px 22px 8px rgba(0,0,0,0.10);
			}
		}
		.retakered {
			background-color: red !important;
		}
	}

	$break-max-ipad: 991px;
	$break-mob: 500px;
	$break-mob1: 350px;

	@media (max-width:$break-max-ipad) {
		.complnt-frm1{
			float: left !important;
			width:auto !important;
		}
		.complnt-frm2{
			float: left !important;
		} 
	}
	@media (max-width:$break-mob){
		.complant_tble{
			overflow-x:auto;
			width:340px !important;
		}
		footer{
			img{
					width:200px !important;
			}
		}
		.contnt-1{
			width:340px;
		}
		.tble-wdth{
			width:30% !important;
		}   
		.modal-content{
			ul.options{      
				.custom-checkbox+label{
					height: auto;
					line-height: 18px !important;
					padding: 5px 5px 5px 35px;
				}       
		   }      
		} 
	}
	@media (max-width:$break-mob1){
		.complant_tble{
			overflow-x:auto;
			width:240px !important;
		}
		.contnt-1{
			width:240px;
		}      
	}

	.question{
		.radio-button{
			width: 0;
		}
	}

	.btn_proceed_outer .disabledclass {
		border-color: var(--bs-btn-hover-border-color);
		background: #69a2d7;
		opacity: 0.5;
		cursor: default;
	}
	.activeclass {
		background: #69a2d7 !important;
		color: $color_1;
	}
	.error-msg {
		background: #fb0404;
		color: #fff;
		font-size: 13px;
		width: 100%;
		float: left;
		text-align: center;
		margin-top: 5px;
		border-radius: 3px;
	}

    
	.question6a .question .checked {
		background: #111857;
	}

	@media only screen and (min-width: 768px) {
		.quesmdl .w-lg-50 {
			width: 50% !important;
		}
	}
	.quesmdl .form-sp-input {
		height: 40px !important;
		font-size: 13px;
	}


}

.modal-backdrop.show{
	background: rgba(0,0,0,0.85) !important;
	opacity: 1;
}
.flw-loader{
	background: rgba(0, 0, 0, 0.95) !important;
	z-index: 9999;
	opacity: 1;
}

.vanquis-next {
	background: #73cf56 !important;
}
