.SignProceed{
  /*================================================
 modal start
==================================================*/

.blackover{
    background: rgba(0, 0, 0, 0.98) !important;
}

.modal-content {
    border: 4px solid #195071 !important;
 
    p {
      font-size: 14px;
      margin-bottom: 5px;
    }
 
    h2 {
      color: #105b9a;
      font-size: 32px;
      font-weight: 800;
    }
 
    .thanktxt{
     color: #105b9a;
        font-size: 36px;
        font-weight: 800;
        text-align: center;
        margin-bottom:30px;
        
   }
 
   #thankyou01{
     display: none;
   }
   .thankimg{
     height:80px; 
     margin-bottom:30px;
     margin-top: 30px;
   }
  }
 
  img {
    max-width: 100%;
  }
 
  .sidespace {
    color: #000;
    font-size: 14px;
  }
 
  .review-cntnt {
    font-size: 11px;
  }
 
  .review1-cntnt {
    font-size: 11px !important;
  }
 
  .rating-str {
    height: 26px;
  }
 
  .f_name {
    font-size: 16px !important;
  }
 
  .client_1 {
    background: #e5fa01;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
 
  .options {
    display: block;
    padding: 0 0 10px;
    list-style: none;
    margin-bottom: 15px;
    margin-left: 0;
 
    li {
      margin: 0 1% 1% 0;
      width: 100%;
      float: left;
      cursor: pointer;
    }
 
    .custom-checkbox1 {
      display: none;
    }
 
    .custom-checkbox1+label {
      background: #73be73 7px;
      border: 1px solid #73be73;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      outline: none;
      margin: 0;
      padding: 15px;
      line-height: 18px;
      border-radius: 4px;
      width: 100%;
      clear: none;
      color: #fff;
      font-weight: 700;
      display: block;
      font-size: 16px;
      text-align: center;
    }
 
    .custom-checkbox1+label:hover {
      background: #568f56;
      color: #ffffff;
    }
 
    .custom-checkbox2 {
      display: none;
    }
 
    .custom-checkbox2+label {
      background: #ff6863 7px;
      border: 1px solid #ff6863;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      outline: none;
      margin: 0;
      padding: 15px;
      line-height: 18px;
      border-radius: 4px;
      width: 100%;
      clear: none;
      color: #fff;
      font-weight: 700;
      display: block;
      font-size: 16px;
      text-align: center;
    }
 
    .custom-checkbox2+label:hover {
      background: #f84949;
      color: #ffffff;
    }
  }
 
  #cancel01{
   display: none;
   .canceltxt{
     font-size: 21px;
     font-weight: bold;
     color: #105b9a;
     margin-bottom:30px;
     margin-top:30px;
     text-align: center;
    }
  }
  #signature01{
 
   .signtxt{
     font-size: 21px;
     font-weight: bold;
     margin-bottom:10px;
     margin-top:10px;
     text-align: center;
    }
    .signsubtxt{
     text-align: center;
     margin-bottom:15px;
    }
 .web-canvas{
   margin-bottom: 20px;
   width: 100%;
   border: 1px solid #ced4da;
   border-radius: 0.25rem;
   .signature-pad {
     display: block;
     padding: 0.375rem 0.75rem;
     font-size: 1rem;
     line-height: 1.5;
     color: #495057;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid #000000;
     border-radius: 0.25rem;
     transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
     margin: 0 auto;
     width: 100%;
     min-height: 250px;
     
 }
 }
 
 .options11 {
   
   padding: 0 0 10px;
   list-style: none;
   margin-left: 0;
   width:100%;
   display: flex;
   justify-content: space-between;
 
   li {
     cursor: pointer;
   }
 
   .custom-checkbox1 {
     display: none;
   }
 
   .custom-checkbox1+label {
     background: #359f14 7px;
     border: 1px solid #359f14;
     box-sizing: border-box;
     position: relative;
     cursor: pointer;
     outline: none;
     margin: 0;
     padding: 15px 50px;
     line-height: 18px;
     border-radius: 4px;
     width: 100%;
     clear: none;
     color: #fff;
     font-weight: 700;
     display: block;
     font-size: 16px;
     text-align: center;
   }
 
   .custom-checkbox1+label:hover {
     background:  #3cb616;
     color: #ffffff;
   }
 
   .custom-checkbox2 {
     display: none;
   }
 
   .custom-checkbox2+label {
     background: #ff0000 7px;
     border: 1px solid #ff0000;
     box-sizing: border-box;
     position: relative;
     cursor: pointer;
     outline: none;
     margin: 0;
     padding: 15px 50px;
     line-height: 18px;
     border-radius: 4px;
     width: 100%;
     clear: none;
     color: #fff;
     font-weight: 700;
     display: block;
     font-size: 16px;
     text-align: center;
   }
 
   .custom-checkbox2+label:hover {
     background: #ff1a1a;
     color: #ffffff;
   }
 }
    
  }
  
 
  .options1 {
   
   padding: 0 0 10px;
   list-style: none;
   margin-left: 0;
   width:100%;
   display: flex;
   justify-content: center;
 
   li {
     margin: 0 5% 5% 0;
     cursor: pointer;
   }
 
   .custom-checkbox1 {
     display: none;
   }
 
   .custom-checkbox1+label {
     background: #73be73 7px;
     border: 1px solid #73be73;
     box-sizing: border-box;
     position: relative;
     cursor: pointer;
     outline: none;
     margin: 0;
     padding: 15px 50px;
     line-height: 18px;
     border-radius: 4px;
     width: 100%;
     clear: none;
     color: #fff;
     font-weight: 700;
     display: block;
     font-size: 16px;
     text-align: center;
   }
 
   .custom-checkbox1+label:hover {
     background: #568f56;
     color: #ffffff;
   }
 
   .custom-checkbox2 {
     display: none;
   }
 
   .custom-checkbox2+label {
     background: #ff6863 7px;
     border: 1px solid #ff6863;
     box-sizing: border-box;
     position: relative;
     cursor: pointer;
     outline: none;
     margin: 0;
     padding: 15px 50px;
     line-height: 18px;
     border-radius: 4px;
     width: 100%;
     clear: none;
     color: #fff;
     font-weight: 700;
     display: block;
     font-size: 16px;
     text-align: center;
   }
 
   .custom-checkbox2+label:hover {
     background: #f84949;
     color: #ffffff;
   }
 }
 
 
 
  /*================================================
 modal end 
 ==================================================*/
 
 
 
  /////////////Media Query/////////////////
 
  $break-large: 1367px;
  $break-ipad-pro: 1200px;
  $break-min-ipad: 768px;
  $break-max-ipad: 991px;
  $break-min-tab: 577px;
  $break-max-tab: 768px;
  $break-mob: 576px;
  $break-smlmob: 391px;
  $break-ex_smlmob: 322px;
 
  @media screen and (min-width: $break-max-ipad) {}
 
  @media screen and (max-width: $break-max-tab) {
 
    body,
    p {
      font-size: 14px;
    }
 
    .title {
      font-size: 22px;
    }
 
    .privacy {
      .logosec {
        img {
          width: 150px;
        }
      }
 
      table {
        td {
          h5 {
            font-size: 15px;
          }
        }
      }
    }
  }
 
  @media screen and (max-width: $break-mob) {}


}