$color1 :#63a311;
$color2 :#319cff;
$color3 :white;
$color4 :#e73e49;

 

.tms-wrap2 {
    height: 100vh;

    &.bg-main-pr {
        background: url(../../img/bg87899.jpg) fixed !important;
        background-size: cover !important;
        float: left;
        width: 100%;


    }
     
    .option_but {
        background: $background_color_7;
        display: inline-block;
        border: 0px solid $color_6;
        border-bottom: 3px solid $color_3;
        font-size:20px;
        color: $color_1;
        font-weight: 600;
        border-radius:1px !important;
        box-sizing: border-box;
        width: 100%;
        cursor: pointer;
        margin: 0 0 10px !important;
        position: relative;
        overflow: hidden;
        text-align: center;
        padding: 16px 15px;
        span {
            display: inline-block;
            vertical-align: middle;
        }
        &.skip-btn {
        background: transparent;
        border: 2px solid  #dddddd;
        color: #333;
        text-transform: initial;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 18px;
        &:hover {
            background: #eee;
        }
    }

      

         
    }
    .mt-l-r {
        margin-top: -4rem;
    }
    .main {

        h1 {
            font-weight: bold;
            font-size: 25px;

            &.h4 {
                font-size: 22px;
            }

            span {
                color: $background_color_2;
            }

        }

        select {
            padding: 15px !important;
            border: 2px solid #e1e1e1;
        }

        h2 {
            font-weight: bold;

            .small {
                color: $color1;
                font-size: 18px;
                font-weight: normal;
            }
        }

        p {
            font-size: 23px;

        }

      

        .text2b {
            i {
                color: $color1;
            }

            font-size: 14px;
        }

        .text3b {
            font-style: italic;
            font-size: 14px;
            font-weight: 600;
        }

        .slot-filed {
            background: $background_color_11;
            font-size: 22px;
            font-weight: bold;
            padding: 15px;
            border-radius: 10px;
            color: $color_1;
            position: relative;
            cursor: pointer;
            &.active {
                background: #2f9de8 url("../../img/upload-ticka.png")  no-repeat;
                background-position: 96% center;
            }
            input {
                position: absolute;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
            }

            .icon {
                height: 50px;
                width: 50px;
                background: #1c7dbf;
                border-radius: 50%;
                display: inline-block;
                color: #fff;
                padding-top: 7px;
                font-size: 26px;
            }

            &.slot-email {
              
                font-size: 18px;
                font-weight: 500;
                
            }
        }

    }
    .btn-proceed {
        background: #11a317;
        border-bottom: 0;
    }
    .clearfix {
        &::after {
            content: "";
            clear: both;
            display: table;
        }
    }

    .btn-comm2 {
        padding: 20px 60px;
        align-items: center;
        appearance: none;
        background-image: radial-gradient(100% 100% at 100% 0, $color2 0, $color2 100%);
        border: 0;
        border-radius: 6px;
        box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        justify-content: center;
        line-height: 1;
        list-style: none;
        overflow: hidden;
        position: relative;
        text-align: left;
        text-decoration: none;
        transition: box-shadow .15s, transform .15s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        will-change: box-shadow, transform;
        font-size: 22px;
        width: 100%;
        text-align: center;

        span {
            font-size: 24px;
            background: #00000040;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: inline-block;
            text-align: center;
            line-height: 17px;
            margin-left: 10px;
        }

        &:focus {
            box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
        }

        &:hover {
            box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
            transform: translateY(-2px);
        }

        &:active {
            box-shadow: #3c4fe0 0 3px 7px inset;
            transform: translateY(2px);
        }

        &.second {
            background: $color1;

            &:focus {
                box-shadow: #446e0c 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c 0 -3px 0 inset;
            }

            &:hover {
                box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #446e0c 0 -3px 0 inset;
                transform: translateY(-2px);
            }


            &:active {
                box-shadow: #446e0c 0 3px 7px inset;
                transform: translateY(2px);
            }


        }
    }

    .btn-prev {
        background: transparent;
        outline: 0;
        border: 0;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;

    }

    .modal-body-xx {
        position: relative;

        .close-modal {
            position: absolute;
            border: none;
            background: $color2;
            color: #fff;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            top: 4px;
            right: 4px;
            font-size: 30px;
            line-height: 0;
            text-align: center;
            padding: 0;
            transition: all ease-in 0.3s;

            &:hover {
                transform: rotate(180deg);
                transition: all ease-in 0.3s;
            }
        }


    }

    .textlop {
        text-wrap: balance;
        font-size: 22px !important;
        background: #faffbb;
        border: 2px dashed $color2;
        border-radius: 7px;

    }

    .pulse {
        animation: pulse-animation 2s infinite;
    }

    @keyframes pulse-animation {
        0% {
            box-shadow: 0 0 0 0px #59d0d8;
        }

        100% {
            box-shadow: 0 0 0 20px #00000000;
        }
    }

    .claim-id-wraper {
        max-width: 40%;
        margin: 0 auto;

        @media (max-width: 1200px) {
            max-width: 80%;
        }

        h3 {
            font-size: 18px;
            line-height: 25px;

            @media (max-width: 490px) {

                font-size: 16px;
                line-height: 24px;
            }
        }

        .claim-id {

            .in {
                background: #FFE5E5;
                padding: 10px 15px;
                border-radius: 5px;
                font-weight: bold;

            }
        }
    }


    .loader-wrap {
        height: 100vh;
        width: 100%;
        overflow: hidden;

        .loader-logo {

            img {
                height: 65px;
                margin: 20px 0;
            }
        }

        .text-analys {
            font-size: 18px;
        }

        .loader {
            margin-top: 4rem;

            img {
                height: 130px;
            }

        }

        .loader-text {
            max-width: 600px;
            margin: 0 auto;
        }
    }

    .dis_none {
        display: none;
    }

    .main-form-wraper {
        margin-top: 2rem;
    }

    .brand-logo {
        img {
            margin: 20px 0;
        }
    }

    .lookupadd {
        .lookbnt {
            background: $color2;
            font-weight: 600;
            font-size: 21px;
            color: #fff;
        }
    }
    .docum-slide2{
        fieldset {
            border: 1px solid #ced4da;
            padding: 10px;
            border-radius: 6px;
            margin-bottom: 15px;
            background: #fff;
        }
        legend {
            padding: 0 10px;
            display: block;
            float: none;
            width: auto !important;
            max-width: 100%;
            margin-bottom: 8px;
            font-size: 24px;
            line-height: inherit;
            color: inherit;
            white-space: normal;
        }
        .edit_icn1{
           
            border-radius: 6px;
            padding:10px;
            color: #e73e49;
            text-decoration: none;
            display:flex;
            align-items: center;
            img{
                width:20px;
                margin-right: 4px;
            }
        }
        
            .frm_name1{
                height: 60px;
            }
           
            
    }


    @media only screen and (max-width: 1200px) and (min-width: 960px) {

        .main {
            p {
                font-size: 16px;
            }
        }
    }

    @media (min-width: 990px) {}

    @media (max-width: 990px) {
        .mt-val {
            margin-top: 4rem;
        }
        .mt-l-r {
            margin-top: inherit;
        }

    }

    @media (max-width: 768px) {
        .main-form-wraper {
            margin-top: 2rem;
        }

        .com-btn {
            font-size: 16px;
        }

        .mt-val {
            margin-top: 3rem;
        }

        .textlop {
            font-size: 16px !important;
        }

        .main {
            .slot-filed {

                font-size: 16px;

                &.slot-email {
                    font-size: 13px;
                }

                .ps-3 {
                    text-align: left;
                }
            }

            p {
                font-size: 14px;
            }

            fieldset {
                height: auto;

                .bud-logo {
                    width: 115px;
                    margin-bottom: 15px;
                }
            }

            padding-bottom: 60px;

            h1 {
                font-size: 24px;
            }


        }

        .btn-comm2 {
            font-size: 18px;
            padding: 15px 30px;
            display: flex;

        }



    }

    @media (max-width: 490px) {
        .success-msg {
            h3 {
                font-size: 20px;
            }
        }

        .safe-secure-img {
            height: 36px;
        }

        .loader-wrap {

            .loader {
                img {
                    height: 100px;
                }
            }

            .loader-logo {
                img {
                    height: 50px;
                }
            }
        }

        .main {



            h1 {
                font-size: 18px;
            }

            .text3b,
            .text2b {
                font-size: 12px;
            }

            .brand-logo {
                img {
                    height: 50px;
                }
            }
        }

        .btn-comm2 {
            font-size: 17px;


            span {
                display: none;
            }
        }

        .btn-prev {
            font-size: 14px;
        }


    }

}