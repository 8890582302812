.OpenBankingMb2 {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //background: url(../img/bg-new.jpg) no-repeat right;
  background-size: cover;
  font-family: 'Poppins', sans-serif !important;

  /*
	
*******************************************
	Template Name: tms legal
	Author: Anush 
	Date:09-06-2023
	Template By: Vandalay Designs
	Copyright 2023-2024 Vandalay Business Solution

	* This file contains the styling for the tms legal, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/





  // 	============================================
  // ==== T A B L E   O F   C O N T E N T S =====
  // ============================================
  // 01 - Sass Variables
  // 02 - QUESTIONIRE BODY
  // 03 - ANIMATION CLASSES
  // 04 - ANIMATION 
  // 05 - MEDIA QUERY



  /*================================================
01 - Sass Variables
==================================================*/



  $background_color_1: #ffffff;
  $background_color_2: #2f3996;
  $background_color_3: #3ebbe9;


  $color_1: #ffffff;
  $color_2: #257eba;


  /*================================================
02 - QUESTIONIRE BODY 
==================================================*/


  html {
    scroll-behavior: smooth;
  }

  a {
    cursor: pointer;
  }

  .showload{
    display: flex !important;
  }

  .api-logo{
    width: 9% !important;
  }

 .anim_blue {
    animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
    box-shadow: 0 0 0 0 #113e67;
  }



  .main-page-btn {
    min-height: 100vh;

    .btn-info_section {
      min-height: 100vh;
      padding: 0px;
      margin: 0px;
      display: flex;
      align-items: center;

      &::before {
        z-index: -1;
        opacity: 0.3;
        content: "";
        position: fixed;
        height: 100%;
        width: 100%;
        background: #ffffff !important;
      }

      .btn-info_part {
        display: flex;
        justify-content: center;
        align-items: center;
        .vh91{ 
          height: 91vh;
        }

        .space {
          .logo_img {
            width: 202px;
            margin-bottom: 60px;
          }

          .htext {
            font-weight: normal;
            text-align: center;
            margin-top: 30px;
            color: #2f3996;
            font-size: 18px;
          }

          .sub-text {
            font-size: 16px;
            font-weight: 400;
            color: rgb(80, 80, 80);
          }

          .info-italic {
            font-size: 12px;
            font-style: italic;
          }

          .refresh_btn_outer{
            height: 41px !important;
            margin: auto !important;
            border-radius: 50px;
          }

          .button {
            position: relative;
            width: 40%;
            height: 53px;
            margin: 30px auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            overflow: hidden;
            //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 3px 8px rgba(0, 0, 0, 0.1);
            transition: all 0.1s cubic-bezier(0, 0.22, .3, 1);
            color: #fff;
            font-size: 1rem;
            z-index: 10;
            font-weight: 600;
            letter-spacing: 2px;
            border:none;
            
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.1);
            }

            .btn_proceed{
              position: relative;
              width: 100%;
              height: 53px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              overflow: hidden;
              //box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 3px 8px rgba(0, 0, 0, 0.1);
              transition: all 0.1s cubic-bezier(0, 0.22, .3, 1);
              color: #fff;
              font-size: 1rem;
              background: transparent;
              z-index: 10;
              font-weight: 600;
              letter-spacing: 2px;
              border:none;
            }

            .refresh_btn{
              height: 41px !important;
              padding: 0 45px;
            }
            &._1 {
              background: #2980b9
            }
            .back {
              position: absolute;
              width: 0;
              height: 0;
              filter: url(#filter);
              border-radius: 50%;
              z-index: 5;
              transition: all 1.1s cubic-bezier(0.1, 0.22, .3, 1);
            }

            &._1 .back {
              top: -43%;
              background: #113e67;
            }

            &:hover .back {
              width: 692px;
              height: 92px;
            }
          
          }

          
         

        }
      }
    }
  }


  /*================================================
LOADER ANIMATION SCSS WITH KEYFRAME
==================================================*/


  .loader {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader-anime {
      display: contents;

      img {
        width: 40%;
      }
    }
  }


  /*================================================
LOADER ANIMATION SCSS WITH KEYFRAME
==================================================*/

  /*================================================
05 - MEDIA QUERY
==================================================*/

  @media only screen and (max-width: 750px) {
    .button {
      margin: 0.8rem 0;
    }
  }

  @media (min-width: 768px) {
    .refresh_btn {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }

  @media only screen and (max-width: 768px) and (min-width: 480px) {
    .space {
      .htext {
        font-size: 16px !important;
      }

      .api-logo{
        width: 15% !important;
      }

      .sub-text {
        font-size: 14px !important;
      }

      .button {
        width: 50% !important;
      }
      .w-100{
        width: 100% !important;
      }
      .btn_proceed{
        width: 100% !important;
        font-size: 12px !important;
      }
    }
  }

  @media (max-width: 480px) {
    .container {
      max-width: 100%;
    }

    .space {
      .logo_img {
        width: 160px !important;
      }
      .api-logo{
        width: 22% !important;
      }

      .htext {
        font-size: 16px !important;
      }

      .sub-text {
        font-size: 12px !important;
      }

      .button {
        width: 100% !important;
      }
      .btn_proceed{
        width: 100% !important;
        font-size: 12px !important;
      }
    }

    .loader {
      .loader-anime {
        img {
          width: 85%;
        }
      }
    }

  }
}