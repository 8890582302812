.loa-sec2 {
  //margin-top: 0.6cm;
  //margin-left: 1cm;
  //margin-right: 1cm;
  margin-bottom: 0.6cm;
  font-size: 14px;
  color: #000;
  background-color: #eee;
  padding-top: 20px;

  @page {
    margin: 0cm;
    padding: 0cm;
  }

  /** Define now the real margins of every page in the PDF **/

  /** Define the footer rules **/
  /* footer {
         position: fixed;
         bottom: 1cm;
         left: 2cm;
         right: 2cm;
         height: 2cm;
         }
         header {
         position: fixed;
         left:2cm; 
         right:2cm;
         height: 2cm;
         top:1cm;
         } */
  table {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
  }

  table tbody {
    width: 100%;
  }

  .page-break {
    page-break-after: always;
  }

  p {
    margin: 0px;
    font-size: 14px;
    padding: 0px;
    color: #000;
    line-height: 22px;
    margin-bottom: 10px;
  }

  ul,
  ol {
    margin: 0px;
    font-size: 13px;
    padding-left: 20px;
  }

  li {
    font-size: 14px;
    padding-left: 10px;
    line-height: 24px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 20px;
    color: #312783;
    margin: 0px;
    padding: 0;
  }

  h2 {
    color: #4e7f36;
    margin: 0px;
  }

  td {
    vertical-align: bottom;
  }

  h1 {
    padding: 0;
  }

  .f-wb {
    font-weight: bold;
  }

  /* colors */
  .color_one {
    color: #271c6f;
  }

  .color_two {
    color: #68a56f;
  }

  .color_three {
    color: #585858;
  }

  .color_black {
    color: #000;
  }

  /* colors */
  .text_one {
    font-size: 26px;
    font-weight: bold;
    line-height: 20px;
  }

  .text_two {
    font-size: 22px;
    font-weight: bold;
    line-height: 16px;
  }

  .text_thre {
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 0px;
  }

  .sml_text {
    font-size: 12px;
  }

  .border {
    border: 1px solid #4f8491;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .border td {
    border: 1px solid #000;
    padding: 4px;

  }

  .border th {
    border-right: 1px solid #000;
  }

  .text-un-line {
    text-decoration: underline;
  }

  .table_low_pad td {
    padding: 0;
  }

  .table_styl_cb td {
    height: 30px;
    vertical-align: bottom !important;
  }

  .body_all {
    max-width: 1000px;
    margin: 0 auto;
  }

  .body-class section>.adition_page {
    background: #fff;
    padding: 20px !important;
    margin-bottom: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    display: block;
  }

  .adition_page2 p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .adition_page2 ul li {
    font-size: 14px;
  }

  .border-1 {
    border: 1px solid #000000;
    border-collapse: collapse;
  }

  .txt-1 {
    text-align: center;
  }

  .txt-2 {
    padding-left: 120px;
    color: red;
    padding-top: 20px;
  }

  .txt-3 {
    padding-left: 100px;
  }

  .dotted-underline {
    border-bottom: dotted;
  }

  .ft_col {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .colb {
      text-align: right;

    }

  }

  .pt-logo {
    text-align: right;
  }

  .border .tdp {
    text-align: center;
    padding: 4px 2px !important;
  }
}

@media only screen and (min-width: 960px) {


  .loa-sec {

    .log_hd {
      width: 380px;
      float: right;

    }

  }


}


@media only screen and (max-width: 960px) {
  .loa-sec {
    .ft_col {
      display: block;

      .colb {
        margin-top: 20px;

      }
    }
  }

}



@media only screen and (max-width: 590px) {

  .loa-sec {

    .log_hd {
      .br_code {
        width: 50px;
      }
    }

    .pt-logo {
      text-align: left;
    }

  }


}

@media only screen and (max-width:410px) {

  .loa-sec {
    .table_resp {
      width: 300px;
      overflow-x: scroll;
    }

  }
}

@media only screen and (max-width:414px) {

  .loa-sec {
    .table_resp {
      width: 350px;
      overflow-x: scroll;
    }

  }
}

@media only screen and (max-width:393px) {

  .loa-sec {
    .table_resp {
      width: 317px;
      overflow-x: scroll;
    }

  }
}


@media only screen and (max-width:375px) {

  .loa-sec {
    .table_resp {
      width: 317px;
      overflow-x: scroll;
    }

  }
}

@media only screen and (max-width:360px) {

  .loa-sec {
    .table_resp {
      width: 290px;
      overflow-x: scroll;
    }

  }
}

@media only screen and (max-width:320px) {

  .loa-sec {
    .table_resp {
      width: 260px;
      overflow-x: scroll;
    }

  }
}