.ADV_1 {
  $background_color_1: #ffffff;
  $background_color_2: #000000;
  $background_color_3: #ff5200;
  $background_color_4: #521b8d;
  $background_color_5: #1169c2;
  $background_color_6: #ebebeb;
  $background_color_7: #008000;
  $background_color_8: #076807;
  $background_color_9: #000000bf;
  $background_color_1a: #ff66c4;

  $color_1: #000000;
  $color_2: #ffffff;
  $color_3: #b0afaf;
  $color_4: #656565;
  $color_5: #236093;

  $font_size_1: 12px;
  $font_size_2: 14px;
  $font_size_3: 15px;
  $font_size_4: 16px;
  $font_size_5: 18px;
  $font_size_6: 20px;
  $font_size_7: 46px;
  $font_size_8: 26px;
  $font_size_9: 32px;
  $font_size_10: 24px;
  $font-bold: bold;
  $font-normal: normal;
  $font-w500: 500;
  $border_color_1: #fff;

  @mixin f_style1 {
    color: $color_2;
    font-weight: $font-bold;
  }
  html {
    scroll-behavior: smooth;
  }
  .fw-bold {
    font-weight: 700 !important;
  }
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
  header {
    .gt_btn {
      font-size: $font_size_10;
      border-radius: 10px;
      font-weight: bold;
    }
  }
  .eligible_sec {
    a {
      font-size: 14px;
      font-weight: bold;
      width: 11%;
      padding: 11px 5px;
      display: inline-block;
      text-decoration: none;
      border-radius: 17px;
      margin-bottom: 5px;
      text-align: center;
    }
  }
  .contact-footer{
    background: #ebebeb !important;
  }
  footer {
    padding: 55px 0;
    margin-top: 0;
    h3 {
      font-size: 14px;

      font-weight: 400;
      text-align: left;
      font-size: 17px;
      font-weight: 500;
      padding: 10px 0;
    }
    p {
      font-size: 13px;
      padding-top: 0;

      font-weight: 400;
      line-height: 25px;
    }
    ul {
      margin: 10px 0;
      padding: 0;
      li {
        list-style: none;
        display: inline;
        margin: 0 13px 0 0;
        padding-right: 10px;

        &:last-child {
          border: none;
        }
        a {
          font-size: 14px;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
  .popup-modal {
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 2 !important;
    background-size: 400% 400%;
    -webkit-animation: Gradient 2s ease infinite;
    -moz-animation: Gradient 2s ease infinite;
    animation: Gradient 2s ease infinite;
  }
  p {
    .gt_btn1 {
      font-size: $font_size_10;
      border-radius: 10px;
      font-weight: bold;
    }
  }
  footer {
    padding: 15px 0;
    p {
      font-size: 16px;
    }
  }
  .modal-content {
    .modal-header {
      border-radius: 0 !important;
      .modal-title {
        font-size: 18px;
      }
      .close {
        font-size: 18px;
        border: 0px;
        background-color: #fff;
      }
    }
    .modal-body {
      p {
        font-size: 14px;
        margin-bottom: 7px;
      }
      h3 {
        font-size: 22px;
      }
    }
    .modal-footer {
      border: 0;
      padding: 10px 20px !important;
      border-radius: 0 !important;
    }
  }
  .cooki {
    font-size: 8px;
    padding: 6px 0;
    position: fixed;
    left: -1px;
    z-index: 999;
    bottom: 0;
    border: 0px;

    float: left;
    width: 100%;
    line-height: 11px;
    a {
      cursor: pointer;
    }
    .rp_clk {
      u {
        cursor: pointer;
      }
    }
  }
  header {
    .gt_btn {
      background: #ff5200;
      border-bottom: 4px solid #c94100;
      width: 60%;
    }
  }

  .eligible_sec {
    border: solid 5px #001b72;
    h2 {
      background-color: #001b72;
    }
    a {
      background-color: #001b72;
      color: #fff;

      &:hover {
        background-color: #1169c2;
        text-decoration: underline;
        color: #fff;
      }
    }
  }

  footer {
    background: #ebebeb;

    p {
      color: #656565;
    }
    ul {
      li {
        border-right: 1px solid #656565;
        &:last-child {
          border: none;
        }
        a {
          color: #656565;
        }
      }
    }
  }
  p {
    .gt_btn1 {
      background: #ff5200;
      border-bottom: 4px solid #c94100;
      width: 60%;
    }
  }

  .modal-content {
    .modal-header {
      background: #fff;

      .modal-title {
        color: #000;
      }
      .close {
        background-color: #fff;
      }
    }

    .modal-footer {
      background: #fff;
    }
  }
  .cooki {
    color: #fff;
    background: #000000;
  }
  $break-ipad-pro: 1024px;
  $break-min-ipad: 768px;
  $break-max-ipad: 990px;
  $break-min-tab: 577px;
  $break-max-tab: 767px;
  //$min-break-small: 576px;
  $break-mob: 576px;

  @media screen and (max-width: $break-ipad-pro) {
  }

  @media (min-width: $break-min-ipad) and (max-width: $break-max-ipad) {
  }

  @media (min-width: $break-min-tab) and (max-width: $break-max-tab) {
  }
  @media (min-width: 220px) and (max-width: 425px) {
    .cta_area {
      a {
        img {
          width: 100%;
        }
      }
    }
    .heading_sec {
      h1 {
        font-size: 24px;
      }
    }
    .content_sec {
      .main_area {
        // .cta_area{
        //     .rbut1{
        //         a{
        //             font-size: 14px !important;
        //         }
        //     }
        // }
        .mid-banner {
          .midinnr {
            a {
              width: 89% !important;
            }
          }
        }
      }
    }
    footer {
      .end_fut {
        padding: 20px 9px;
        ul {
          text-align: center;
          li {
            font-size: 13px;
          }
        }
      }
    }
  }

  @media (min-width: 220px) and (max-width: 574px) {
    header {
      .mob_view {
        border: none !important;
      }
      .logo {
        margin-top: 4%;
      }
    }
    .heading_sec {
      h1 {
        font-size: 27px;
      }
    }
    .content_sec {
      .main_area {
        .cta_area {
          img {
            // width: 55%;
          }
        }
      }
    }
    footer {
      padding: 0px;
      ul {
        text-align: center;
        margin-bottom: 47px;
      }
    }
  }

  @media (max-width: 379px) {
    header {
      .mob_view {
        margin-top: 5% !important;
      }
    }
    .content_sec {
      .main_area {
        .mid-banner {
          .midinnr {
            h2 {
              font-size: 16px;
            }
          }
        }
        .cta_area {
          .rbut1 {
            a {
              font-size: 16px !important;
            }
          }
        }
      }
    }
    .heading_sec {
      h1 {
        font-size: 20px;
      }
    }
  }
}
