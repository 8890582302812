.BudsRedirectPage {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: url(../img/bg-new.jpg) no-repeat right;
  background-size: cover;
  font-family: 'Poppins', sans-serif !important;

  /*
	
*******************************************
	Template Name: tms legal
	Author: Anush 
	Date:09-06-2023
	Template By: Vandalay Designs
	Copyright 2023-2024 Vandalay Business Solution

	* This file contains the styling for the tms legal, this
	is the file you need to edit to change the look of the
	site.

	This files contents are outlined below >>>>

	*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
	*******************************************
	*/





  // 	============================================
  // ==== T A B L E   O F   C O N T E N T S =====
  // ============================================
  // 01 - Sass Variables
  // 02 - QUESTIONIRE BODY
  // 03 - ANIMATION CLASSES
  // 04 - ANIMATION 
  // 05 - MEDIA QUERY



  /*================================================
01 - Sass Variables
==================================================*/



  $background_color_1: #ffffff;
  $background_color_2: #2f3996;
  $background_color_3: #3ebbe9;


  $color_1: #ffffff;
  $color_2: #257eba;


  /*================================================
02 - QUESTIONIRE BODY 
==================================================*/


  html {
    scroll-behavior: smooth;
  }



  a {
    cursor: pointer;
  }

  .main-page-btn {
    min-height: 100vh;

    .btn-info_section {
      min-height: 100vh;
      padding: 0px;
      margin: 0px;
      display: block;
      align-items: center;

      &::before {
        z-index: -1;
        opacity: 0.3;
        content: "";
        position: fixed;
        height: 100%;
        width: 100%;
        background: #ffffff !important;
      }

      .btn-info_part {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 25px;

        .space {
          img {
            width: 202px;
          }

          .tq-img {
            width: 100px;
            margin-bottom: 1rem;
          }

          .bounce-in {
            animation: bounce-in 2s ease;
            -webkit-animation: bounce-in 2s ease;
          }

          @keyframes bounce-in {
            0% {
              opacity: 0;
              transform: scale(.3);
            }

            50% {
              opacity: 1;
              transform: scale(1.05);
            }

            70% {
              transform: scale(.9);
            }

            100% {
              transform: scale(1);
            }
          }

          .htext {
            font-weight: normal;
            text-align: center;
            margin-top: 30px;
            color: #2f3996;
            font-size: 20px;
          }

          .sub-text {
            font-size: 18px;
            font-weight: 400;
            color: rgb(80, 80, 80);
          }

          .info-italic {
            font-size: 12px;
            font-style: italic;
          }

          .button {
            position: relative;
            width: 264px;
            height: 53px;
            margin: 30px auto 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            overflow: hidden;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2), 0 3px 8px rgba(0, 0, 0, 0.1);
            transition: all 0.1s cubic-bezier(0, 0.22, .3, 1);

            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.1);
            }

            span {
              color: #fff;
              font-size: 1rem;
              z-index: 10;
              font-weight: 600;
              letter-spacing: 2px;
            }

            &._1 {
              background: #2980b9
            }

            .back {
              position: absolute;
              width: 0;
              height: 0;
              filter: url(#filter);
              border-radius: 50%;
              z-index: 5;
              transition: all 1.1s cubic-bezier(0.1, 0.22, .3, 1);
            }

            &._1 .back {
              top: -43%;
              background: #113e67;
            }

            &:hover .back {
              width: 692px;
              height: 92px;
            }
          }

        }
      }

      /* container main card design from here onwards  */
      /* design does not include bootstrap hover effecct and class name will will be body */

      .body {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-wrap: wrap;

        .container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          max-width: 1200px;

          .card {
            position: relative;
            width: 400px;
            height: 160px;
            background: #fff;
            box-shadow: 0px 0px 42px 0px #0000001c;
            border: none !important;
            border-radius: 15px;
            margin: 20px 20px;
            transition: 0.5s;
          }
        }

      }
      .btn-comm2.second {
        background: #63a311;
        border-bottom: 3px solid #3e6d02
    }
    
    .btn-comm2 {
        padding: 20px 60px;
        align-items: center;
        appearance: none;
        background-image: radial-gradient(100% 100% at 100% 0, #319cff 0, #319cff 100%);
        border: 0;
        border-radius: 6px;
        box-shadow: rgba(45,35,66,0.4) 0 2px 4px,rgba(45,35,66,0.3) 0 7px 13px -3px,rgba(58,65,111,0.5) 0 -3px 0 inset;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        justify-content: center;
        line-height: 1;
        list-style: none;
        overflow: hidden;
        position: relative;
        text-align: left;
        text-decoration: none;
        transition: box-shadow .15s,transform .15s;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        will-change: box-shadow,transform;
        font-size: 22px;
        width: 100%;
        text-align: center
    }

    
    .btn-comm2.second {
        background: #63a311
    }
    
    .btn-comm2.second:focus {
        box-shadow: #446e0c 0 0 0 1.5px inset,rgba(45,35,66,0.4) 0 2px 4px,rgba(45,35,66,0.3) 0 7px 13px -3px,#446e0c 0 -3px 0 inset
    }
    
    .btn-comm2.second:hover {
        box-shadow: rgba(45,35,66,0.4) 0 4px 8px,rgba(45,35,66,0.3) 0 7px 13px -3px,#446e0c 0 -3px 0 inset;
        transform: translateY(-2px)
    }
    
    .btn-comm2.second:active {
        box-shadow: #446e0c 0 3px 7px inset;
        transform: translateY(2px)
    }

 
    .btn-comm2 span {
        font-size: 28px;
        background: #00000040;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: inline-block;
        text-align: center;
        line-height: 17px;
        margin-left: 10px;
    }
    

    .anim_green {
      -webkit-animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
      animation: pulse 1.25s cubic-bezier(0.66, 0, 0, 1) infinite;
      box-shadow: 0 0 0 0 #4a7a0d;
  }


  @keyframes pulse {
    100% {
      box-shadow: 0 0 0 45px rgba(232,76,61,0);
  }
  }


      .body .container .card .box {
        position: absolute;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: 0.5s;
      }

      .body .container .card .box .content {
        text-align: center;
      }


      .body .container .card .box .content h3 {
        font-size: 1rem;
        color: #747474;
        z-index: 1;
        font-weight: 600;
        transition: 0.5s;
        margin-bottom: 20px;
      }

      .body .container .card .box .content p {
        font-size: 0.8rem;
        font-weight: 300;
        color: #858585e6;
        z-index: 1;
        transition: 0.5s;
      }


      /* card image hover effect png image adjust to the box */
      /* image size adjust based on height  */
      /* inside card content design */
      .content img {
        width: auto;
        height: 85px;
      }
    }
  }


  /*================================================
LOADER ANIMATION SCSS WITH KEYFRAME
==================================================*/


  .loader {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader-anime {
      display: contents;

      img {
        width: 40%;
      }
    }
  }


  /*================================================
LOADER ANIMATION SCSS WITH KEYFRAME
==================================================*/

  /*================================================
05 - MEDIA QUERY
==================================================*/

  @media only screen and (min-width: 960px) {
    .card {
      height: 220px !important;
    }

    .content h3 {
      font-size: 18px !important;
    }
  }


  @media only screen and (max-width: 750px) {
    .button {
      margin: 0.8rem 0;
    }
  }

  // @media (min-width: 576px) {
  //   .container {
  //     max-width: 100%;
  //   }
  // }

  @media only screen and (max-width: 680px) and (min-width: 480px) {
    .space {
      .htext {
        font-size: 16px !important;
      }

      .sub-text {
        font-size: 14px !important;
      }

      .button {
        width: 100% !important;
      }
    }
  }

  @media (max-width: 480px) {
    .container {
      max-width: 100%;
    }

    .space {
      img {
        width: 160px !important;
      }

      .tq-img {
        width: 100px !important;
        margin-bottom: 1rem;
      }

      .htext {
        font-size: 16px !important;
      }

      .sub-text {
        font-size: 14px !important;
      }

      .button {
        width: 100% !important;
      }
    }

    .loader {
      .loader-anime {
        img {
          width: 85%;
        }
      }
    }

  }

  .disablebtn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
 }

}